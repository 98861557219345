import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';


const WhyBuyFromBrand = ({brand, parts, models}) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState({ color: '#132530', fontFamily: 'Montserrat, Arial', fontWeight:'501', fontSize: '16px', fontWeight: '500', lineHeight: '26px' });
    const [description, setDescription] = useState({ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '13px', fontWeight: '500', lineHeight: '20px' });
    const [imgStyle, setimgStyle] = useState({width: '90%', float: 'right'});
    const dir = localStorage.getItem('dir');
    if(window.innerWidth <= 768){
        return (
            <section className="section wrap">
                <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                    {t('why_buy_from_brand.title', { brand:brand })}
                </h2>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className='col-2'>
                                <img style={imgStyle} src='/assets/icons/popular-icon.svg' alt="popular-icon" />
                            </div>
                            <div className='col-10'>
                                <span style={title}>
                                {t('why_buy_from_brand.popular_manufacturer')}
                                </span>
                                <p style={description}>{t('why_buy_from_brand.popular_description', { brand: brand })}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className='col-2'>
                                <img style={imgStyle} src='/assets/icons/document-icon.svg' alt='document-icon' />
                            </div>
                            <div className='col-10'>
                                <span style={title}>
                                {t('why_buy_from_brand.wide_choice')}
                                </span>
                                <p style={description}>
                                {t('why_buy_from_brand.wide_choice_description', { brand: brand })}
                                </p>
                            </div>
                        </div>
                    </div>
    
    
                    <div className='col-md-12 mt-2'>
                        <div className='row'>
                            <div className='col-2'>
                                <img style={imgStyle} src='/assets/icons/star-black.svg' alt='star-black' />
                            </div>
                            <div className='col-10'>
                                <span style={title}>
                                {t('why_buy_from_brand.ranked')}
                                </span>
                                <p style={description}>
                                {t('why_buy_from_brand.ranked_description', { brand: brand })}
                                </p>
                            </div>
                        </div>
                    </div>
    
                    <div className='col-md-12 mt-2'>
                        <div className='row'>
                            <div className='col-2'>
                                <img style={imgStyle} src='/assets/icons/security-icon.svg' alt='security-icon' />
                            </div>
                            <div className='col-10'>
                                <span style={title}>
                                {t('why_buy_from_brand.wide_coverage')}
                                </span>
                                <p style={description}>
                                {t('why_buy_from_brand.wide_coverage_description', { brand:brand, models:models })}
                                </p>
                            </div>
                        </div>
                    </div>
    
                    <div className='col-md-12 mt-2'>
                        <div className='row'>
                            <div className='col-2'>
                                <img style={imgStyle} src='/assets/icons/catalog-icon.svg' alt='security-icon' />
                            </div>
                            <div className='col-10'>
                                <span style={title}>
                                {t('why_buy_from_brand.safe_order')}
                                </span>
                                <p style={description}>
                                {t('why_buy_from_brand.safe_order_description', { brand: brand })}
                                </p>
                            </div>
                        </div>
                    </div>
    
    
                    <div className='col-md-12 mt-2'>
                        <div className='row'>
                            <div className='col-2'>
                                <img style={imgStyle} src='/assets/icons/accessories-price-icon.svg' alt='accessories-price-icon' />
                            </div>
                            <div className='col-10'>
                                <span style={title}>
                                {t('why_buy_from_brand.best_price')}
                                </span>
                                <p style={description}>
                                {t('why_buy_from_brand.best_price_description', { brand: brand })}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
    return (
        <section className="section wrap">
            <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                {t('why_buy_from_brand.title', { brand: brand })}
            </h2>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <img style={imgStyle} src='/assets/icons/popular-icon.svg' alt="popular-icon" />
                        </div>
                        <div className='col-sm-10'>
                            <span style={title}>
                            {t('why_buy_from_brand.popular_manufacturer')}
                            </span>
                            <p style={description}>{t('why_buy_from_brand.popular_description', { brand: brand })}</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <img style={imgStyle} src='/assets/icons/document-icon.svg' alt='document-icon' />
                        </div>
                        <div className='col-sm-10'>
                            <span style={title}>
                            {t('why_buy_from_brand.wide_choice')}
                            </span>
                            <p style={description}>
                            {t('why_buy_from_brand.wide_choice_description', { brand: brand })}
                            </p>
                        </div>
                    </div>
                </div>


                <div className='col-md-6 mt-2'>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <img style={imgStyle} src='/assets/icons/star-black.svg' alt='star-black' />
                        </div>
                        <div className='col-sm-10'>
                            <span style={title}>
                            {t('why_buy_from_brand.ranked')}
                            </span>
                            <p style={description}>
                            {t('why_buy_from_brand.ranked_description', { brand: brand })}
                            </p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 mt-2'>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <img style={imgStyle} src='/assets/icons/security-icon.svg' alt='security-icon' />
                        </div>
                        <div className='col-sm-10'>
                            <span style={title}>
                            {t('why_buy_from_brand.wide_coverage')}
                            </span>
                            <p style={description}>
                            {t('why_buy_from_brand.wide_coverage_description', { brand:brand, models:models })}
                            </p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 mt-2'>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <img style={imgStyle} src='/assets/icons/catalog-icon.svg' alt='security-icon' />
                        </div>
                        <div className='col-sm-10'>
                            <span style={title}>
                            {t('why_buy_from_brand.safe_order')}
                            </span>
                            <p style={description}>
                            {t('why_buy_from_brand.safe_order_description', { brand: brand })}
                            </p>
                        </div>
                    </div>
                </div>


                <div className='col-md-6 mt-2'>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <img style={imgStyle} src='/assets/icons/accessories-price-icon.svg' alt='accessories-price-icon' />
                        </div>
                        <div className='col-sm-10'>
                            <span style={title}>
                            {t('why_buy_from_brand.best_price')}
                            </span>
                            <p style={description}>
                            {t('why_buy_from_brand.best_price_description', { brand: brand })}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyBuyFromBrand;
