import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'

import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../Context/ConfigContext';
import slugify from 'slugify';

import { Helmet } from 'react-helmet';
import WhyBuyFromBrand from '../../Components/Statics/WhyBuyFromBrand';
import Reviews from './PartDetails/Reviews';
import CarSeachBoxHorizontal from '../Cars/CarSeachBoxHorizontal';
import UniCarSearch from '../Cars/UniCarSearch';
import CarInfoBox from '../Cars/CarInfoBox';
const SupplierCatelog  = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang');
    const {gdcURL, PCID, categories} = useContext(ConfigContext);
    const dir = localStorage.getItem('dir');
    const {slug} = useParams();
    const [supplierCategories, setSupplierCategories] = useState([]);
    const [car, setCar] = useState([]);
    const [loading, setLoading] = useState(false);
    const [stridtree, setSTRIDTree] = useState([null]);
    const [popularSuppliers, setPopularSuppliers] = useState([]);
    const [popularSuppliersLimit, setPopularSuppliersLimit] = useState(8);
    const [supdId, setSupId] = useState(0);
    
    useEffect(() => {
      window.scrollTo(0, 0)
      if(slug){
        try{

          const sup_id = slug.split('-')[0];
          setSupId(sup_id);
        
        fetch(`${gdcURL}/Suppliers/GetCategoriesBySupplier?pc_id=${PCID}&sup_id=${sup_id}&lang=${locale}`)
          .then((res) => res.json())
          .then((data) => {
            setSupplierCategories(data.data);
           
          })
        }catch(err){
         
        }
      }
         
      }, [PCID]);

      useEffect(() => {
      
        if(slug){
          try{
          fetch(`${gdcURL}/Suppliers/GetPouplarSuppliers`)
            .then((res) => res.json())
            .then((data) => {
              setPopularSuppliers(data.data);
            })
          }catch(err){
           
          }
        }
           
        }, []);
    
    const AddCategorySTRID = (str_id) => {
        setSTRIDTree([str_id, ...stridtree])
    }
    const RemoveCategorySTRID = () => {
        if( stridtree[0] !== null){
            setSTRIDTree(stridtree.slice(1));
        }
    }
    const findChildrens = (prent_id) => {
        var obj = supplierCategories.find((item) => item.STR_ID_PARENT === prent_id)
        return obj?true:false;
    }
    
    const checkInSupplierCategory = (STR_ID) => {
      return supplierCategories && supplierCategories.find((item) => item.STR_ID === STR_ID);
    }
    // const sortingSupps = () => {
    //   var primaryCats = categories.find((item) => item.STR_ID_PARENT === null);
    //   for(var i = 0; i < primaryCats.length; i++){
    //     console.log(primaryCats)
    //     var cat = categories[0];


    //   }
    // }
    return (
        <main className="main">   
        <Helmet>
                <title>{`${supplierCategories && supplierCategories[0] && supplierCategories[0].SUP_NAME && supplierCategories[0].SUP_NAME!=='undefined'? supplierCategories[0].SUP_NAME: ``} Catelog`}</title>
                <meta name="description" content={`Discover unbeatable prices on top  ${supplierCategories && supplierCategories[0] && supplierCategories[0].SUP_NAME} automotive parts at Ghayar and secure the lowest prices on high-demand {supplierCategories[0].SUP_NAME} car parts. Find the best deals on widely-used ${supplierCategories && supplierCategories[0] && supplierCategories[0].SUP_NAME} parts, ensuring you get the finest quality for your car at an amazing value.`} />
                
                
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content={`${supplierCategories && supplierCategories[0] && supplierCategories[0].SUP_NAME} Catelog`} />
                <meta property="og:description" content={`Explore the top-rated spare parts suppliers at ${t('site_name')}. Discover a variety of parts and components for different industries.`} />
                <meta property="og:url" content={`${t('site_url')}/spare-parts-suppliers`} />
                <meta property="og:type" content="website" />
                
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`${supplierCategories && supplierCategories[0] && supplierCategories[0].SUP_NAME} Catelog`} />
                <meta name="twitter:description" content={`Looking for spare parts suppliers? ${t('site_name')} connects you with the best suppliers offering quality parts and services.`} />
                
                <link rel="canonical" href={`${t('site_url')}/spare-parts-suppliers`} />
            </Helmet>
            <CarInfoBox isHorizontal={true} redirectStatus={true}/>
          <div className="parts p-5">
            <div className="container">
            {!localStorage.getItem('pc_id') && 
                  <div className='container mb-1 mt-1 pb-2 pt-2'>
                    <div className='alert alert-info' style={{textAlign: 'center'}}>
                    <div className='col-sm-12'>
                      {
                        localStorage.getItem('lang') === null || localStorage.getItem('lang') !== 'ar' ?
                        <div>
                          To ensure you get the right parts that fit your car perfectly, please select your car before exploring our wide range of products. 
                      This helps us tailor the best options specifically for your make and model, guaranteeing a perfect fit and optimal performance every time.
                      <br /><b>Select Your Car</b> Now and start shopping with confidence!</div>
                      :<div>
                        <b>للحصول على القطع المناسبة لسيارتك!</b>
                        <br />
  لتضمن حصولك على القطع التي تناسب سيارتك تمامًا، يرجى اختيار سيارتك قبل استكشاف مجموعة منتجاتنا الواسعة. يساعدنا هذا في تقديم أفضل الخيارات التي تتناسب مع طراز سيارتك لضمان ملاءمة مثالية وأداء أمثل في كل مرة.
  <br />
  <b>اختر سيارتك الآن وابدأ التسوق بثقة!</b>
                        </div>
                      }
                    </div>
                    </div>
                    
                  </div>

                  
                  }
                 
              <div className="flex-wrap d-flex justify-content-between align-items-center">
                {
                    stridtree[0] !== null?<h4 onClick={() => RemoveCategorySTRID()}> <i className="fas fa-arrow-left mr-3 my-icon"></i>{" "} </h4>: <div></div>
                }
                
              </div>
              {
              supplierCategories && supplierCategories.length > 0  && 
              <div dir={dir} className='container' style={{padding: '20px', backgroundColor: '#FFF'}}>
                <div className='row'>
                <div className='col-sm-1'>
                  <img src={`${gdcURL}/sup-logo/${supplierCategories[0].SUP_LOGO_NAME}`} alt={supplierCategories[0].STR_NODE_NAME}  style={{width: '50px'}}/>
                </div>
                {
                  localStorage.getItem('lang') === 'en' || localStorage.getItem('lang') === null || localStorage.getItem('lang') === ''?
                
                <div className='col-sm-11'>Discover unbeatable prices on top {supplierCategories[0].SUP_NAME} automotive parts at Ghayar and secure the lowest prices on high-demand {supplierCategories[0].SUP_NAME} car parts. Find the best deals on widely-used {supplierCategories[0].SUP_NAME} parts, ensuring you get the finest quality for your car at an amazing value. </div>
              :  
              <div className='col-sm-11' style={{textAlign: 'right'}}>
                اكتشف أفضل الأسعار  لقطع غيار السيارات من {supplierCategories[0].SUP_NAME} في غيار واحصل على أقل الأسعار على قطع غيار السيارات من {supplierCategories[0].SUP_NAME} ذات الطلب العالي. ابحث عن أفضل العروض على قطع غيار {supplierCategories[0].SUP_NAME} الشائعة، لضمان حصولك على أعلى جودة لسيارتك بأفضل قيمة.
                 </div>
              }
                </div>
              </div>
              }
              <div className="row mt-3" style={{minHeight: '150vh'}}>
                {
                    supplierCategories && supplierCategories.map((item) => {
                        if(item.STR_ID_PARENT === stridtree[0] && item.STR_ID !== 100343 && item.STR_ID !== 706365 && item.STR_ID !== 100019){
                            var isChildren = findChildrens(item.STR_ID);
                            return <div key={item.STR_ID} className="col-md-3" onClick={() => 
                            {
                              if(isChildren){
                                AddCategorySTRID(item.STR_ID)
                                window.scrollTo(0, 0)
                              }else{
                                navigate(`/Spare-Parts-Supplier/${slugify(item.STR_NODE_NAME)}-${item.STR_ID}/${slug.split('-')[0]}/${item.SUP_NAME}`)
                                window.scrollTo(0, 0)
                              }
                            }
                            }>
                                <div style={{cursor: 'pointer', border: '1px solid transparent'}} 
                                     onMouseEnter={(e) => e.currentTarget.style.border = '1px solid red'} 
                                     onMouseLeave={(e) => e.currentTarget.style.border = '1px solid transparent'}>
                                    <div className="card-body d-flex flex-column justify-content-center align-items-center text-center">
                                    <img
                                    src={`${gdcURL}/category-images/600x600/${item.STR_ID}`}
                                    className="img-fluid"
                                    alt={item.STR_NODE_NAME}
                                    width={200}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // Prevents infinite loop in case fallback also fails
                                      currentTarget.src = "https://placehold.co/100x100/FFFFFF/FFFFFF/png";
                                    }}
                                    
                                  />
                                    <h6 style={{fontWeight: 500, marginTop: '-20px'}}>{item.SUP_NAME} <br /> {item.STR_NODE_NAME} </h6>
                                    </div>
                                    
                                </div>
                            </div>
                            
                        }
                    })
                }

                <div className="row mt-3">

                  {
                    supplierCategories && supplierCategories.map((item) => {
                      if(item.STR_ID_PARENT === stridtree[0] && item.STR_ID !== 100343 && item.STR_ID !== 706365 && item.STR_ID !== 100019){
                        return (
                          <div className='col-sm-12'>
                            <table><tr><td><img src={`${gdcURL}/category-images/600x600/${item.STR_ID}`} alt={item.STR_NODE_NAME}  style={{width: '150px'}}/></td><td><h3 style={{ fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '18px', fontWeight: 500, lineHeight: '16px' }}>{t('dep_parts_catalogue_from', {node_name: item.STR_NODE_NAME, sup_name: item.SUP_NAME})} </h3></td></tr></table>
                            <div className='row'>
                              {
                                [...new Map(supplierCategories.filter((row) => row.STR_ID_PARENT === item.STR_ID).map(row => [row.STR_NODE_NAME, row])).values()].map((row) => {
                                  return (
                                    <div className='col-sm-4' key={row.STR_ID}>
                                      
                                      <NavLink to={`/Spare-Parts/${slugify(row.STR_NODE_NAME)}-${row.STR_ID}?supplier=${supdId}-${item.SUP_NAME}`}>
                                        <table>
                                          <tr>
                                            <td>
                                              <img src={`${gdcURL}/category-images/600x600/${row.STR_ID}`} alt={row.STR_NODE_NAME} style={{ width: '80px' }} />
                                            </td>
                                            <td>
                                              <h3 style={{ fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '14px', fontWeight: 500, lineHeight: '12px' }}>{row.STR_NODE_NAME}</h3>
                                            </td>
                                          </tr>
                                        </table>
                                      </NavLink>
                                    </div>
                                  );
                                })
                              }
                            </div>
                          </div>
                        )
                        
                      }
                    })  
                  }

                  {supplierCategories && supplierCategories.length>0 && <WhyBuyFromBrand brand={supplierCategories[0].SUP_NAME} parts={73000} models={46777}/>}
                </div>
                <div className='row mt-3'>
                  <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                    Other popular manufacturers of aftermarket automotive parts
                  </h2>
                  <div className='container'>
                    <div className="row justify-content-center">
                        {popularSuppliers && popularSuppliers.length > 0 && 
                            [...new Map(popularSuppliers.map(sup => [sup.SUP_ID, sup])).values()].slice(0, popularSuppliersLimit).map((sup, index) => (
                                <NavLink key={`popular-supplier-${index}`} to={`/Supplier-Catelog/${sup.SUP_ID}-${slugify(sup.SUP_BRAND)}`}>
                                    <div className="col-sm-1" style={{margin: '5px'}}>
                                        <ImageWithFallback 
                                            src={`${gdcURL}/sup-logo/${sup.SUP_LOGO_NAME}`} 
                                            alt={sup.SUP_BRAND} 
                                            index={index} 
                                            fallbackText={sup.SUP_BRAND}
                                        />
                                    </div>
                                </NavLink>
                            ))
                        }
                    </div>
                    {popularSuppliers && popularSuppliers.length > 8 && (
                        <center >
                            <span 
                                className="text-primary" 
                                style={{
                                    marginTop: '20px', 
                                    cursor: 'pointer',
                                    textDecoration: 'underline'
                                }} 
                                onClick={() => {
                                    // if (popularSuppliersLimit > 8) {
                                    //     setPopularSuppliersLimit(8);
                                    // } else {
                                    //     setPopularSuppliersLimit(popularSuppliers.length);
                                    // }

                                    window.location = '/Suppliers'
                                }}
                            >
                                {popularSuppliersLimit > 8 ? t('show_less') : t('show_more')}
                            </span>
                        </center>
                    )}
                </div>
                <div className='container mt-2'>
                  <div className='card p-5'>
                    <Reviews title={t('leave_a_review_about', {value: supplierCategories && supplierCategories[0] && supplierCategories[0].SUP_NAME && supplierCategories[0].SUP_NAME!=='undefined'? supplierCategories[0].SUP_NAME: ''})}  partNumber={``} supId={supdId} />
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      
    )
 
}

const GetChildrens = (STR_ID_PARENT, rows) => {
  return rows.filter((row) => row.STR_ID_PARENT = STR_ID_PARENT);
}

const ImageWithFallback = ({ src, index, alt, fallbackText }) => {
    const [imgError, setImgError] = useState(false);
  
    const handleError = () => {
        setImgError(true);
    };

    const fallbackStyle = {
        textAlign: 'center',
        fontSize: '10px',
        padding: '10px',
        textOverflow: 'ellipsis',
        fontSizeAdjust: '0.545s',
    };
  
    const containerStyle = {
        position: 'relative',
        width: '120px',
        height: '65px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #CCC',
        padding: '11px',
        backgroundColor: '#FFF'
    };

    return (
        <div style={containerStyle}>
            {imgError ? (
                <div style={fallbackStyle}>{fallbackText}</div>
            ) : (
                <img 
                    style={{height: '50px', float: 'none'}} 
                    key={`img-part-${index}`} 
                    src={src} 
                    alt={alt} 
                    onError={handleError}  
                />
            )}
        </div>
    );
};
export default SupplierCatelog;