import { useContext } from "react";
import { ConfigContext } from "../Context/ConfigContext";

const AddToGarage = (CarIfo) => {
    var cars = localStorage.getItem('cars');
    if(cars){
        cars = JSON.parse(cars);
    }else{
        cars = [];
    }
    
    // Check if car with same PC_ID already exists
    const carExists = cars.some(car => car.PC_ID === CarIfo.PC_ID);
    if (carExists) {
        return; // Exit if car already exists
    }
    
    ///// Reset Selection /////////
    CarIfo.VIN = '';
    CarIfo.saved = 0;
    CarIfo.selected = 1;
    for(var i = 0; i < cars.length; i++){
        cars[i].selected = 0;
    }
    
    // Check if there are already 5 cars
    if (cars.length >= 5) {
        cars.shift(); // Remove the oldest car
    }
    
    cars.push(CarIfo);
    
    localStorage.setItem('cars', JSON.stringify(cars));
}
const SaveCar = (index) => {
    var cars = localStorage.getItem('cars');
    if(cars){
        cars = JSON.parse(cars);
        cars[index].saved = 1;
        localStorage.setItem('cars', JSON.stringify(cars));
    }
}

const SaveCarVIN = (index, VIN) => {
    var cars = localStorage.getItem('cars');
    if(cars){
        cars = JSON.parse(cars);
        cars[index].VIN = VIN;
        localStorage.setItem('cars', JSON.stringify(cars));
    }
}
const GetGarages = () => {
    
    var cars = localStorage.getItem('cars');

    if(cars !== ''){
        cars = JSON.parse(cars);
    }else{
        cars = [];
    }
    
    return cars;
}
const SelectCarGarage = async(index) => {
    
    var cars = localStorage.getItem('cars');
    if(cars){
        cars = JSON.parse(cars);
    }else{
        cars = [];
    }
    
    for(var i = 0; i < cars.length; i++){
        if(index === i){
            cars[i].selected = 1;
        }else{
            cars[i].selected = 0;
        }
    }
   
   
    localStorage.setItem('cars', JSON.stringify(cars)); 
    return cars;
}
const SelectCarGarageByPCID = async(PC_ID) => {
    
    var cars = localStorage.getItem('cars');
    if(cars){
        cars = JSON.parse(cars);
    }else{
        cars = [];
    }
    
    for(var i = 0; i < cars.length; i++){
        if(PC_ID === cars[i].PC_ID){
            cars[i].selected = 1;
        }else{
            cars[i].selected = 0;
        }
    }
   
   
    localStorage.setItem('cars', JSON.stringify(cars)); 
    return cars;
}
const RemoveCarGarage = async(index) => {
    
    var cars = localStorage.getItem('cars');
    if(cars){
        cars = JSON.parse(cars);
    }else{
        cars = [];
    }
    
    cars.splice(index, 1);
   
   
    localStorage.setItem('cars', JSON.stringify(cars)); 
    return cars;
}
const ClearGarage = async() => {
   
    localStorage.setItem('cars', '');
    return true;
}
export {AddToGarage, GetGarages, ClearGarage, SelectCarGarage, RemoveCarGarage, SelectCarGarageByPCID}