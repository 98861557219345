import React, { useContext, useEffect, useRef, useState } from "react";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../Context/ConfigContext";

import { useParams } from "react-router-dom";
import { Spinner } from "../../Components/Shimmers";
import slugify from "slugify";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import { Helmet } from "react-helmet";
import UniCarSearch from "../Cars/UniCarSearch";
import PartsHorizontalCard from "../../Components/Cards/PartsHorizontalCard";
import ClipLoader from "react-spinners/ClipLoader";
import MobPartsHorizontalCard from "../../Components/Cards/MobPartsHorizontalCard";


const MobParts = () => {
  const { currency, apiURL } = useContext(ConfigContext);
  const location = useLocation();
  const { t } = useTranslation();
  const locale = localStorage.getItem("lang") ?? "en";
  const { gdcURL } = useContext(ConfigContext);
  const dir = localStorage.getItem("dir");
  var { slug } = useParams();
  var STR_ID = slug.split("-").slice(-1);
  const [loading, setLoading] = useState(false);
  const [loadingOE, setLoadingOE] = useState(true);
  const [loadingIAM, setLoadingIAM] = useState(true);
  const [parts, setParts] = useState([]);
  const [OEParts, setOEParts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedOE, setSelectedOE] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedBrandTags, setSelectedBrandTags] = useState([]);

  const [ProductGroups, setProductGroups] = useState([]);
  const [TotalOEParts, setTotalOEParts] = useState(0);
  const [TotalIAMParts, setTotalIAMParts] = useState(0);
  const [TotalParts, setTotalParts] = useState(0);

  const [CurrentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [TotalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [CarBrand, setCarBrand] = useState('');
  const [isSticky, setSticky] = useState(false);
  const [OEEnable, setOEEnable] = useState(false); 
  const [selectedBrandsArray, setSelectedBrandsArray] = useState([]); 
  const [responsive, setResponsive] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 10,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
  });
  const [checking, setChecking] = useState(true);
  useState(() => {
    if (localStorage.getItem('pc_id') && localStorage.getItem('pc_id') != 0 && localStorage.getItem('pc_id') != null) {
      setChecking(false);
    } else {
      var nocar = window.location.href.replaceAll('Spare-Parts', 'Spare-Parts-nocar');
      window.location = nocar;
      setChecking(true);
    }
  }, [])
  const [car_info, setCarInfo] = useState(
    localStorage.getItem("car_info")
      ? JSON.parse(localStorage.getItem("car_info"))
      : []
  );
  const [loadMore, setLoadMore] = useState(false);
  const loadMoreRef = useRef(null);
  const queryParams = new URLSearchParams(location.search);

  // Get the value of the "keyword" parameter
  const selectedPTID = queryParams.get('group');
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("pc_id")) {
      fetch(`${gdcURL}/Parts/GetBrands?pc_id=${localStorage.getItem("pc_id")}&str_id=${STR_ID}&lang=${locale}&currency=${currency}&limit=${limit}&page=${page}&pt_id=${selectedPTID}&brands=${selectedBrands}`)
        .then((res) => res.json())
        .then((data) => {

          setBrands(data.data);
        })
      }
  }, [])
  useEffect(() => {
    if (localStorage.getItem("pc_id")) {
      
      fetch(`${gdcURL}/Parts/GetProductGroups?pc_id=${localStorage.getItem("pc_id")}&str_id=${STR_ID}&lang=${locale}&currency=${currency}&limit=${limit}&page=${page}&pt_id=${selectedPTID}&brand=${localStorage.getItem('make')}`)
        .then((res) => res.json())
        .then((data) => {
          setProductGroups(data.data);
        })
      setSelectedBrands([]);
      setSelectedBrandTags([]);
      setProductGroups([]);

      setParts([])
      if (!queryParams.get('supplier')) {
        console.log("!query", queryParams.get('supplier'))
        LoadParts();

      } else {
        console.log("query", queryParams.get('supplier'))
      }

    }
  }, [location]);
  var supp = queryParams.get('supplier');
  useEffect(() => {

    // if (supp && supp.split('-')) {
    //   if (queryParams.get('supplier').split('-')) {
    //     console.log("supp", supp.split('-')[0], supp.split('-')[1])
    //     toggleBrand(parseInt(supp.split('-')[0]), supp.split('-')[1], supp.split('-')[2]);
    //   }

    // }
  }, [])
  // useEffect(() => {
  //   if(page != 0){
  //     LoadParts();
  //   }

  // }, [page]);
  // useEffect(() => {
  //   var MFA_BRAND = localStorage.getItem("car_info")?JSON.parse(localStorage.getItem("car_info"))[0].MFA_BRAND:'';
  //       setCarBrand(MFA_BRAND);
  //       setLoadingOE(true)
  //       // console.log(JSON.parse(localStorage.getItem("car_info"))[0])
  //       fetch( `${gdcURL}/Parts/GetOEPartsByCar?pc_id=${localStorage.getItem( "pc_id" )}&str_id=${STR_ID}&lang=${locale}&currency=${currency}&limit=${limit}&page=${page}&pt_id=${selectedPTID}&brand=${MFA_BRAND}` )
  //       .then((res) => res.json())
  //       .then((data) => {

  //         setOEParts(data.data)
  //         setLoading(false);
  //         setLoadingOE(false)

  //       })
  //       .catch((error) => {
  //         console.error("Fetch error:", error);
  //         setLoading(false);
  //         setLoadingOE(false)
  //       });
  // }, [])
  const LoadParts = () => {
    
    if (localStorage.getItem("pc_id")) {
      setLoading(true);
      setLoadingIAM(true)
      fetch(`${gdcURL}/Parts/GetPartsByCar?pc_id=${localStorage.getItem("pc_id")}&str_id=${STR_ID}&lang=${locale}&currency=${currency}&limit=${limit}&page=${page}&pt_id=${selectedPTID}&brands=${selectedBrands}&selectedBrands=${selectedBrandsArray?JSON.stringify(selectedBrandsArray):''}`)
        .then((res) => res.json())
        .then((data) => {
          setParts(data.data);
          // Check if any part has ART_TYPE === 'OENumber'
          const hasOENumber = data.data.some(part => part.ART_TYPE === 'OENumber');
          setOEEnable(hasOENumber);

          // setParts((prevProducts) => [...prevProducts, ...data.data]);
          // setParts((prevProducts) => {
          //   var nData = [...prevProducts, ...data.data]
          //   return removeDuplicates(nData)
          // });

          setTotalPages(data.paggination.pages);
          setTotalParts(data.paggination.counts);
          setTotalOEParts(data.oe_count);
          setLoading(false);
          setLoadMore(false);
          setLoadingIAM(false)

        })
        .catch((error) => {
          console.error("Fetch error:", error);
          setLoading(false);
          setLoadingIAM(false)
        });

    }
  }

  const LoadMoreParts = () => {
    console.log("LoadMoreParts Called")
    if (localStorage.getItem("pc_id")) {
      fetch(`${gdcURL}/Parts/GetPartsByCar?pc_id=${localStorage.getItem("pc_id")}&str_id=${STR_ID}&lang=${locale}&currency=${currency}&limit=${limit}&page=${page + 1}&pt_id=${selectedPTID}&brands=${selectedBrands}`)
        .then((res) => res.json())
        .then((data) => {
          setParts(prevParts => [...prevParts, ...data.data]);
          setPage(prevPage => prevPage + 1); // Increment page number
        })
        .catch((error) => {
          console.error("Fetch error:", error);
        });
    }
  }

  const removeDuplicates = (array) => {
    const uniqueKeys = new Set();
    return array.filter(item => {
      // Create a unique key for each object based on specified properties
      const key = `${item.ART_ARTICLE_NR}-${item.PRODUCT_GROUP}-${item.SUP_ID}`;
      if (!uniqueKeys.has(key)) {
        uniqueKeys.add(key);
        return true; // Keep the item
      }
      return false; // Discard the item
    });
  };
  
  useEffect(() => {

    setParts([])
    LoadParts();
  }, [selectedBrandTags]);

  useEffect(() => {
   
  }, [selectedBrandsArray])
  const toggleBrand = (SUP_ID, SUP_BRAND, ART_TYPE) => {
    setSelectedBrands((prevSelectedBrands) => {
      if (prevSelectedBrands.includes(SUP_ID)) {
        // Remove SUP_ID if it is already in the array
        return prevSelectedBrands.filter((id) => id !== SUP_ID);
      } else {
        // Add SUP_ID if it is not in the array
        return [...prevSelectedBrands, SUP_ID];
      }
    });

    // Update selectedBrandsArray
    setSelectedBrandsArray((prevArray) => {
      const index = prevArray.findIndex((item) => item.SUP_ID === SUP_ID);
      if (index === -1) {
        // If not found, add new item
        var clearBrand = SUP_BRAND; 
        const newArray = [...prevArray, { SUP_ID, SUP_BRAND, ART_TYPE }];
        console.log('Added to selectedBrandsArray:', newArray);
       
        return newArray;
      } else {
        // If found, remove the item
        const newArray = prevArray.filter((item) => item.SUP_ID !== SUP_ID);
        console.log('Removed from selectedBrandsArray:', newArray);
        return newArray;
      }
    });

    let setSelectedBTs = [...selectedBrandTags];
    const index = selectedBrandTags.findIndex((tag) => tag.SUP_ID === SUP_ID);
    if (index === -1) {
      setSelectedBTs.push({ SUP_ID, SUP_BRAND, ART_TYPE });
    } else {
      setSelectedBTs.splice(index, 1);
    }

    setSelectedBrandTags(setSelectedBTs);
    setPage(0);
  };

  const toggleOE = () => {
    if (selectedOE === false || selectedOE === null) {
      setSelectedOE(true);
    } else {
      if (selectedBrandTags.length === 0) {
        setSelectedOE(null);
      } else {
        setSelectedOE(false);
      }
    }
  };

  useEffect(() => {
    LoadParts();
}, [page]);
  const handlePageChange = (newPage) => {
    setPage(newPage);
    LoadParts();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <div className="carousel-button-group">
        <button style={{ padding:'2px 6px', position: 'absolute', left: 0, top: '8px', marginLeft: '-40px' }} className={currentSlide === 0 ? 'btn btn-outline-dark btn-sm disable' : 'btn btn-outline-dark btn-sm '} onClick={() => previous()} > <i className="fas fa-chevron-left"></i> </button>
        <button style={{ padding:'2px 6px', position: 'absolute', right: 0, top: '8px', marginRight: '-30px' }} className="btn btn-outline-dark btn-sm" onClick={() => next()} ><i className="fas fa-chevron-right"></i></button>
      </div>
    );
  };
  const BrandsTags = () => {
    return parts && parts.length > 0 &&  localStorage.getItem("pc_id") &&
     selectedBrandTags && (
       <div>
         <h6 style={{ lineHeight: 0, marginTop: "10px" }}>
         {selectedBrandTags.length!== 0 && t("selected_filters")}
         </h6>
         
         {selectedBrandTags && selectedBrandTags.map((item, index) => {
             return (
               <span
                 onClick={() =>
                   toggleBrand(item.SUP_ID, item.SUP_BRAND)
                 }
                 className="badge badge-pill  mr-4 pt-2 pb-2"
                 style={{ backgroundColor: "#e9e9e9" }}
                 key={`tags-${item.SUP_ID}`}>
                 {item.SUP_BRAND}{" "}
                 <i className="fas fa-times-circle lightColorText"></i>{" "}
               </span>
             );
           })}
       </div>
     )
  
  }
  const BrandsOptions = () => {
    var divs = <div style={{ position: 'relative' }} className={`prevent-select ${selectedBrandTags && selectedBrandTags.length!== 0 && 'mt-2'}`}>

      <Carousel
        arrows={false}
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={false}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        // removeArrowOnDeviceType={["tablet", "mobile"]}
        // deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px">
        {car_info && car_info[0] && car_info[0].PC_MFA_ID && TotalOEParts > 0 && selectedBrandsArray && (
          <div
            onClick={() => toggleBrand(car_info[0].PC_MFA_ID, car_info[0].MFA_BRAND, 'OE')}
            key={`img-sup-brand-100288`}
            style={{
              cursor: "pointer",
              height: "40px",
              width: "80px",
              textAlign: "center",
              border:
              selectedBrandsArray.find((item) => item.ART_TYPE === 'OE')
                  ? "2px solid #cc000b":"0px solid #CCC"
                  ,
              marginLeft: "10px",
            }}>
            <ImageWithFallback
              OE={true}
              src={`${gdcURL}/make_logo/${car_info[0].PC_MFA_ID}.png`}
              alt={car_info[0].MFA_BRAND}
              index={101102}
              fallbackText={car_info[0].MFA_BRAND}
            />
          </div>
        )}

        {brands &&
  [...brands]
    .sort((a, b) => selectedBrands.includes(b.SUP_ID) - selectedBrands.includes(a.SUP_ID))
    .map((img, index) => {
      return (
        <div
          key={`img-sup-brand-${index}`}
          onClick={() => {
            toggleBrand(
              img.SUP_ID,
              img.ART_SUP_BRAND.trim().replace(/[\r\n]/g, '').replace(/&/g, '__AMP__'),
              'IAM'
            );
            window.scrollTo(0, 0);
          }}
          style={{
            cursor: 'pointer',
            height: '40px',
            width: '80px',
            textAlign: 'center',
            border: selectedBrands.includes(img.SUP_ID)
              ? '2px solid #cc000b'
              : '0px solid #CCC',
          }}
        >
          <ImageWithFallback
            src={`${gdcURL}/sup-logo/${img.SUP_LOGO_NAME}`}
            alt={img.ART_SUP_BRAND}
            index={index}
            fallbackText={img.ART_SUP_BRAND}
            OE={false}
          />
        </div>
      );
    })}

      </Carousel>
    </div>
    return divs;
  }
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 200) { // Adjust this value as needed
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [title, setTitle] = useState("Ghayar");
  useEffect(() => {
    try{
      var tt = slug && slug.split("-")  && slug.split("-").slice(0, -1).join(" "); 
      tt +=" "+car_info && car_info[0] && car_info[0].TYPEL;
      tt +=" "+car_info && car_info[0] && car_info[0].sr_label; 
    }catch(err){
      setTitle('Ghayar');
    }
  }, [])
 
  let [color, setColor] = useState("#ffffff");
  return (
    checking ? (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}>
        <ClipLoader
        color={color}
        loading={true}
        // cssOverride={override}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      </div>
    ) : <div
    
    style={{
      borderBottom: "1px solid rgb(226, 226, 226)",
      marginBottom: "10px",
    }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
    <div className="container">
      
          <div className="row justify-content-center">
            <div className="col-md-3">
                <UniCarSearch />
                {ProductGroups && ProductGroups.length > 1 &&
                  <div className="widget widget-size mb-4" style={{ border: '1px solid #e6e6e6', borderRadius: '5px' }}>

                    <div className="collapse show" id="widget-body-5">

                      <div className="widget-body">
                        <div className="custom-control custom-radio pb-4 ml-4" onClick={() => { navigate(`/Spare-Parts/${slug}`); window.scrollTo(0, 0) }} style={{ borderBottom: '1px solid #e6e6e6', marginTop: 0, marginBottom: 0 }}>
                          <input type="radio" className="custom-control-input" name="pt_ids" onChange={() => { navigate(`/Spare-Parts/${slug}?group=${selectedPTID}`); window.scrollTo(0, 0) }} checked={selectedPTID === null && true} />
                          <label className="custom-control-label"> {t('all_groups')} </label>
                          <i className="sicon-arrow-right align-right" style={{ float: 'right', marginRight: '10px', marginTop: '5px' }}></i>
                        </div>

                        {ProductGroups &&
                          ProductGroups.map((item, index) => {
                            return (
                              <div key={`pt-group-${item.PT_ID}-${index}`} className="custom-control custom-radio  pb-4 ml-4" onClick={() => { window.location.href = `/Spare-Parts/${slug}?group=${item.PT_ID}`; window.scrollTo(0, 0) }} style={{ borderBottom: '1px solid #e6e6e6', marginTop: '10px', marginBottom: 0 }}>
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="pt_ids"
                                  onChange={() => { window.location.href = `/Spare-Parts/${slug}?group=${item.PT_ID}`; window.scrollTo(0, 0) }}
                                  checked={selectedPTID && Number(selectedPTID) === item.PT_ID && true}
                                />
                                <label className="custom-control-label">
                                  {item.PRODUCT_GROUP}
                                </label>
                                <i className="sicon-arrow-right align-right" style={{ float: 'right', marginRight: '10px', marginTop: '5px' }}></i>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                }
            </div>

            <div className="col-md-9">
              <h2 className="mt-2" style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                {slug && slug.split("-").slice(0, -1).join(" ")} {car_info && car_info[0] && car_info[0].TYPEL} {car_info && car_info[0] && car_info[0].sr_label}
                </h2>

                <span className="mt-2" style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '14px', fontWeight: '500', lineHeight: '24px' }}>
                {t('best_department_for', {'department': (slug && slug.split("-").slice(0, -1).join(" ")), 'car': car_info && car_info[0] && car_info[0].TYPEL})}
                
                </span>
              <div >

                
                    <div className={dir==='rtl'?"pr-5":'pl-5 pr-5'}>
                    {brands && <BrandsTags />}
                    {brands && <BrandsOptions />}
                    </div>
                
              </div>
              <div className="row justify-content-center">
                {loading && (
                    <div className="mt-5">
                      <center>
                        <Spinner />
                      </center>
                    </div>
                  )}
              </div>
              {localStorage.getItem("pc_id") && (
                  <div className="row">
                    <div className="container pt-5">
                      {parts.length > 0 && t('total_items', { total: TotalParts ? TotalParts : 0 })}
                    </div>
                    <div className="row pb-4">
                      {parts && parts.length > 0 && parts.map((part, index) => {

                        var part_slug =
                          slugify(`${part.PRODUCT_GROUP}`.toLowerCase()) +
                          "-" +
                          slugify(`${part.ART_SEARCH_NUMBER}`.toLowerCase()) +
                          "-" +
                          STR_ID +
                          "-" +
                          slugify(`${part.SUP_ID}`.toLowerCase()) +
                          "-" +
                          slugify(`${part.ART_ID}`.toLowerCase());
                        var partImg = part.MEDIA_SOURCE ? `${gdcURL}/images/${part.MEDIA_SOURCE}` : `${apiURL}/public/placeholder_category.jpeg`
                        return (
                          <MobPartsHorizontalCard
                            key={`r-part-id-${part.ART_ID}-${index}`}
                            partImg={partImg}
                            criteria={part.ARTICLE_CRITERIA}
                            url={part.ART_TYPE === 'OENumber' ? `/OE-Spare-Parts/Details/${part_slug}` : `/Spare-Parts/Details/${part_slug}`}
                            id={part.ART_ID}
                            title={part.PRODUCT_GROUP}
                            number={part.ART_ARTICLE_NR}
                            price={part.VD_PRICE ?? 0}
                            display_price={part.display_price ?? 0}
                            mrp={part.VD_PRICE_MRP ?? 0}
                            display_price_mrp={part.display_price_mrp ?? 0}
                            qty={part.VD_QTY}
                            imgUrl={partImg}
                            action={true}
                            brand={part.ART_SUP_BRAND}
                            suplogo={part.SUP_LOGO_NAME}
                            supid={part.SUP_ID}
                            redLabel={3 === 3 ? `Aftermarket` : "Genuine"}
                            partInfo={part.ART_INFO}
                            pt_intl={part.PT_INTL}
                            art_type={part.ART_TYPE}
                            mfa_id={part.MFA_ID}
                            part={part}
                          />
                        );

                      })}
                    </div>
                  </div>
                )}

                
                <div className="col-lg-12">
                { TotalPages > 1 && 
                  <div className="text-center mb-2">
                    <button
                      className="btn btn-dark pl-5 pr-5 pt-3 pb-3"
                      style={{ fontSize: '12px' }}
                      onClick={() => {
                        LoadMoreParts();
                      }}
                    >
                      <i className="fas fa-redo mr-2"></i> {t('load_more')}
                    </button>
                  </div>
                  }

                  {TotalPages > 1 && (
                    <div className="pagination-container">
                      <nav aria-label="Page navigation">
                        <ul style={{ color: '#0d1f2a' }} className="pagination justify-content-center">

                          <li className={`page-item ${page === 0 && 'disabled'}`}>
                            <button
                              className="page-link"
                              onClick={() => handlePageChange(page - 1)}
                              disabled={page === 0}
                            >
                              <i style={{ fontSize: '12px' }} className="fas fa-arrow-left"></i>
                            </button>
                          </li>

                          {Array.from({ length: TotalPages }, (_, i) => (
                            <li
                              key={i}
                              className={`page-item ${page === i && 'active'}`}
                            >
                              <button
                                className="page-link"
                                onClick={() => handlePageChange(i)}
                              >
                                {i + 1}
                              </button>
                            </li>
                          ))}

                          <li className={`page-item ${page === TotalPages - 1 && 'disabled'}`}>
                            <button
                              className="page-link"
                              onClick={() => handlePageChange(page + 1)}
                              disabled={page === TotalPages - 1}
                            >
                              <i style={{ fontSize: '12px' }} className="fas fa-arrow-right color-dark"></i>
                            </button>
                          </li>

                        </ul>
                      </nav>
                    </div>
                  )}
                </div>
            </div>
          </div>
          

    </div>
    {/* End .container */}
  </div>
  )

  return (
    
    <main className="main" dir={dir}>
      <div className="">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <Helmet>
              <title>{slug.split("-").slice(0, -1).join(" ")}</title>
              <meta name="keywords" content={localStorage.getItem("pc_id") && loading === false &&

                parts && parts.length > 0 && ((selectedBrands.length !== 0 && selectedOE === true) ||
                  selectedOE !== true) &&
                parts.map((part, index) => {
                  return `${part.PRODUCT_GROUP} - ${part.ART_ARTICLE_NR} - ${part.ART_SUP_BRAND}`
                })
              } />
            </Helmet>
            <div className="row justfiy-content-center">
              <div className="col-lg-5">
                <UniCarSearch />
                {ProductGroups && ProductGroups.length > 0 &&
                  <div className="widget widget-size mb-4" style={{ border: '1px solid #e6e6e6', borderRadius: '5px' }}>

                    <div className="collapse show" id="widget-body-5">

                      <div className="widget-body">
                        <div className="custom-control custom-radio pb-4" onClick={() => { navigate(`/Spare-Parts/${slug}`); window.scrollTo(0, 0) }} style={{ borderBottom: '1px solid #e6e6e6', marginTop: 0, marginBottom: 0 }}>
                          <input type="radio" className="custom-control-input" name="pt_ids" onChange={() => { navigate(`/Spare-Parts/${slug}?group=${selectedPTID}`); window.scrollTo(0, 0) }} checked={selectedPTID === null && true} />
                          <label className="custom-control-label"> {t('all_groups')} </label>
                          <i className="sicon-arrow-right align-right" style={{ float: 'right', marginRight: '10px', marginTop: '5px' }}></i>
                        </div>

                        {ProductGroups &&
                          ProductGroups.map((item, index) => {
                            return (
                              <div key={`pt-group-${item.PT_ID}-${index}`} className="custom-control custom-radio  pb-4" onClick={() => { window.location.href = `/Spare-Parts/${slug}?group=${item.PT_ID}`; window.scrollTo(0, 0) }} style={{ borderBottom: '1px solid #e6e6e6', marginTop: '10px', marginBottom: 0 }}>
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="pt_ids"
                                  onChange={() => { window.location.href = `/Spare-Parts/${slug}?group=${item.PT_ID}`; window.scrollTo(0, 0) }}
                                  checked={selectedPTID && Number(selectedPTID) === item.PT_ID && true}
                                />
                                <label className="custom-control-label">
                                  {item.PRODUCT_GROUP}
                                </label>
                                <i className="sicon-arrow-right align-right" style={{ float: 'right', marginRight: '10px', marginTop: '5px' }}></i>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                }

              </div>
              <div className="col-lg-7">
                <nav aria-label="breadcrumb" className="breadcrumb-nav mb-3">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to={"/"}>
                        <i className="icon-home" />
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to={`/Spare-Parts/Catelog`}>{t("catelog")}</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {slug.split("-").slice(0, -1).join(" ")}
                    </li>
                  </ol>
                </nav>

                {!localStorage.getItem("pc_id") && (
                  <div className="container mb-1 mt-1 pb-2 pt-2">
                    <div
                      className="alert alert-info"
                      style={{ textAlign: "center", display: 'none' }}>
                      <div className="col-sm-12">
                        {localStorage.getItem("lang") === null ||
                          localStorage.getItem("lang") === "en" ? (
                          <div>
                            To ensure you get the right parts that fit your car
                            perfectly, please select your car before exploring our
                            wide range of products. This helps us tailor the best
                            options specifically for your make and model,
                            guaranteeing a perfect fit and optimal performance every
                            time.
                            <br />
                            <b>Select Your Car</b> Now and start shopping with
                            confidence!
                          </div>
                        ) : (
                          <div>
                            <b>للحصول على القطع المناسبة لسيارتك!</b>
                            <br />
                            لتضمن حصولك على القطع التي تناسب سيارتك تمامًا، يرجى
                            اختيار سيارتك قبل استكشاف مجموعة منتجاتنا الواسعة.
                            يساعدنا هذا في تقديم أفضل الخيارات التي تتناسب مع طراز
                            سيارتك لضمان ملاءمة مثالية وأداء أمثل في كل مرة.
                            <br />
                            <b>اختر سيارتك الآن وابدأ التسوق بثقة!</b>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {(loadingOE === false && loadingIAM === false) && loading === false && parts.length === 0 && OEParts.length === 0 && localStorage.getItem("pc_id") &&
                  <div className="container mb-1 mt-1 pb-2 pt-2">
                    <div className="alert alert-info" style={{ textAlign: "center" }}>
                      <div className="col-sm-12">
                        {localStorage.getItem("lang") === null || localStorage.getItem("lang") === "en" ?
                          <div className="no-results">
                            <h2>No Results Found</h2>
                            <p>We couldn't find any parts that match your search. Please try adjusting your search terms or explore other categories.</p>
                            <NavLink to={`/Spare-Parts/Catelog`} className="btn btn-primary">{t('open_catelog')}</NavLink>
                          </div> :

                          <div className="no-results" >
                            <h2>لا توجد نتائج</h2>
                            <p>لم نتمكن من العثور على أي قطع تطابق بحثك. يرجى تعديل كلمات البحث أو استكشاف الفئات الأخرى.</p>
                            <NavLink to={`/Spare-Parts/Catelog`} className="btn btn-primary">{t('open_catelog')}</NavLink>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                }


                {(loadingOE === false && loadingIAM === false) && parts && parts.length > 0 &&
                  localStorage.getItem("pc_id") &&
                  car_info.length !== 0 && (
                    <h4 className="my_title">
                      {t("actual_offers_of", {
                        category: slug.split("-").slice(0, -1).join(" "),
                        car_info: car_info[0].TYPEL,
                      })}
                    </h4>
                  )}
                <br />
                <div style={isSticky ? { 
                  position: 'fixed', 
                  top: '0', 
                  backgroundColor: '#FFF', 
                  width: '100%', 
                  left: '0', 
                  zIndex: '1000', 
                  padding: '10px 0',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                } : {}}>
                  {(loadingOE === false && loadingIAM === false) && parts && parts.length > 0 &&
                    localStorage.getItem("pc_id") &&
                    selectedBrandTags && (
                      <div>
                        <h6 style={{ lineHeight: 0, marginTop: "10px" }}>
                          {t("selected_filters")}
                        </h6>
                        {/* {selectedOE && (
                    <span
                      onClick={() => toggleOE()}
                      className="badge badge-pill  mr-4 pt-2 pb-2"
                      style={{ backgroundColor: "#e9e9e9" }}
                      key={`tags-OE-brand`}>
                      {car_info[0].MFA_BRAND}{" "}
                      <i className="fas fa-times-circle lightColorText"></i>{" "}
                    </span>
                  )} */}
                        {selectedBrandTags &&
                          selectedBrandTags.map((item, index) => {
                            return (
                              <span
                                onClick={() =>
                                  toggleBrand(item.SUP_ID, item.SUP_BRAND, item.ART_TYPE)
                                }
                                className="badge badge-pill  mr-4 pt-2 pb-2"
                                style={{ backgroundColor: "#e9e9e9" }}
                                key={`tags-${item.SUP_ID}`}>
                                {item.SUP_BRAND}{" "}
                                <i className="fas fa-times-circle lightColorText"></i>{" "}
                              </span>
                            );
                          })}
                      </div>
                    )}

                  {parts && localStorage.getItem("pc_id") && (
                    <div className="row">
                      <div className="col-12">
                        {brands && <BrandsOptions />}
                      </div>
                    </div>
                  )}
                </div>
                {parts.length > 0 && t('total_items', { total: TotalParts ? TotalParts : 0 })}

                {/* {localStorage.getItem("pc_id") &&
              (selectedBrands.length === 0 || selectedOE === true) && (
                <div
                  className="container mb-1 mt-1 pb-2 pt-2"
                  style={{
                    borderBottom: "1px solid #f3f3f3",
                    borderTop: "1px solid #f3f3f3",
                  }}>
                  <div className="col-lg-12">
                    <OEParts setTotal={setTotalOEParts} STR_ID={STR_ID} SELECTED_PTID={selectedPTID} />
                  </div>
                </div>
              )} */}
                {loading && (
                  <div className="mt-5">
                    <center>
                      <Spinner />
                    </center>
                  </div>
                )}

                {/* {localStorage.getItem("pc_id") && (
              <div className="row">
                <div className="col-lg-9 order-lg-1"> </div>
                <div className="row pb-4">
                  {OEParts && OEParts.length > 0 &&
                    (selectedBrands.length === 0 || selectedOE === true) && 
                      OEParts.map((part, index) => {
                    
                        var part_slug =
                          slugify(`${part.PRODUCT_GROUP}`.toLowerCase()) +
                          "-" +
                          slugify(`${part.ARL_SEARCH_NUMBER}`.toLowerCase()) +
                          "-" +
                          STR_ID +
                          "-" +
                          slugify(`${part.SUP_ID}`.toLowerCase()) +
                          "-" +
                          slugify(`${part.ART_ID}`.toLowerCase()) ;
                          var partImg = part.MEDIA_SOURCE?part.MEDIA_SOURCE:`${apiURL}/public/placeholder_category.jpeg`
                        return (
                          <PartsHorizontalCard
                            key={`r-part-id-${part.ART_ID}-${index}`}
                            partImg={partImg}
                            criteria={part.ARTICLE_CRITERIA}
                            url={`/OE-Spare-Parts/Details/${part_slug}`}
                            id={part.ART_ID}
                            title={part.PRODUCT_GROUP}
                            number={part.ARL_SEARCH_NUMBER}
                            price={part.VD_PRICE ?? 0}
                            display_price={part.display_price ?? 0}
                            mrp={part.VD_PRICE_MRP ?? 0}
                            display_price_mrp={part.display_price_mrp ?? 0}
                            qty={part.VD_QTY}
                            imgUrl={partImg} 
                            action={true}
                            brand={part.ARL_BRA_BRAND}
                            suplogo={part.SUP_LOGO_NAME}
                            supid={part.SUP_ID}
                            redLabel={3 === 3 ? `Aftermarket` : "Genuine"}
                            partInfo={part.ART_INFO}
                            pt_intl={part.PT_INTL}
                          />
                        );
                      
                    })}
                </div>
              </div>
            )} */}

                {localStorage.getItem("pc_id") && (
                  <div className="row">
                    <div className="col-lg-9 order-lg-1"> </div>
                    <div className="row pb-4">
                      {parts && parts.length > 0 && parts.map((part, index) => {

                        var part_slug =
                          slugify(`${part.PRODUCT_GROUP}`.toLowerCase()) +
                          "-" +
                          slugify(`${part.ART_SEARCH_NUMBER}`.toLowerCase()) +
                          "-" +
                          STR_ID +
                          "-" +
                          slugify(`${part.SUP_ID}`.toLowerCase()) +
                          "-" +
                          slugify(`${part.ART_ID}`.toLowerCase());
                        var partImg = part.MEDIA_SOURCE ? `${gdcURL}/images/${part.MEDIA_SOURCE}` : `${apiURL}/public/placeholder_category.jpeg`
                        return (
                          <PartsHorizontalCard
                            key={`r-part-id-${part.ART_ID}-${index}`}
                            partImg={partImg}
                            criteria={part.ARTICLE_CRITERIA}
                            url={part.ART_TYPE === 'OENumber' ? `/OE-Spare-Parts/Details/${part_slug}` : `/Spare-Parts/Details/${part_slug}`}
                            id={part.ART_ID}
                            title={part.PRODUCT_GROUP}
                            number={part.ART_ARTICLE_NR}
                            price={part.VD_PRICE ?? 0}
                            display_price={part.display_price ?? 0}
                            mrp={part.VD_PRICE_MRP ?? 0}
                            display_price_mrp={part.display_price_mrp ?? 0}
                            qty={part.VD_QTY}
                            imgUrl={partImg}
                            action={true}
                            brand={part.ART_SUP_BRAND}
                            suplogo={part.SUP_LOGO_NAME}
                            supid={part.SUP_ID}
                            redLabel={3 === 3 ? `Aftermarket` : "Genuine"}
                            partInfo={part.ART_INFO}
                            pt_intl={part.PT_INTL}
                            art_type={part.ART_TYPE}
                            mfa_id={part.MFA_ID}
                            part={part}
                          />
                        );

                      })}
                    </div>
                  </div>
                )}
                <div className="col-lg-12">
                  <div className="text-center mb-2">
                    <button
                      className="btn btn-dark pl-5 pr-5 pt-3 pb-3"
                      style={{ fontSize: '12px' }}
                      onClick={() => {
                        LoadMoreParts();
                      }}
                    >
                      <i className="fas fa-redo mr-2"></i> {t('load_more')}
                    </button>
                  </div>

                  {TotalPages > 1 && (
                    <div className="pagination-container">
                      <nav aria-label="Page navigation">
                        <ul style={{ color: '#0d1f2a' }} className="pagination justify-content-center">

                          <li className={`page-item ${page === 0 && 'disabled'}`}>
                            <button
                              className="page-link"
                              onClick={() => handlePageChange(page - 1)}
                              disabled={page === 0}
                            >
                              <i style={{ fontSize: '12px' }} className="fas fa-arrow-left"></i>
                            </button>
                          </li>

                          {Array.from({ length: TotalPages }, (_, i) => (
                            <li
                              key={i}
                              className={`page-item ${page === i && 'active'}`}
                            >
                              <button
                                className="page-link"
                                onClick={() => handlePageChange(i)}
                              >
                                {i + 1}
                              </button>
                            </li>
                          ))}

                          <li className={`page-item ${page === TotalPages - 1 && 'disabled'}`}>
                            <button
                              className="page-link"
                              onClick={() => handlePageChange(page + 1)}
                              disabled={page === TotalPages - 1}
                            >
                              <i style={{ fontSize: '12px' }} className="fas fa-arrow-right color-dark"></i>
                            </button>
                          </li>

                        </ul>
                      </nav>
                    </div>
                  )}
                </div>
                <div ref={loadMoreRef} style={{ visibility: 'hidden' }}>LoadMOre {TotalPages} === {page}</div>
                <div className="row justify-content-center">
                  <div className="col-sm-2 pb-5">
                    {loadMore === true && (
                      <div className="mt-5">
                        <center>
                          <Spinner />
                        </center>
                      </div>
                    )}
                    {/* <ul className="pagination toolbox-item" style={{margin: 'auto', float: 'none'}}>
                <li className="page-item" onClick={() => {handlePrev()}}>
                  <a className="page-link page-link-btn">
                    <i className="icon-angle-left" />
                  </a>
                </li>
                 {
                  new Array(TotalPages).fill().map((_,index) => {
                    return <li className={`page-item ${index === page && 'active'}`} onClick={() => {setPage(index);  window.scrollTo(0, 0);}}>
                    <a className="page-link">
                      {index+1} {index === page && <span className="sr-only">(current)</span>}
                    </a>
                  </li>
                  })
                 }
                  
                  
                  <li className="page-item" onClick={() => {handleNext()}}>
                    <a className="page-link page-link-btn">
                      <i className="icon-angle-right" />
                    </a>
                  </li>
                </ul> */}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
const ImageWithFallback = ({ src, index, alt, fallbackText, OE }) => {
  const [imgError, setImgError] = useState(false);

  const handleError = () => {
    setImgError(true);
  };
  const fallbackStyle = {
    textAlign: "center",
    fontSize: "10px",
    padding: "10px",
    wordBreak: "break-word",
    maxWidth: "90%",
  };

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <div style={containerStyle}>
      {imgError ? (
        <div style={fallbackStyle}>{fallbackText}</div>
      ) : (
        <img
          draggable="false"
          style={{
            height: "30px",
            margin: "auto",
            float: "none",
            marginTop: OE === false ? "5px" : 0,
          }}
          key={`img-part-${index}`}
          src={src}
          alt={alt}
          onError={handleError}
        />
      )}
    </div>
  );
};
export default MobParts;
