import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

import slugify from "slugify";
import { NavLink, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../../Context/ConfigContext";
import CatelogModal from "./CatelogModal";
import TabsItems from "./TabsItems";
import Tabs from "./Tabs";
const Sections = ({ tabNameForeign, from }) => {
    const { t } = useTranslation();
    const divRef = useRef(null);
    const dir = localStorage.getItem("dir");


    return (<>
        <section ref={divRef} className="" >

            <center >
                <h1 style={{ lineHeight: '0', fontSize: '30px', fontWeight: 'bold' }}>{t('spare_parts_catelogue')}</h1>
                
            </center>
            <div className="container" data-animation-name="fadeInUpShorter" >
                <Tabs from={from} />
                <center style={{marginTop: '-20px', direction: dir === 'rtl'?'ltr': ''}}><NavLink to={`/Spare-Parts/Catelog`}>{t('all_spare_parts_catelogue')} <i className="fas fa-chevron-right"></i></NavLink></center>
            </div>
        </section>
    </>)

}

export default Sections