import React, { useContext, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { CartProcess, GetCarts } from "../../Helper/Carts";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import { ConfigContext } from "../../Context/ConfigContext";
import Swal from "sweetalert2";
import withRouter from "../../Routes/withRouter";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import Invoice from "../../Components/Invoice/Invoice";
import { jsPDF } from "jspdf";
import { useTranslation, withTranslation } from 'react-i18next';
import { ToastContainer, toast } from "react-toastify";
import InvoicePDF from "../../Components/Invoice/InvoicePDF";
import { FaExclamationTriangle } from 'react-icons/fa';
import './Cart.css';
const Failure = () => {
    const { t } = useTranslation();
    const {order_uid} = useParams();
    const { apiURL, token, lang } = useContext(ConfigContext);
    const [orderInfo, setOrderInfo] = useState([]);
    const [address, setAddress] = useState([]);
    const [details, setDetails] = useState([]);
    const [items, setItems] = useState([]);
    const [success, setSuccess] = useState(true);

    useEffect(() => {
       
    }, []);


   
    const dir = localStorage.getItem('dir');
    return (
        <main className="main" dir={dir}>
            <div className="payment-failure-container">
                <div className="failure-card">
                    <div className="icon-wrapper">
                        <FaExclamationTriangle className="failure-icon" />
                    </div>
                    <h1 className="failure-title">{t('payment_failed')}</h1>
                    <h2 className="failure-order-number">{t('order_number', {value: order_uid})}</h2>
                    <p className="failure-message">{t('payment_failed_message')}</p>
                    <div className="button-group">
                        <button 
                            className="retry-button"
                            onClick={() => window.location = `https://pay.ghayar.com/pay?order_id=${order_uid}&origin=ghayar.com&order_uid=${order_uid}`}
                        >
                            <i className="fas fa-redo"></i>
                            {t('try_again')}
                        </button>
                        <button 
                            className="home-button"
                            onClick={() => window.location.href = '/'}
                        >
                            <i className="fas fa-shopping-cart"></i>
                            {t('continue_shopping')}
                        </button>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Failure;