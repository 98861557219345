import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'

import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import slugify from 'slugify';

import Carousel from 'react-multi-carousel';
import axios from 'axios';
import { Spinner } from '../../../Components/Shimmers';

import Sections from './Sections';
import { ConfigContext } from '../../../Context/ConfigContext';
import CatelogModal from './CatelogModal';

import Tabs from './Tabs';
import useIsMobile from '../../../Helper/useIsMobile';
import MobSection from './MobSection';
import { Helmet } from 'react-helmet';

import UniCarSearch from '../../Cars/UniCarSearch';
import RecentViews from '../../Home/RecentViews';
import CarInfoBox from '../../Cars/CarInfoBox';
const Catelog = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locale = localStorage.getItem('lang');
  const { gdcURL, PCID, setCategories, categories, treeCategories, TillYear } = useContext(ConfigContext);
  const dir = localStorage.getItem('dir');

  const [p_subs, setPsubs] = useState(null);
  const [p_index, setPindex] = useState(0);
  const [p_title, setPTitle] = useState(null);
  const [p_STR_ID, setPSTRID] = useState(null);
  // const [categories, setCategories] = useState([]);
  const [car, setCar] = useState([]);
  const [loading, setLoading] = useState(true);
  const { s_str_id } = useParams();
  const [stridtree, setSTRIDTree] = useState(s_str_id ? [Number(s_str_id), null] : [null]);

  const [starDepartments, setStartDepartment] = useState([]);
  const [responsive, setResponsive] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    }
  })
  useEffect(() => {

    window.scrollTo(0, 0);
    if (localStorage.getItem('pc_id') && localStorage.getItem('pc_id') !== '' && localStorage.getItem('pc_id') !== '0') {
      // alert(`${gdcURL}/Categories/GetCategoriesByCar?pc_id=${PCID}&lang=${locale}`)
      setLoading(true);
      axios.get(`${gdcURL}/Categories/GetCategoriesByCar?pc_id=${PCID}&lang=${locale}`)
        .then(data => {

          setCategories(JSON.parse(JSON.stringify(data.data.data)));
          setLoading(false);
        })
    } else {
      setLoading(true);
      axios.get(`${gdcURL}/Categories/GetCategories?lang=${locale}`)
        .then(data => {

          setCategories(JSON.parse(JSON.stringify(data.data.data)));
          setLoading(false);
        })
    }

  }, [PCID]);

  useEffect(() => {
    var tempstarDepartments = [];
    treeCategories.map((item) => {
     
      if(item.STR_ID === 100002)
      {
       
        tempstarDepartments.push(item); 
        if(item.children.length > 0){
          item.children.map((child) => {
            tempstarDepartments.push(child);
            

            child.children.map((child2) => {
              tempstarDepartments.push(child2);
              
            });
          });
        }
      }
    })
   
    setStartDepartment(tempstarDepartments);
  }, [treeCategories])
  const RemoveCategorySTRID = () => {
    if (stridtree[0] !== null) {
      setSTRIDTree(stridtree.slice(1));
    }
  }

  const car_info = localStorage.getItem('car_info') ? JSON.parse(localStorage.getItem('car_info')) : null;


  useEffect(() => {
    console.log('car_info', car_info)
  }, [])
  const [showModal, setShowModal] = useState(false);

  const handleShow = (subs, title, STR_ID, index) => {
    setPTitle(title);
    setPsubs(subs);
    setPSTRID(STR_ID);

    setPindex(Math.floor(((index) / 6)));
  }

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState("...");
  const [selectedCategoryID, setSelectedCategoryID] = useState(0);
  const isMobile = useIsMobile();
  const [showAllCategories, setShowAllCategories] = useState(false);

  return (
    <main className="main" style={{marginTop: '-10px', textAlign: dir === 'rtl'? 'right': 'unset'}} dir={dir} >
      <Helmet>
        <title>{t('spare_parts_catelogue')}</title>

      </Helmet>
      {/* <UniCarSearch isHorizontal={true} /> */}
      
      

      <CarInfoBox />

      <div className={ isMobile ? '': 'p-5'} >
        <div className="container" >
          {/* {loading === true && <div><center className='mt-5 pt-5'><Spinner/>Please wait...</center></div>} */}

          
          <center className='mb-4'><h3 style={{fontWeight: 500, textTransform: 'uppercase'}}>{car_info?t('choose_car_parts_category_for', {car: car_info[0].TYPEL}):t('choose_car_parts_category')}</h3></center>
         

          {/* {loading === false && car_info && <p style={{ fontSize: 'x-large', fontAlign: 'center' }}>{t('top_parts_categories_for', { value: car_info[0].TYPEL })}</p>} */}

          {loading === false &&  categories && categories.length > 0 && treeCategories && treeCategories.length > 0 && <div className={`container ${treeCategories.length}`}>
            <Carousel
              swipeable={true}
              draggable={false}
              showDots={false}
              responsive={responsive}

              infinite={false}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              // deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px">
              {
                treeCategories && treeCategories.map((item, indexxx) => {

                  return item.children && <div >
                    <div className={ isMobile ? 'mr-1': 'p-5 mr-4'} style={{ backgroundColor: '#f5f5f5', height: '500px', overflow: 'hidden' }} >
                      <div style={{ minHeight: '100px' }}>
                        <img
                          src={`${gdcURL}/category-images/600x600/${item.STR_ID}`}
                          className="img-fluid"
                          height={100}
                          alt={item.STR_NODE_NAME}
                          onClick={() => handleShow(item.children, item.STR_NODE_NAME, item.STR_ID, indexxx)}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // Prevents infinite loop in case fallback also fails
                            currentTarget.src = "https://placehold.co/100x100/FFFFFF/FFFFFF/png";
                            currentTarget.height = 100
                          }} />
                      </div>
                      <div style={{ position: 'relative' }}>
                        <hr style={{ lineHeight: 0, margin: 0 }} />
                        <div style={{
                          position: 'absolute',
                          left: '50%',
                          top: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          backgroundColor: '#cc000a'
                        }}></div>
                      </div>
                      <center><div className='mt-2' style={{ fontSize: 'large', color: '#000', height: '50px' }}>{item.STR_NODE_NAME}</div></center>
                      <br />
                      <ul style={{ lineHeight: '25px', listStyleType: 'disc', paddingLeft: dir === 'rtl'?'':'20px', paddingRight: dir === 'rtl'?'20px':'' }} dir={dir}>
                        {item.children && item.children.slice(0, 5).map((child) => (
                          <li style={{ color: '#cc000a' }}>
                            <span style={{ color: 'inherit' }}>
                              <NavLink 
                                to={`/Spare-Parts/${slugify(item.STR_NODE_NAME)}-${item.STR_ID}`} 
                                className="nav-link-custom"
                                style={{ color: '#000' }}
                              >
                                {child.STR_NODE_NAME}
                              </NavLink>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                })
              }
            </Carousel></div>
          }
          { isMobile && <section className="feature-boxes-container" style={{ background: '#FFF' }}>
          <h3 style={{fontWeight: 500}}>{car_info?t('please_choose_proper_group', { value: car_info[0].TYPEL }):t('ghayar_original_spare_parts')}</h3>
           <MobSection />
          </section>}
          {/* {loading === false && isMobile === false && car_info && <div className='mt-4'><p style={{ fontSize: 'x-large', fontAlign: 'center' }}>{t('please_choose_proper_group', { value: car_info[0].TYPEL })}</p></div>} */}

          <div className="flex-wrap d-flex justify-content-between align-items-center">
            {
              stridtree[0] !== null ? <h4 onClick={() => RemoveCategorySTRID()}> <i className="fas fa-arrow-left mr-3 my-icon"></i>{" "} </h4> : <div></div>
            }
          </div>
          {loading === false && isMobile === false && categories && categories.length > 0 &&

            <section className="feature-boxes-container" style={{ background: '#FFF' }}>
              <h3 style={{fontWeight: 500}}>{car_info?t('please_choose_proper_group', { value: car_info[0].TYPEL }):t('ghayar_original_spare_parts')}</h3>
              <div className="container" data-animation-name="fadeInUpShorter" >
                <Tabs p_index={p_index} p_subs={p_subs} p_title={p_title} p_STR_ID={p_STR_ID} />

              </div>
            </section>
          }
          {
            car_info && treeCategories && treeCategories.map((item, indexxx) => {
              if(item.STR_ID === 100002){
                  return <h3 style={{fontWeight: 500}}>{t('car_department_catalogue', {car: car_info[0].TYPEL, department: item.STR_NODE_NAME})}</h3>
              }
            })
          }
          <div className='row'>
          
          {
            
                  starDepartments && starDepartments.slice(0, showAllCategories ? starDepartments.length : 4).map((child) => (
                    <div className='col-sm-3' key={child.STR_ID}>
                      <NavLink 
                        to={`/Spare-Parts/${slugify(child.STR_NODE_NAME)}-${child.STR_ID}`} 
                        className="nav-link-custom"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <img
                            alt={child.STR_NODE_NAME}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "https://placehold.co/100x100/FFFFFF/FFFFFF/png";
                            }}
                            src={`${gdcURL}/category-images/600x600/${child.STR_ID}`} 
                            style={{ width: '100px', marginRight: '10px' }} /> 
                        {child.STR_NODE_NAME}
                      </NavLink>
                    </div>
                  ))
          }
          </div>
          {treeCategories && treeCategories.some(item => item.STR_ID === 100002 && item.children.length > 4) && (
            <div className="text-center mt-3">
              <button 
                className="btn btn-link" 
                onClick={() => setShowAllCategories(!showAllCategories)}
                style={{ textDecoration: 'none' }}
              >
                {showAllCategories ? t('show_less') : t('show_more')} 
                <i style={{marginRight: dir === 'rtl'? '10px': 'unset'}} className={`fas fa-chevron-${showAllCategories ? 'up' : 'down'} ml-2`}></i>
              </button>
            </div>
          )}
          <br />
          <RecentViews />
        </div>
      </div>
      <CatelogModal subs={selectedCategories} title={selectedCategoryName} STR_ID={selectedCategoryID} modalStatus={showModal} />
    </main>

  )

}

export default Catelog;