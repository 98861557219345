import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

import slugify from "slugify";
import { NavLink, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../../Context/ConfigContext";
import CatelogModal from "./CatelogModal";
import TabsItems from "./TabsItems";
const Tabs = ({ p_subs, p_title, p_STR_ID, p_index, from }) => {
    const { t } = useTranslation();
    const { categories, treeCategories, gdcURL, PCID } = useContext(ConfigContext);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const scrollToTop = (event) => {
        const element = event.currentTarget; // Get the clicked element
        window.scrollTo({
            top: element.offsetTop,
            behavior: "smooth",  // Enable smooth scrolling
        });
    };

    const handleShow = (subs, title, STR_ID) => {
        setSelectedCategoryName(title);
        setSelectedCategories(subs);
        setSelectedCategoryID(STR_ID)
        setShowModal(true);

    }
    useEffect(() => {
        if (p_subs !== null && p_title !== null && p_STR_ID) {
            handleShowMenu(p_subs, p_title, p_STR_ID, p_index);
            const section = document.getElementById('sec-' + p_STR_ID);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }

    }, [p_STR_ID])
    const handleShowMenu = (subs, title, STR_ID, index) => {
        
        setSelectedCategoryName(title);
        setSelectedCategories(subs);
        setSelectedCategoryID(STR_ID)
        setShowModal(false);
        toggleActiveTab(STR_ID, index);

    }
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCategoryName, setSelectedCategoryName] = useState("...");
    const [selectedCategoryID, setSelectedCategoryID] = useState(0);
    const [activeTab, setActiveTab] = useState("")
    const [activeTabMenu, setActiveTabMenu] = useState("")
    const dir = localStorage.getItem('dir');
    const toggleActiveTab = (tabName, tabitemindex) => {
        if (activeTab === tabName) {
            setActiveTab("")
            setActiveTabMenu("")
        } else {
            setActiveTab(tabName)
            setActiveTabMenu(tabitemindex)
        }

    }
    const divRef = useRef(null);
    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {

            // Clicked outside the div, handle the logic here
            setActiveTab("")
            setActiveTabMenu("")

        }
    };

    useEffect(() => {
        // Add event listener on mount
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    var fromi = 0;
    var itemIndex = 0;
    const [limit, setLimit] = useState(from?true:false);
    return (<>

        <div className="" ref={divRef} >
            {
                treeCategories && treeCategories.length > 0 && Array.from({ length: limit ? 2 : Math.ceil(treeCategories.length / 6) }).map((_, index) => {
                    fromi = fromi + 6;

                    return <div className="row justify-content-center" dir={dir} key={`pp-${Math.random() * 6000}`}>
                        <ul className="nav nav-tabs nav-border-top nav-border-top-primary" role="tablist">
                            {
                                treeCategories && Array.from({ length: 6 }).map((_, ii) => {

                                    if (!treeCategories[itemIndex]) {
                                        itemIndex++;
                                        return <div></div>;

                                    }
                                    var item = treeCategories[itemIndex];
                                    itemIndex++;
                                    // if(treeCategories.children.length === 0){
                                    //     itemIndex++;
                                    //     return <div></div>;
                                    // }
                                    // console.log(item.children.length, item.TotalPrices)
                                    return <div className="text-center sectiontab" style={{ width: '200px', textAlign: 'center', zIndex: 90 }} key={`section-tabs-${item.STR_ID}-${Math.random() * 1000}`} id={`sec-${item.STR_ID}`}>
                                        <li onClick={scrollToTop} style={activeTab === item.STR_ID ? { borderTop: '1px solid #cc000b', borderLeft: '1px solid #cc000b', borderRight: '1px solid #cc000b', borderBottom: '1px solid #FFF', backgroundColor: '#FFFFFF', zIndex: 100 } : {}} role="presentation">
                                            <a onClick={() => handleShowMenu(item.children, item.STR_NODE_NAME, item.STR_ID, index)} data-bs-toggle="tab" href="#nav-border-top-home" role="tab" aria-selected="false" tabIndex={-1}>
                                                <div className="" style={{height: '200px', width: '200px',}}>
                                                    <img
                                                        alt={item.STR_NODE_NAME}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // Prevents infinite loop in case fallback also fails
                                                            currentTarget.src = "https://placehold.co/100x100/FFFFFF/FFFFFF/png";
                                                        }}
                                                        src={`${gdcURL}/category-images/600x600/${item.STR_ID}`} style={{  margin: 'auto', width: '400px' }} />
                                                </div>
                                                <div className="card-text text-center pl-2 pr-2 mb-1" style={{ height: '70px' }}>
                                                    <span style={{ fontFamily: 'Montserrat,Arial,sans-serif', fontSize: '17px', fontWeight: 500, color: '#032f4a' }}>{item.STR_NODE_NAME}  </span>
                                                </div>
                                            </a>
                                        </li>
                                    </div>
                                })
                            }
                        </ul>
                        <div className="w-100" style={activeTabMenu === index ? { marginTop: '-5px', border: '1px solid #cc000b', zIndex: 10, backgroundColor: '#FFF', padding: '20px' } : { display: 'none' }}>
                            {/* <div onClick={() => {setActiveTabMenu(''); setActiveTab('')}} style={{float: 'right'}}><i className="fas fa-times"></i></div> */}
                            <TabsItems subs={selectedCategories} title={selectedCategoryName} STR_ID={selectedCategoryID} modalStatus={showModal} />
                        </div>
                    </div>
                })
            }

        </div>
        <center className="mt-1">
            {
                // from && <button className="btn btn-outline-primary" onClick={() => window.location='/Spare-Parts/Catelog'} >{t('more_car_parts')} <i className="fas fa-arrow-right ms-2"></i></button>
                // :limit ?
                //     <button className="btn btn-outline-primary" onClick={() => setLimit(false)} >{t('show_more')}</button>
                //     : <button className="btn btn-outline-primary" onClick={() => setLimit(true)} >{t('show_less')}</button>
            }

        </center>
        <br /><br />

    </>)

}

export default Tabs