import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UniCarSearch from "./UniCarSearch";
import { useContext, useEffect } from "react";
import { ConfigContext } from "../../Context/ConfigContext";

const { useState } = require("react");

const CarInfoBox = () => {
    const car_info = localStorage.getItem('car_info') ? JSON.parse(localStorage.getItem('car_info')) : null;
    useEffect(() => {
      console.log("car_info", car_info)
    }, [])
    const [showCarModal, setShowCarModal] = useState(false);
    const [showCarDetailsModal, setShowCarDetailsModal] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { gdcURL, PCID, setCategories, categories, treeCategories, TillYear } = useContext(ConfigContext);
    const dir = localStorage.getItem('dir');

    if(window.innerWidth <= 768){
     
      return (
        <div className="parts p-2" style={{ borderTop: '1px solid #CCC', borderBottom: '1px solid #CCC', backgroundColor: '#FFF' }}>
        <div className="container">
          {
            !car_info && <div className="row ml-2 mr-2">
            <div className="col-8"><h4 className='mt-2' style={{fontWeight: 500}}>{t('spare_parts_catelogue')}</h4></div>
            <div className="col-4 d-flex align-items-center justify-content-end">
            <button 
                  className='btn btn-primary btn-sm' 
                  data-bs-toggle="modal" 
                  data-bs-target="#carSelectionModal" 
                  style={{direction: dir==='rtl'?'ltr': ''}}
                  onClick={() => setShowCarModal(true)} ><i className="fas fa-cog ml-2"></i>  {" "}
                  {car_info?t('change_a_car'):t('choose_a_car')} 
                </button>
            </div>
          </div>
          }
          { car_info && 
          <div className="row">
            <div className="col-4 d-flex align-items-center">
              {car_info && (
                <img 
                  src={`${gdcURL}/car-ms-images/${localStorage.getItem('ms_id')}`} 
                  alt={car_info && car_info[0]? `${car_info[0].TYPEL} ${car_info[0].PC_ENG_CODES} ${new Date(car_info[0].PCS_CI_FROM).toLocaleString('default', {  year: 'numeric' })} - ${car_info[0].PCS_CI_TO ? new Date(car_info[0].PCS_CI_TO).toLocaleString('default', {  year: 'numeric' }) : TillYear}`:t('spare_parts_catelogue')}
                  style={{ 
                    width: '130px',  
                    marginRight: '15px', 
                    objectFit: 'contain',
                    WebkitTransform: 'scaleX(-1)',
                    transform: 'scaleX(-1)'
                  }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "https://placehold.co/80x80/FFFFFF/FFFFFF/png";
                  }}
                />
              )}
            </div>
            <div className="col-8">
            <h3 className='mt-2' style={{fontWeight: 500}}>
              {car_info && car_info[0] ? <div style={{width: '100%', textAlign: dir==='rtl'?'right': 'left'}}>
              
              <span >{car_info[0].TYPEL} {car_info[0].PC_ENG_CODES}</span> 
              <div style={{width: '100%', color: '#7e7c84',textAlign: dir==='rtl'?'right': 'left'}}><small >{t('engine')}: {car_info[0].PC_ENG_CODES} {t('year')}: {`${new Date(car_info[0].PCS_CI_FROM).toLocaleString('default', {  year: 'numeric' })} - ${car_info[0].PCS_CI_TO ? new Date(car_info[0].PCS_CI_TO).toLocaleString('default', {  year: 'numeric' }) : TillYear}`} {t('fuel_type')}: {car_info[0].PC_FUEL_TYPE}</small></div> 
             
              </div>: '' 
              }
              </h3>
            </div>
          </div> 
          }
          { car_info && 
          <div className="col-md-12 d-flex justify-content-start">
            
            {car_info && <button 
                className='btn btn-outline-primary me-3' 
                data-bs-toggle="modal" 
                data-bs-target="#carDetailsModal" 
                style={{direction: dir==='rtl'?'ltr': ''}}
                onClick={() => setShowCarDetailsModal(true)}>
                <i className="fas fa-info-circle mr-2"></i> {t("car_details")}
              </button>}
              <button 
                className='btn btn-primary ml-4' 
                data-bs-toggle="modal" 
                data-bs-target="#carSelectionModal" 
                style={{direction: dir==='rtl'?'ltr': ''}}
                onClick={() => setShowCarModal(true)} ><i className="fas fa-cog ml-2"></i>  {" "}
                {car_info?t('change_a_car'):t('choose_a_car')} 
              </button>
          </div>
          }
        </div>

        <div className={`modal fade ${showCarModal ? 'show' : ''}`} 
      style={{ display: showCarModal ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)', zIndex:100000 }}
      id="carSelectionModal" tabIndex="-1" aria-labelledby="carSelectionModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" style={{width: '400px'}} >
          <div className="modal-content" style={{width: 'auto'}}>
            <i 
              type="button" 
              className="fas fa-times position-absolute top-0 end-0 m-3" 
              data-bs-dismiss="modal" 
              aria-label="Close"
              style={{ zIndex: 1, top: '-40px', right: 0, color: '#b8b3b3', fontSize: '25px' }}
              onClick={() => setShowCarModal(false)}
            ></i>
            <div className="modal-body" style={{padding: 0}}>
              <UniCarSearch />
            </div>
          </div>
        </div>
      </div>

      <div className={`modal fade ${showCarDetailsModal ? 'show' : ''}`} 
      style={{ display: showCarDetailsModal ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)', zIndex:100000 }}
      id="carDetailsModal" tabIndex="-1" aria-labelledby="carDetailsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" style={{width: '500px'}}>
          <div className="modal-content">
            <i 
              type="button" 
              className="fas fa-times position-absolute top-0 end-0 m-3" 
              data-bs-dismiss="modal" 
              aria-label="Close"
              style={{ zIndex: 1, top: '-30px', right: '-30px', color: '#b8b3b3', fontSize: '25px' }}
              onClick={() => setShowCarDetailsModal(false)}
            ></i>
            <div className="modal-body " style={{textAlign: 'center'}}>
            {car_info && car_info[0] && <h4>{car_info[0].TYPEL}</h4>}
              {car_info && (
                <div>
                  <table className='table table-bordered' style={{textAlign: dir==='rtl'?'right': 'left'}}>
                    <tr><td>{t('type')}:</td><td>{parseFloat(car_info[0].PCS_CAPACITY_LT).toFixed(1)}</td></tr>
                    <tr><td>{t('tech_capacity')}:</td><td>{car_info[0].PCS_CAPACITY_TECH?parseFloat(car_info[0].PCS_CAPACITY_TECH).toFixed(1):'...'}</td></tr>
                    <tr><td>{t('manuf_year')}:</td><td>{new Date(car_info[0].PCS_CI_FROM).toLocaleString('default', { month: '2-digit', year: 'numeric' })} - {car_info[0].PCS_CI_TO ? new Date(car_info[0].PCS_CI_TO).toLocaleString('default', { month: '2-digit', year: 'numeric' }) : '...'}</td></tr>
                    
                    <tr><td>{t('car_body_type')}:</td><td>{car_info[0].PC_BODY_TYPE??'...'}</td></tr>
                    <tr><td>{t('type_of_drive')}:</td><td>{car_info[0].PC_DRIVE_TYPE??'...'}</td></tr>
                    <tr><td>{t('power_kw')}:</td><td>{car_info[0].PCS_POWER_KW??'...'}</td></tr>
                    <tr><td>{t('power_hp')}:</td><td>{car_info[0].PCS_POWER_PS??'...'}</td></tr>
                    <tr><td>{t('number_cylinder')}:</td><td>{car_info[0].PCS_NUMBER_OF_CYLINDERS}</td></tr>
                    <tr><td>{t('engine_type')}:</td><td>{car_info[0].PC_ENGINE_TYPE}</td></tr>
                    <tr><td>{t('engine_code')}:</td><td>{car_info[0].PC_ENG_CODES}</td></tr>
                    <tr><td>{t('fuel_type_label')}:</td><td>{car_info[0].PC_FUEL_TYPE}</td></tr>
                    <tr><td>{t('fuel_handling')}:</td><td>{car_info[0].PC_FUEL_MIXTURE??'...'}</td></tr>
                    <tr><td>{t('braking_system')}:</td><td>{car_info[0].PC_BRAKE_SYSTEM??'...'}</td></tr>

                    
                  </table>
                  
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      </div>
    )
    }
    return (
        <div className="parts p-2 text-right" style={{ borderTop: '1px solid #CCC', borderBottom: '1px solid #CCC', backgroundColor: '#FFF' }}>
        <div className="container d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            {car_info && (
              <img 
                src={`${gdcURL}/car-ms-images/${localStorage.getItem('ms_id')}`} 
                alt={car_info && car_info[0]? `${car_info[0].TYPEL} ${car_info[0].PC_ENG_CODES} ${new Date(car_info[0].PCS_CI_FROM).toLocaleString('default', {  year: 'numeric' })} - ${car_info[0].PCS_CI_TO ? new Date(car_info[0].PCS_CI_TO).toLocaleString('default', {  year: 'numeric' }) : TillYear}`:t('spare_parts_catelogue')}
                style={{ 
                  width: '130px',  
                  marginRight: '15px', 
                  objectFit: 'contain',
                  WebkitTransform: 'scaleX(-1)',
                  transform: 'scaleX(-1)'
                }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "https://placehold.co/80x80/FFFFFF/FFFFFF/png";
                }}
              />
            )}
            <h3 className='mt-2' style={{fontWeight: 500}}>
              {car_info && car_info[0] ? <div style={{width: '100%', textAlign: dir==='rtl'?'right': 'left'}}>
              
              <span >{car_info[0].TYPEL} {car_info[0].PC_ENG_CODES}</span> 
              <div style={{width: '100%', color: '#7e7c84',textAlign: dir==='rtl'?'right': 'left'}}><small >{t('engine')}: {car_info[0].PC_ENG_CODES} {t('year')}: {`${new Date(car_info[0].PCS_CI_FROM).toLocaleString('default', {  year: 'numeric' })} - ${car_info[0].PCS_CI_TO ? new Date(car_info[0].PCS_CI_TO).toLocaleString('default', {  year: 'numeric' }) : TillYear}`} {t('fuel_type')}: {car_info[0].PC_FUEL_TYPE}</small></div> 
             
              </div>: t('spare_parts_catelogue') 
              }
              </h3>
          </div>
          <div>
          {car_info && <button 
              className='btn btn-outline-primary mr-3' 
              data-bs-toggle="modal" 
              data-bs-target="#carDetailsModal" 
              style={{direction: dir==='rtl'?'ltr': ''}}
              onClick={() => setShowCarDetailsModal(true)}>
              <i className="fas fa-info-circle mr-2"></i> {t("car_details")}
            </button>}
            <button 
              className='btn btn-primary mr-5' 
              data-bs-toggle="modal" 
              data-bs-target="#carSelectionModal" 
              style={{direction: dir==='rtl'?'ltr': ''}}
              onClick={() => setShowCarModal(true)} ><i className="fas fa-cog ml-2"></i>  {" "}
              {car_info?t('change_a_car'):t('choose_a_car')} 
              
              
              
             
            </button>
          </div>
        </div>

        <div className={`modal fade ${showCarModal ? 'show' : ''}`} 
      style={{ display: showCarModal ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)', zIndex:100000 }}
      id="carSelectionModal" tabIndex="-1" aria-labelledby="carSelectionModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" style={{width: '400px'}} >
          <div className="modal-content" style={{width: 'auto'}}>
            <i 
              type="button" 
              className="fas fa-times position-absolute top-0 end-0 m-3" 
              data-bs-dismiss="modal" 
              aria-label="Close"
              style={{ zIndex: 1, top: '-30px', right: '-30px', color: '#b8b3b3', fontSize: '25px' }}
              onClick={() => setShowCarModal(false)}
            ></i>
            <div className="modal-body" style={{padding: 0}}>
              <UniCarSearch />
            </div>
          </div>
        </div>
      </div>

      <div className={`modal fade ${showCarDetailsModal ? 'show' : ''}`} 
      style={{ display: showCarDetailsModal ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.5)', zIndex:100000 }}
      id="carDetailsModal" tabIndex="-1" aria-labelledby="carDetailsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" style={{width: '500px'}}>
          <div className="modal-content">
            <i 
              type="button" 
              className="fas fa-times position-absolute top-0 end-0 m-3" 
              data-bs-dismiss="modal" 
              aria-label="Close"
              style={{ zIndex: 1, top: '-30px', right: '-30px', color: '#b8b3b3', fontSize: '25px' }}
              onClick={() => setShowCarDetailsModal(false)}
            ></i>
            <div className="modal-body " style={{textAlign: 'center'}}>
            {car_info && car_info[0] && <h4>{car_info[0].TYPEL}</h4>}
              {car_info && (
                <div>
                  <table className='table table-bordered' style={{textAlign: dir==='rtl'?'right': 'left'}}>
                    <tr><td>{t('type')}:</td><td>{parseFloat(car_info[0].PCS_CAPACITY_LT).toFixed(1)}</td></tr>
                    <tr><td>{t('tech_capacity')}:</td><td>{car_info[0].PCS_CAPACITY_TECH?parseFloat(car_info[0].PCS_CAPACITY_TECH).toFixed(1):'...'}</td></tr>
                    <tr><td>{t('manuf_year')}:</td><td>{new Date(car_info[0].PCS_CI_FROM).toLocaleString('default', { month: '2-digit', year: 'numeric' })} - {car_info[0].PCS_CI_TO ? new Date(car_info[0].PCS_CI_TO).toLocaleString('default', { month: '2-digit', year: 'numeric' }) : '...'}</td></tr>
                    
                    <tr><td>{t('car_body_type')}:</td><td>{car_info[0].PC_BODY_TYPE??'...'}</td></tr>
                    <tr><td>{t('type_of_drive')}:</td><td>{car_info[0].PC_DRIVE_TYPE??'...'}</td></tr>
                    <tr><td>{t('power_kw')}:</td><td>{car_info[0].PCS_POWER_KW??'...'}</td></tr>
                    <tr><td>{t('power_hp')}:</td><td>{car_info[0].PCS_POWER_PS??'...'}</td></tr>
                    <tr><td>{t('number_cylinder')}:</td><td>{car_info[0].PCS_NUMBER_OF_CYLINDERS}</td></tr>
                    <tr><td>{t('engine_type')}:</td><td>{car_info[0].PC_ENGINE_TYPE}</td></tr>
                    <tr><td>{t('engine_code')}:</td><td>{car_info[0].PC_ENG_CODES}</td></tr>
                    <tr><td>{t('fuel_type_label')}:</td><td>{car_info[0].PC_FUEL_TYPE}</td></tr>
                    <tr><td>{t('fuel_handling')}:</td><td>{car_info[0].PC_FUEL_MIXTURE??'...'}</td></tr>
                    <tr><td>{t('braking_system')}:</td><td>{car_info[0].PC_BRAKE_SYSTEM??'...'}</td></tr>

                    
                  </table>
                  
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      </div>
    )
}

export default CarInfoBox;