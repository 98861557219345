import React, { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ConfigContext } from "../../../Context/ConfigContext";
import { useTranslation } from 'react-i18next';

import {
    CartProcess,
    DeleteCartItem,
    GetCarts,
    QtyUpdate, ClearCart
} from "../../../Helper/MyCart";
import { Spinner } from "../../../Components/Shimmers";
import { AddToCartNotifier } from "../../../Components/CartComponents";
import MobCartItem from "../MobCartItem";

function MobBasketConfirm() {

    const { apiURL, gdcURL, UpdateCartList, lang, token, paymentMethod, setPaymentMethod } = useContext(ConfigContext);
    const [cartItems, setCartItems] = useState([]);
    const [coupon, setCoupon] = useState(localStorage.getItem("coupon") || "");
    const [couponData, setCouponData] = useState([]);
    const [cartLoading, setCartLoading] = useState(true);
    const { t } = useTranslation();
    const [customersAddresses, setCustomersAddresses] = useState([]);
    const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if(!paymentMethod){
            window.location = '/basket/payment';
        }
    })
    useEffect(() => {
        setCartLoading(true);
        fetchCartDataFromLocalStorage();
        GetCoupons();
    }, []);
    const handlePlaceOrder = async () => {
        if (placeOrderLoading) {
          return false;
        }
        const carts = GetCarts();
        const requestData = {
          lang: lang,
          customer_id: Number(localStorage.getItem('customer_id')),
          address_id: selectedAddress?selectedAddress.address_id: 0,
          carts: JSON.stringify(carts),
          coupon: coupon,
          currency: localStorage.getItem('currency'),
          address_id:  customersAddresses[0].address_id,
          payment_method: paymentMethod,
          source_info: {
            ip: "192.168.5.100",
            device: "MAC",
            country: "India",
          },
        };
        
        try {
          setPlaceOrderLoading(true);
          const response = await axios.post(`${apiURL}/Carts/Checkout`, requestData, {
            headers: {
              'token': token,
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
          
          const data = response.data;
          
          if(data.success === false){
            setPlaceOrderLoading(false);
          }
          if(data.success === true){
    
            ClearCart();
            UpdateCartList(GetCarts());
            if(paymentMethod === 'cod' || paymentMethod === 'bank'){
               // Navigate within the app
               navigate(`/OrderInformation/${data.secret_order_id}`, { replace: true });
            } else {
              // Redirect to external payment URL
              window.history.replaceState(null, '', '/');
              window.location.href = `https://pay.ghayar.com/pay?order_id=${data.secret_order_id}&origin=ghayar.com&order_uid=${data.secret_order_id}`;
            }
    
    
          }
        } catch (error) {
          setPlaceOrderLoading(false);
          // console.error("Error placing order:", error);
        }
      };
    const fetchCartDataFromLocalStorage = async () => {
        try {
            const carts = GetCarts();
            if (carts?.length > 0) {
                const response = await axios.get(`${gdcURL}/Orders/GetCart`, {
                    params: {
                        currency: localStorage.getItem('currency') ?? 'aed',
                        lang,
                        customer_id: localStorage.getItem("customer_id") || 0,
                        carts: JSON.stringify(carts),
                        coupon,
                    },
                });
                setCartItems(response.data.data || []);
            }
            setCartLoading(false);
        } catch (err) {
            console.error("Error fetching cart data:", err);
            setCartLoading(false);
        }
    };

    const GetCoupons = async () => {
        try {
            const response = await axios.get(`${apiURL}/Home/GetCoupons?lang=en`);
            setCouponData(response.data.data || []);
        } catch (err) {
            console.error("Error fetching coupons:", err);
        }
    };

    const RemoveCoupon = () => {
        localStorage.removeItem("coupon");
        setCoupon("");
    };

    const ApplyCoupon = (couponCode, e) => {
        e.preventDefault();
        setCoupon(couponCode);
    };

    useEffect(() => {
        localStorage.setItem("coupon", coupon);
        fetchCartDataFromLocalStorage();
    }, [coupon]);

    const Cart = cartItems?.items?.length > 0;
    const dir = localStorage.getItem('dir');
    const [selectedAddress, setSelectedAddress] = useState([]);
    useEffect(() => {
        getCustomerAddresses();
    }, []);

    const getCustomerAddresses = async () => {
        try {
            const customer_id = localStorage.getItem("customer_id");
            const headers = { token: token, Accept: "application/json", "Content-Type": "application/json", };

            const response = await axios.get(
                `${apiURL}/Account/GetAddresses?customer_id=${customer_id}`,
                { headers }
            );

            const data = response.data;

            if (data.success) {
                setCustomersAddresses(data.data);

                setSelectedAddress(data.data.find((item) => item.selected === 1));
                fetchCartDataFromLocalStorage();
            } else {
                localStorage.setItem('token', '');
                localStorage.setItem('customer_id', '');
                localStorage.setItem('email_address', '');
                localStorage.setItem('first_name', '');
                localStorage.setItem('mobile_number', '');
                navigate('/Signup')
                // console.log("Error While Fetching Customer Addresses", data.message);
            }
        } catch (error) {
            // console.log("Oops Something Went Wrong", error);
        }
    };

    return (
        <main className="main" style={{textAlign: dir ==='rtl'?'right': ''}} dir={dir}>
            <ToastContainer position="bottom-center" autoClose={1000} theme="dark" />
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 mt-2">
                        <div style={{ display: localStorage.getItem('customer_id') === '' ? 'none' : '' }}>
                            <p><h2 className="step-title" style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }}>{t('shipping_address')}</h2></p>
                            <div className="cart-summary p-2" style={{ borderRadius: "2%" }}>
                                {customersAddresses.length > 0 ? (
                                    <div className="container">

                                        <div className="row">
                                            <div className="col-md-6 mt-2"  style={{textAlign: dir ==='rtl'?'right': 'left', paddingRight: dir ==='rtl'?'50px':''}}>
                                                <span className="product-description__item-title ml-4" style={{ fontSize: '16px' }}>{t("billing_address_and_delivery_address")}</span>
                                                <div className="ml-4" key={selectedAddress.address_id} style={{ lineHeight: "30px" }} >
                                                    <div className="h5 mb-0"> {selectedAddress.first_name} {selectedAddress.last_name} </div>
                                                    <div className="mt-0">
                                                        {selectedAddress.complete_address} <br />
                                                        {selectedAddress.city}, {selectedAddress.state}<br /> 
                                                        {selectedAddress.pincode}, {selectedAddress.country}
                                                    </div>
                                                    {selectedAddress.mobile_number_2 && selectedAddress.mobile_number_2 > 0 ?
                                                        <div>
                                                            <p> {selectedAddress.mobile_number_2}{" "}
                                                                {/* <span className="text-primary"> {"Verify now"} </span>  */}
                                                            </p>
                                                        </div> : <></>}
                                                        <span  onClick={() => window.location = `/basket/address?backUrl=${window.location.href}`} className="text-primary link-blue"> {t('change')} </span>
                                                    
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="card-body" style={{ minHeight: 'unset', textAlign: dir ==='rtl'?'right': 'left'}}>
                                                    <span className="product-description__item-title ml-4" style={{ fontSize: '16px', textAlign: 'left' }}>{t("payment_method")}</span>
                                                    {
                                                        paymentMethod === 'online' && <div className="col-lg-12" style={{textAlign: dir ==='rtl'?'right': 'left'}}>
                                                            <span className="ml-2" style={{fontSize: '13px', fontWeight: 500}}>{t('online_payments_recommended')}</span>
                                                            <div className="col-lg-12">
                                                                <img src="/assets/images/payment/visa.svg" alt="Visa" className="payment-icon" style={{width: '40px', display: 'inline-block', marginLeft: '-5px'}} />
                                                                <img src="/assets/images/payment/mc.svg" alt="Mastercard" className="payment-icon" style={{width: '40px', display: 'inline-block'}} />
                                                                <img src="/assets/images/payment/ae.svg" alt="American Express" className="payment-icon" style={{width: '40px', display: 'inline-block'}} />
                                                            </div>

                                                        </div>

                                                    }

                                                    {
                                                        paymentMethod === 'bank' && <div className="col-lg-12" style={{textAlign: dir ==='rtl'?'right': 'left'}}>
                                                            <span className="ml-2" style={{fontSize: '13px', fontWeight: 500}}>{t('bank_transfer')}</span>
                                                            <div className="col-lg-12"  >
                                                                <img src="/assets/images/payment/bank_transfer.svg" alt="Bank Transfer" className="payment-icon" style={{width: '60px', display: 'inline-block', marginLeft: '-5px'}} />
                                                                
                                                            </div>

                                                        </div>

                                                    }

                                                    {
                                                        paymentMethod === 'cod' && <div className="col-lg-12" style={{textAlign: dir ==='rtl'?'right': 'left'}}>
                                                            <span className="ml-2" style={{fontSize: '13px', fontWeight: 500}}>{t('cash_on_delivery')}</span>
                                                            <div className="col-lg-12">
                                                                <img src="/assets/images/payment/cod.svg" alt="Bank Transfer" className="payment-icon" style={{width: '60px', marginTop: '-10px', display: 'inline-block', marginLeft: '-5px'}} />
                                                                
                                                            </div>

                                                        </div>

                                                    }
                                                    <div className="col-lg-12 ml-3 mt-1" style={{textAlign: dir ==='rtl'?'right': 'left'}}>
                                                    <span  onClick={() => window.location = `/basket/payment`} className="text-primary link-blue" style={{marginLeft: '-5px'}}> {t('change')} </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                ) : (
                                    <div className="d-flex justify-content-center" style={{ height: '100px', padding: '40px' }}>
                                        <span  onClick={() => window.location = `/basket/address?backUrl=${window.location.href}`} className="text-primary" style={{cursor: 'pointer'}}> {t('add_new_address')} </span>
                                        

                                    </div>
                                )}
                            </div>
                        </div>

                        {cartLoading ? <center><Spinner /></center> :
                            Cart ? (
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="cart-table-container">
                                            {cartItems.items.map((item, index) => (
                                                <MobCartItem key={item.item_id} item={item} index={index} fetchCartDataFromLocalStorage={fetchCartDataFromLocalStorage} />
                                            ))}
                                        </div>
                                    </div>


                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="cart-table-container cart-summary">
                                            <div className="d-flex justify-content-center">
                                                <h5>{t("your_cart_is_empty")}</h5>
                                            </div>
                                            <div className="checkout-methods d-flex justify-content-center">
                                                <NavLink to="/" className="btn btn-dark btn-lg px-5">
                                                    {t("shop_now")}
                                                    <i className="fa fa-arrow-right" />
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                    <div className="col-lg-4 mt-2">
                        <p><h2 className="step-title" dir={dir} style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }}>{t('order_summary')}</h2></p>
                        <div className="cart-summary p-3 mb-1" style={{ borderRadius: "3%", textAlign: dir === 'rtl' ? 'right' : 'left', direction: dir }} >
                            <h5 style={{ lineHeight: "1.5 rem", marginBottom: "0px", marginLeft: "10px", }} > {t("order_details")} </h5>
                            <table className="table table-totals mb-0">
                                {Cart &&
                                    cartItems.items.map((item, index) => (
                                        <tr key={item.item_id}>
                                            <td className="custom-light">
                                                {index + 1}. {item.item_title} x {item.item_qty}
                                            </td>
                                            <td className="float-right">
                                                {item.total_display_price}
                                            </td>
                                        </tr>
                                    ))}
                            </table>
                            {Cart && (
                                <>
                                    <table className="table table-totals mb-0">
                                        <tbody>
                                            <tr style={{ border: "none", marginBottom: "0px" }}>
                                                <td style={{ textAlign: dir === 'rtl' ? 'right' : 'left', }}>{t("sub_total")}</td>
                                                <td style={{ textAlign: dir !== 'rtl' ? 'right' : 'left', }} className="custom-dark">
                                                    <span>{cartItems.sub_total_display}</span>
                                                </td>
                                            </tr>
                                            {
                                                cartItems.free_shipping ?
                                                    <tr style={{ border: "none", marginBottom: "0px" }}>
                                                        <td style={{ textAlign: dir === 'rtl' ? 'right' : 'left', }}>{t("shipping")}</td>
                                                        <td style={{ textAlign: dir !== 'rtl' ? 'right' : 'left', }} >
                                                            <div className="alert-success" style={{ padding: '5px', width: '100%', textAlign: 'center', border: '2px dotted', }}>{t('free_shipping_all_over_nation')}</div>
                                                        </td>
                                                    </tr>
                                                    :
                                                    <tr style={{ border: "none", marginBottom: "0px" }}>
                                                        <td style={{ textAlign: dir === 'rtl' ? 'right' : 'left', }}>{t("shipping")}</td>
                                                        <td style={{ textAlign: dir !== 'rtl' ? 'right' : 'left', }} >
                                                            <span>{cartItems.display_shipping_cost}</span>
                                                        </td>
                                                    </tr>
                                            }
                                            {paymentMethod === 'cod' && cartItems.cod_applicable &&
                                                <tr style={{ border: "none", marginBottom: "0px" }}>
                                                    <td style={{ textAlign: dir === 'rtl' ? 'right' : 'left', }}>{t("cod")}</td>
                                                    <td style={{ textAlign: dir !== 'rtl' ? 'right' : 'left', }} >
                                                        <span>{cartItems.cod_charges > 0 ? cartItems.display_cod_charges : <div className="alert-success" style={{ padding: '5px', width: '100%', textAlign: 'center', border: '2px dotted', }}>{t('cash_on_delivery_free')}</div>}</span>
                                                    </td>
                                                </tr>
                                            }

                                            {localStorage.getItem("coupon")  && cartItems.total_saving !== 0 && 
                                                    <tr>
                                                        <td> 
                                                            {t('total_saving')} <span className="text-success alert-success" style={{ padding: "1px 5px", border: "1px dashed", }} > {localStorage.getItem("coupon")} </span>
                                                        </td>
                                                        <td className="custom-dark">
                                                            <span className="text-success">
                                                                - {cartItems.total_saving_display}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>{t("grand_total")}</td>
                                                <td className="custom-dark">
                                                    <span>{cartItems.grand_total_display}</span>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </>
                            )}
                        </div>
                        <div className="checkout-methods">
                            {
                                localStorage.getItem('customer_id') === '' ?
                                    <span className="alert alert-danger">Please login or create account before place order</span>
                                    : <div></div>
                            }
                            {
                                paymentMethod &&
                                <button
                                    type="button"
                                    style={{ borderRadius: "3%" }}
                                    to={"/Checkout"}
                                    disabled={localStorage.getItem('customer_id') === '' ? true : false}
                                    className={placeOrderLoading === true ? "btn btn-block btn-default" : "btn btn-block btn-primary"}
                                    onClick={handlePlaceOrder}
                                >
                                    {placeOrderLoading === true ? "Please wait..." : t("place_order")}

                                </button>
                            }

                            {
                                !paymentMethod &&
                                <button
                                    type="button"
                                    style={{ borderRadius: "3%" }}
                                    to={"/Checkout"}
                                    disabled={true}
                                    className={placeOrderLoading === true ? "btn btn-block btn-default" : "btn btn-block btn-primary"}

                                >
                                    {placeOrderLoading === true ? "Please wait..." : t("place_order")}

                                </button>
                            }

                        </div>
                        <div className="text-dark" dir={dir} style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }}>
                            <div className="mt-2">
                                {(" ")}<i className="fas fa-solid fa-lock mr-1"></i>{(" ")}
                                <strong className="" style={{ color: 'grey' }}>{t("security_and_privacy")}</strong>
                            </div>
                            <div className="mt-1">
                                {t("checkout_safe")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-6" />
        </main>
    );
}

export default MobBasketConfirm;
