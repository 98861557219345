import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ConfigContext } from "../../Context/ConfigContext";
import { useTranslation } from 'react-i18next';

import {
    CartProcess,
    DeleteCartItem,
    GetCarts,
    QtyUpdate
} from "../../Helper/MyCart";

import { Spinner } from "../../Components/Shimmers";
import { AddToCartNotifier } from "../../Components/CartComponents";
export default function CartItem({item, index, fetchCartDataFromLocalStorage}) {
    const [shimmerCarItem, setShimmerCarItem] = useState('cart-item-');
    const { t, i18n } = useTranslation();
    const dir = localStorage.getItem('dir');
    const MySwal = withReactContent(Swal);
    const { apiURL, gdcURL, UpdateCartList, ChangeCartStatus, CartStatus, lang, setCartItemsQty, setStateGlobleCart } = useContext(ConfigContext);


    const handleDeleteCartItem = async (index) => {

        const result = await MySwal.fire({
            title: "Remove Item",
            text: "Are you sure you want to remove this item?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes Delete",
            cancelButtonText: "Cancel!",
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            DeleteCartItem(index);
            UpdateCartList(GetCarts());
            fetchCartDataFromLocalStorage();
            setShimmerCarItem('cart-item-');
            toast.success("Item Removed successfully!", {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        } else {
            setShimmerCarItem('cart-item-');
            return;
        }
    };

    const handleQuantityChange = async (index, qty) => {
        // alert(max_qty)

        await QtyUpdate(index, qty);
        setCartItemsQty();
        fetchCartDataFromLocalStorage();
        setShimmerCarItem('cart-item-');

    };

    
    return <div className="card" dir={dir} style={{textAlign: dir==='rtl'?'right': ''}}>
        <div className="row">
            <div className="col-sm-2">
                <figure className="product-image-container ml-3 mt-1">
                    <NavLink to={item.item_url} className="product-image" >
                        <img width={70} src={item.item_img_url} alt={item.item_title} onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = "https://ghayar.ae/assets/images/prodefault.jpg";
                        }} />
                    </NavLink>

                </figure>
            </div>
            <div className="col-sm-5" style={{textAlign: dir==='rtl'?'right': ''}}>
                <div className="mt-2" style={{
                    fontFamily: 'Montserrat, Arial, sans-serif',
                    fontSize: '15px !important',
                    fontWeight: '500 !important',
                    color: '#000', width: '100%', textAlign:  dir==='rtl'?'right': 'left',
                    lineHeight: '16px !important'
                }}>{item.item_title}</div>
                <div
                    style={{
                        fontFamily: 'Montserrat, Arial, sans-serif',
                        fontSize: '15px !important',
                        fontWeight: '500 !important',
                        color: '#000', width: '100%', textAlign: dir==='rtl'?'right': 'left',
                        lineHeight: '16px !important'
                    }}><small>{t('part_number')}: {item.item_number}</small></div>
                <NavLink to={item.item_url} style={{float: dir === 'rtl' ? 'right' : 'left', display: 'block'}}>{t('details')}</NavLink>
            </div>
            <div className="col-sm-2">
                <div className="product-single-qty mt-2">
                    <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                        <span className="input-group-btn input-group-prepend">
                            <button
                                className="btn btn-outline btn-down-icon bootstrap-touchspin-down"
                                type="button"
                                // disabled={Number(item.item_qty) > 1?false: true}
                                onClick={() => {

                                    if (Number(item.item_qty) === 1) {
                                        handleDeleteCartItem(index);
                                    } else {
                                        handleQuantityChange(index, (Number(item.item_qty) - 1))
                                    }
                                }
                                }
                            ></button>
                        </span>
                        <input className="horizontal-quantity form-control" type="text" value={item.item_qty} readOnly />
                        <span className="input-group-btn input-group-prepend">
                            <button
                                className="btn btn-outline btn-up-icon bootstrap-touchspin-up btnPlus"
                                type="button"
                                onClick={() => {
                                    if (Number(item.max_qty) >= (Number(item.item_qty) + 1)) {
                                        handleQuantityChange(index, (Number(item.item_qty) + 1))
                                    } else {
                                        toast.success(<AddToCartNotifier item_name={t('sorry_we_have_only', { value: item.max_qty })} />, {})
                                    }
                                }
                                } >
                                +
                            </button>
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-sm-2">
                <div className="mt-2" style={{
                    fontFamily: 'Montserrat, Arial, sans-serif',
                    fontSize: '14px ',
                    fontWeight: '501 ',
                    lineHeight: '20px ',
                    textAlign: dir==='rtl'?'right': 'left',
                    color: '#000'
                }}>{item.total_display_price}<br /><small>{t('incl_vat_short')}</small></div>
            </div>
            <div className="col-sm-1 pt-4">
                <NavLink onClick={() => { setShimmerCarItem('cart-item-' + index); handleDeleteCartItem(index); }} className="icon-cancel" style={{ color: '#999', marginTop: '50px' }} title="Remove Product" />
            </div>
        </div>
    </div>
}