import React, { useContext, useState, useEffect, useRef } from "react";
import "../sidebar.css";

import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../Context/ConfigContext";
import slugify from "slugify";

const Sidebar = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dir = localStorage.getItem("dir");
    const { categories, treeCategories, CarStatus, gdcURL, setCategories, CartItemsQty, CartList, isLoggedIn, lang, setLang, sideBarStatus, setSideBarStatus } = useContext(ConfigContext);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [hoverTimeout, setHoverTimeout] = useState(null);
  
    const layerFirst = {
        top: 0,
        width: '350px',
        height: '100vh',
        backgroundColor: '#f9fafb',
        position: 'fixed',
        zIndex: 1000000000000,
        overlay: 'hidden',
        display: sideBarStatus?'block': 'none',
       
        scrollbarWidth: 'thin', // For Firefox
        scrollbarColor: '#BBB #f1f1f1' 
    };
    // useEffect(() => {
    //     alert(sidebar)
    // }, [sidebar])
    const layerSecond = {
        top: 0,
        left: dir === 'rtl'?'':'350px',
        right: dir === 'rtl'?'350px': '',
        textAlign: dir === 'rtl'?'right': '',
        width: '350px',
        height: '100vh',
        backgroundColor: '#f9fafb',
        position: 'absolute',
        zIndex: 1000,
        visibility: 'hidden',
        opacity: 0,
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollbarWidth: 'thin', // For Firefox
        scrollbarColor: '#BBB #f1f1f1' // For Firefox
        // transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out'
    };
  
    const layerSecondVisible = {
        ...layerSecond,
        visibility: 'visible',
        opacity: 1,
        borderLeft: '1px solid #EEE'
    };

    const liStyle = {
      paddingTop: '5px',
      paddingBottom: '5px',
      paddingLeft: '10px',
      borderBottom: '1px solid #EEE'
    }

    const liStyle2 = {
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '10px',
      borderBottom: '1px solid #EEE'
    }
    
    
    const divRef = useRef(null);

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      // Clicked outside the div, handle the logic here
      if(setSideBarStatus){
        setHoveredItem(null); setSideBarStatus(false);
      }
      
    //   alert("clicked")
    }
  };

  useEffect(() => {
    // Add event listener on mount
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
    
    const handleMouseEnter = (categoryId) => {
      setHoveredItem(categoryId);
    };

    const handleMouseLeave = () => {
      if (hoverTimeout) {
        clearTimeout(hoverTimeout);
      }
    };

    // Add this style object for Webkit browsers
    const scrollbarStyles = {
        // '&::-webkitScrollbar': {
        //     width: '4px',
        //     height: '4px'
        // },
        // '&::-webkitScrollbarTrack': {
        //     background: '#f1f1f1'
        // },
        // '&::-webkitScrollbarThumb': {
        //     background: '#888',
        //     borderRadius: '2px'
        // },
        // '&::-webkitScrollbarThumb:hover': {
        //     background: '#555'
        // }
    };
    
    return (
        <div ref={divRef} style={{...layerFirst, ...scrollbarStyles}} dir={dir}>
            <i onClick={() => {setHoveredItem(null); setSideBarStatus(false);}} className="fas fa-times" style={{position: 'absolute', fontSize: '24px',left: dir === 'rtl'?'-24px': '',left: dir !== 'rtl'?'-24px': '', zIndex: '10',  padding: '4px 4px', zIndex: 10, cursor: 'pointer'}}></i>
            <div style={{overflow: 'auto', 
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: '#BBB #f1f1f1', 
              height: '100vh', ...scrollbarStyles}}>
            
            <ul style={{zIndex: 1000000, }}>
                {treeCategories && treeCategories.length > 0 && treeCategories.map((category, index) => (
                    <li style={liStyle} className="custom-sidebar" 
                        onMouseEnter={() => handleMouseEnter(category.STR_ID)} 
                        onMouseLeave={handleMouseLeave}
                        key={`${category.STR_ID}-${index}`} >
                        <NavLink to="#" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                          <img
                              alt={category.STR_NODE_NAME}
                              onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = "https://placehold.co/100x100/FFFFFF/FFFFFF/png";
                              }}
                              src={`${gdcURL}/category-images/600x600/${category.STR_ID}`}
                              style={{ width: '40px' }}
                          />
                          <span className="custom-sidebar-text" style={{color: '#0d1f2a', fontWeight: 400}}>{category.STR_NODE_NAME}</span>
                      </NavLink>
                        {
                        category.children && category.children.length > 0 && <i  className="fa fa-chevron-right mr-4" style={{float: 'right', marginTop: '-25px', fontSize: '10px'}}></i>}
                        {
                          
                        category.children && category.children.length > 0 && 
                        <div>
                          
                          {
                            hoveredItem === category.STR_ID && 
                            <i onClick={() => {setHoveredItem(null); setSideBarStatus(false);}} 
                               className="fas fa-times" 
                               style={{position: 'absolute', fontSize:'24px', top: 0,  right: '-375px', zIndex: '10',  padding: '4px 4px', cursor: 'pointer'}}>
                            </i>}
                        
                        <div style={hoveredItem === category.STR_ID ? layerSecondVisible : layerSecond}>
                            
                            <ul>
                                {category.children
                                    .filter((child, index, self) => 
                                        index === self.findIndex((t) => (
                                            t.STR_NODE_NAME === child.STR_NODE_NAME
                                        ))
                                    )
                                    .map((child, index) => (
                                    <li className="custom-sidebar" style={liStyle2} key={`${child.STR_ID}-${index}`}>
                                        <NavLink style={{color: '#0d1f2a', fontWeight: 400,paddingRight: dir === 'rtl'?'10px': '',}} 
                                                 onClick={() => {setSideBarStatus(false); setHoveredItem(null)}} 
                                                 to={`/Spare-Parts/${slugify(child.STR_NODE_NAME)}-${child.STR_ID}`}>
                                          {child.STR_NODE_NAME}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        </div>
                        }
                        
                    </li>
                ))}
                
            </ul>
            </div>
        </div>
    );
};

export default Sidebar;
