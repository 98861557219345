import React, { useContext, useEffect, useRef, useState } from "react";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";

// import CarSearchBox from "../Cars/CarSeachBox";
import { Helmet } from "react-helmet";
import { ConfigContext } from "../../../Context/ConfigContext";
import UniCarSearch from "../../Cars/UniCarSearch";
import CarSelectionSection from "../../Cars/CarSelectionSection";
import OtherParts from "../OtherParts";
import PopularSuppliers from "../PopularSuppliers";
import Sections from "./Sections";
import Tabs from "./Tabs";
import axios from "axios";
import { AddToGarage } from "../../../Helper/MyCars";
import slugify from "slugify";
import MobSection from "./MobSection";


const MakeSection = () => {
    const { currency } = useContext(ConfigContext);
    const location = useLocation();
    const { t } = useTranslation();
    const locale = localStorage.getItem("lang") ?? "en";
    const { gdcURL, makes, setContextPCID, setContextMSID } = useContext(ConfigContext);
    const dir = localStorage.getItem("dir");
    var { make, model } = useParams();
    const [MFA_ID, setMFAID] = useState(0);
    const [loading, setLoading] = useState(false);
    const [parts, setParts] = useState([]);

    const [selectedOE, setSelectedOE] = useState(null);
    const [selectedBrands, setSelectedBrands] = useState([]);

    const [departmentName, setDepartmentName] = useState("");
    const [supplierName, setSupplierName] = useState("");

    const [car_info, setCarInfo] = useState(
        localStorage.getItem("car_info")
            ? JSON.parse(localStorage.getItem("car_info"))
            : []
    );
    const [loadMore, setLoadMore] = useState(false);
    const loadMoreRef = useRef(null);

    const navigate = useNavigate();
    const [models, setModels] = useState([]);
    const [series, setSeries] = useState([]);
    const [showAllModels, setShowAllModels] = useState(false);
    const modelsPerLine = 3;
    const modelsToShow = showAllModels ? models : models.slice(0, modelsPerLine * 2);

    useEffect(() => {
        var foundMake = makes.find((m) => m.MFA_BRAND === make);
        if (foundMake) {
            setMFAID(foundMake.MFA_ID);
        }
        console.log("make.MFA_ID", foundMake);
    }, [make, makes]);

    useEffect(() => {
        if (make) {
            var mfa_id = MFA_ID;
            if (!MFA_ID) {
                var foundMake = makes.find((m) => m.MFA_BRAND === make);
                if (foundMake) {
                    mfa_id = foundMake.MFA_ID;
                }
            }


            if (mfa_id && mfa_id !== 0) {

                fetch(`${gdcURL}/Cars/GetUniModels?MFAID=${mfa_id}`)
                    .then((res) => res.json())
                    .then((data) => {
                        setModels(data.data);


                    })
            }
        } else {
            console.log("nomake", make)
        }

    }, [MFA_ID]);

    useEffect(() => {

        if (model) {

            var ms_id = model.split('-')[0];
            var mfa_id = MFA_ID;
            if (!MFA_ID) {
                var foundMake = makes.find((m) => m.MFA_BRAND === make);
                if (foundMake) {
                    mfa_id = foundMake.MFA_ID;
                }
            }
            if (ms_id) {
                axios.get(`${gdcURL}/Cars/GetUniSeries?MFAID=${mfa_id}&MSID=${ms_id}`)
                    .then(data => {
                        setSeries(data.data.data);
                        console.log("series", series)
                    })
            } else {
                console.log("series", "Failed")
            }
        }

    }, [model]);

    const SaveCar = async (pc_id) => {
        var ms_id = model.split('-')[0];
        var mfa_id = make.split('-')[0];
        if (pc_id && ms_id) {
            localStorage.setItem('mfa_id', mfa_id);
            localStorage.setItem('pc_id', pc_id);
            localStorage.setItem('ms_id', ms_id);


            var car = await GetCarInfo();
            localStorage.setItem('car_info', JSON.stringify([car]));

            AddToGarage(car);

            navigate('/Spare-Parts/Catelog')

        }

    }
    const GetCarInfo = async () => {
        var response = await axios.get(`${gdcURL}/Cars/GetCarInfo?pc_id=${localStorage.getItem('pc_id')}&lang=${locale}`);
        var data = response.data;

        localStorage.setItem('car_info', JSON.stringify(data.data));
        setContextPCID(data.data[0].PC_ID)
        setContextMSID(data.data[0].PC_MS_ID)
        return data.data[0];

    }

    return (
        <main style={{ marginTop: '-10px', textAlign: dir === 'rtl' ? 'right' : '' }} className="main" dir={dir} >

            <Helmet>
                <title>{departmentName || "Spare Parts"}</title>
                <meta name="keywords" />
            </Helmet>

            <div className="row justify-content-center pl-2" style={{ backgroundColor: 'rgb(238, 238, 238)' }}>
                <div className="col-md-8">
                    <nav aria-label="breadcrumb" className="breadcrumb-nav">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <NavLink to={"/"}>
                                    <i className="icon-home" />
                                </NavLink>
                            </li>
                            <li className="breadcrumb-item">
                                <NavLink to={`/Spare-Parts/Catelog`}>{t("catelog")}</NavLink>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                {departmentName}
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row justify-content-center pl-2" style={{ backgroundColor: 'rgb(238, 238, 238)' }}>
                <div className="col-md-8 mt-2">
                    <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                        {t('make_parts_catelogue', { make: make })}
                    </h2>
                </div>
            </div>
            <div className="row justify-content-center pl-2" style={{ backgroundColor: 'rgb(238, 238, 238)' }}>
                <div className="col-md-12" >
                    <div className="row justify-content-center pt-0" >
                        <div className="col-md-3" >
                            <div style={{ width: '400px' }}>
                                <UniCarSearch defaultMake={make && make} defaultModel={model && model.split('-')[1]} defaultMSID={model && model.split('-')[0]} redirectStatus={true} />
                            </div>
                        </div>
                        <div className="col-md-5" >
                            <center style={{ marginRight: '-50px' }}>
                                <br /> <br />
                                <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                                    {t('where_to_buy_at_good', { value: departmentName })}
                                </h2>
                                <div style={{
                                    width: '300px',
                                    height: '200px',
                                    overflow: 'hidden',
                                    position: 'relative'
                                }}>
                                    <img
                                        src={`${gdcURL}/make_logo/${MFA_ID}.png`}
                                        className="img-fluid"
                                        alt={departmentName}
                                        style={{
                                            position: 'absolute',
                                            top: '-10px',
                                            width: 'calc(50% + 100px)',
                                            height: 'calc(50% + 100px)',
                                            objectFit: 'cover'
                                        }}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = "https://placehold.co/100x100/FFFFFF/FFFFFF/png";
                                        }}
                                    />
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row justify-content-center mt-3">
                <div className="col-lg-9">
                    {!model && <h3 style={{ fontWeight: 500 }}>{t('spare_parts_for_your', { make: make })}</h3>}
                    {models && models.length > 0 && model && (
                        <h3 style={{ fontWeight: 500 }}>
                            {t('spare_parts_for_your_model', {
                                make: models.find((item) =>
                                    Number(model.split('-')[0]) === Number(item.MS_ID)
                                )?.MS_NAME
                            })}
                        </h3>
                    )}
                </div>
            </div>
            <div className="row justify-content-center pl-2">

                {
                    !model && models && modelsToShow.map((item, index) => {
                        if (index > 0 && index % modelsPerLine === 0) {
                            return (
                                <>
                                    <div className="w-100"></div>
                                    <div className="col-sm-3 m-1" style={{ backgroundColor: '#FFF', border: '1px solid #EEE', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                        <NavLink style={{ width: '100%' }} onClick={() => {

                                            window.location = `/Car-Brands/${make}/${item.MS_ID}-${slugify(item.MS_NAME)}`
                                        }} to={() => { }}>
                                            <table style={{ textAlign: 'center' }}>
                                                <tr>
                                                    <td rowSpan={2} style={{ height: '100px', width: '120px', textAlign: dir === 'rtl' ? 'right' : 'left' }}>
                                                        <img
                                                            width={100}
                                                            src={`${gdcURL}/car-ms-images/${item.MS_ID}`}
                                                            style={{ float: 'left' }}
                                                            onError={e => {
                                                                e.target.src = `${window.location.protocol}//${window.location.host}/assets/images/placeholder_car.png`;
                                                            }}
                                                            alt="Ghayar's Car Placeholder Image"
                                                        />
                                                    </td>
                                                    <td className={`nav-link-custom`} style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }}>
                                                        <b>{item && item.label && item.label.split(' ')[0]}</b> <br />
                                                        {item.label}
                                                    </td>
                                                </tr>
                                            </table>
                                        </NavLink>
                                    </div>
                                </>
                            );
                        }
                        return (
                            <div className="col-sm-3 m-1" style={{ backgroundColor: '#FFF', border: '1px solid #EEE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <NavLink style={{ width: '100%' }} onClick={() => {

                                    window.location = `/Car-Brands/${make}/${item.MS_ID}-${slugify(item.MS_NAME)}`
                                }} to={() => { }} >
                                    <table style={{ textAlign: 'center' }}>
                                        <tr>
                                            <td rowSpan={2} style={{ height: '100px', width: '120px', textAlign: 'left' }}>
                                                <img
                                                    width={100}
                                                    src={`${gdcURL}/car-ms-images/${item.MS_ID}`}
                                                    style={{ float: 'left' }}
                                                    alt={item && item.label}
                                                    onError={e => {
                                                        e.target.src = `${window.location.protocol}//${window.location.host}/assets/images/placeholder_car.png`;
                                                    }}
                                                />
                                            </td>
                                            <td className={`nav-link-custom`} style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }}>
                                                <b>{item && item.label && item.label.split(' ')[0]}</b> <br />
                                                {item.label}
                                            </td>
                                        </tr>
                                    </table>
                                </NavLink>
                            </div>
                        );
                    })
                }
                <div className="col-lg-12 pb-4">
                    {!model && models && models.length > modelsPerLine * 2 && (
                        <div className="text-center mt-3">
                            <button
                                className="btn btn-link"
                                onClick={() => setShowAllModels(!showAllModels)}
                                style={{ textDecoration: 'none' }}
                            >
                                {showAllModels ? t('show_less') : t('show_more')}
                                <i style={{ marginRight: dir === 'rtl' ? '10px' : '' }} className={`fas fa-chevron-${showAllModels ? 'up' : 'down'} ml-2`}></i>
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <div className="row justify-content-center pl-2" style={dir!=='rtl'?{marginLeft:  window.innerWidth <= 768 ? '20px': ''}: {marginRight:  window.innerWidth <= 768 ? '20px': ''}}>
                <div className="col-md-9 pb-4">
                    <div className="row justify-content-left">

                        {
                            series && series.map((item, index) => {
                                return <div className="col-md-3 p-2">
                                    <NavLink onClick={() => SaveCar(item.PC_ID)} >{item.label}</NavLink>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-lg-9">
                    <h3 style={{ fontWeight: 500 }}>{t('parts_catelogue_online', { make: make })}</h3>
                </div>
            </div>
            { window.innerWidth <= 768 ?<MobSection showAll={true}/>:<Tabs />}
        </main>
    );
};
const ImageWithFallback = ({ src, index, alt, fallbackText, OE }) => {
    const [imgError, setImgError] = useState(false);

    const handleError = () => {
        setImgError(true);
    };
    const fallbackStyle = {
        textAlign: "center",
        fontSize: "10px",
        padding: "10px",
        wordBreak: "break-word",
        maxWidth: "90%",
    };

    const containerStyle = {
        position: "relative",
        width: "100%",
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };
    return (
        <div style={containerStyle}>
            {imgError ? (
                <div style={fallbackStyle}>{fallbackText}</div>
            ) : (
                <img
                    draggable="false"
                    style={{
                        height: "40px",
                        margin: "auto",
                        float: "none",
                        marginTop: OE === false ? "5px" : 0,
                    }}
                    key={`img-part-${index}`}
                    src={src}
                    alt={alt}
                    onError={handleError}
                />
            )}
        </div>
    );


};
export default MakeSection;
