import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../Context/ConfigContext";
import axios from "axios";
import { GetCarts } from "../../../Helper/MyCart";

export default function MobBasketPayment(){
    const { gdcURL, apiURL, setLoginStatus, setToken, paymentMethod, setPaymentMethod } = useContext(ConfigContext);
    const { t } = useTranslation();
    
    useEffect(() => {
        console.log("paymentMethod", paymentMethod)
    }, [paymentMethod])

    // Add this to verify the initial state
    useEffect(() => {
        console.log("Initial paymentMethod:", paymentMethod);
    }, []);

    const handlePaymentChange = (method) => {
        console.log("Changing payment method to:", method);
        setPaymentMethod(method);
    }
    const dir = localStorage.getItem('dir');
    const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(true);
    const [CODApplicable, setCODApplicable] = useState(false);
    useEffect(() => {
        fetchCartDataFromLocalStorage();
    }, []);
    const fetchCartDataFromLocalStorage = async () => {
        try {
            const carts = GetCarts();
            if (carts?.length > 0) {
                const response = await axios.get(`${gdcURL}/Orders/GetCart`, {
                    params: {
                        currency: localStorage.getItem('currency') ?? 'aed',
                        lang:localStorage.getItem('lang') ?? 'en',
                        customer_id: localStorage.getItem("customer_id") || 0,
                        carts: JSON.stringify(carts),
                        coupon: localStorage.getItem('coupon')??'',
                    },
                });
                
                if(response.data.data){
                    setCODApplicable(response.data.data.cod_applicable);
                }
            }
            setPaymentMethodsLoading(false);
        } catch (err) {
            console.error("Error fetching cart data:", err);
            setPaymentMethodsLoading(false);
        }
    };
    return (
        <main className="main" dir={dir} style={{textAlign: dir ==='rtl'?'right': ''}}>
            <div className="row justify-content-center mt-2">
                <div className="col-sm-8" style={{textAlign: dir ==='rtl'?'right': 'left'}}>
                    <span className="product-description__item-title">{t("please_convenient_payment")}</span>
                    
                    <div id="accordion2" className="mt-2">
                        <div  className="card card-accordion accordion-boxed" >
                            <div onClick={() => handlePaymentChange('online')}  style={{backgroundColor: '#FFF', textAlign: 'left', height: '100px'}} className="card-header collapsed" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                <div className="row" style={{marginTop: '25px'}}>
                                    <div className="col-1">
                                        <input 
                                            type="radio" 
                                            name="paymentMethod" 
                                            checked={paymentMethod === 'online'} 
                                            onChange={() => handlePaymentChange('online')} 
                                        />
                                    </div>
                                    <div className="col-8">
                                        <img src="/assets/images/payment/visa.svg" alt="Visa" className="payment-icon" style={{width: '40px', display: 'inline-block'}} />
                                        <img src="/assets/images/payment/mc.svg" alt="Mastercard" className="payment-icon" style={{width: '40px', display: 'inline-block'}} />
                                        <img src="/assets/images/payment/ae.svg" alt="American Express" className="payment-icon" style={{width: '40px', display: 'inline-block'}} />
                                        <div className="col-12" style={{textAlign: dir ==='rtl'?'right': 'left'}}>
                                            <span  style={{fontSize: '13px', fontWeight: 500, }}>{t('online_payments_recommended')}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>

                            <div id="collapse4" className="collapse" data-parent="#accordion2">
                                <p>
                                    <span className="alert alert-default" style={{backgroundColor: '#f9fafb'}}>
                                    <i className="fas fa-lock" style={{color: '#000', marginTop: '-60px', fontSize: '16px'}} ></i> {t('we_care_about_your_security')}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="card card-accordion accordion-boxed" >
                            <div onClick={() => handlePaymentChange('bank')} style={{backgroundColor: '#FFF', textAlign: 'left', height: '100px'}} className="card-header collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                <div className="row align-items-center" style={{marginTop: '25px'}}>
                                    <div className="col-1">
                                        <input type="radio" name="paymentMethod" checked={paymentMethod === 'bank'} onChange={() => handlePaymentChange('bank')} />
                                    </div>
                                    <div className="col-3">
                                        <img src="/assets/images/payment/bank_transfer.svg" alt="Bank Transfer" className="payment-icon" style={{width: '60px', display: 'inline-block'}} />
                                    </div>
                                    <div className="col-8" style={{textAlign: dir ==='rtl'?'right': 'left'}}>
                                        <span style={{fontSize: '13px', fontWeight: 500}}>{t('bank_transfer')}</span>
                                    </div>
                                </div>
                                
                            </div>

                            <div id="collapse5" className="collapse" data-parent="#accordion2">
                                <p>
                                    <span className="alert alert-default" style={{backgroundColor: '#f9fafb'}}>{t('an_easy_money')}</span>
                                </p>
                            </div>
                        </div>
                        { CODApplicable && 
                        <div className="card card-accordion accordion-boxed" >
                            
                            <div onClick={() => handlePaymentChange('cod')} style={{backgroundColor: '#FFF', textAlign: 'left', height: '100px'}} className="card-header collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                <div className="row" style={{marginTop: '25px'}}>
                                    <div className="col-1">
                                        <input type="radio" name="paymentMethod" checked={paymentMethod === 'cod'} onChange={() => handlePaymentChange('cod')} />
                                    </div>
                                    <div className="col-4">
                                        <img src="/assets/images/payment/cod.png" alt="Cash on Delivery" className="payment-icon" style={{width: '80px', display: 'inline-block'}} />
                                    </div>
                                    <div className="col-6" style={{textAlign: dir ==='rtl'?'right': ''}}>
                                        <span className="ml-5" style={{fontSize: '13px', fontWeight: 500}}>{t('cash_on_delivery')}</span>
                                    </div>
                                </div>
                            </div>

                            <div id="collapse6" className="collapse" data-parent="#accordion2">
                                <p>
                                    <span className="alert alert-default" style={{backgroundColor: '#f9fafb'}}>{t('pay_with_cash_when')}</span>
                                </p>
                            </div>
                        </div>
                        }
                    </div>

                    <center><button onClick={() => window.location = '/basket/confirm'} className="btn btn-primary btn-lg mt-2" style={{width: '300px'}} disabled={!paymentMethod}>{t('next')}</button></center>
                </div>
            </div>
        </main>
    )
}