import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ConfigContext } from "../../Context/ConfigContext";
import slugify from "slugify";

const NoCarOtherParts = ({ STR_ID, STR_NODE_NAME }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang');
    const { gdcURL, PCID, setCategories, categories } = useContext(ConfigContext);
    const dir = localStorage.getItem('dir');
    const [STR_ID_PARENT, setPARENTSTRID] = useState(0);
    useEffect(() => {



        var obj = categories.find((item) => item.STR_ID_PARENT === Number(STR_ID));
        try {
            if (obj.STR_ID_PARENT !== undefined) {
                setPARENTSTRID(obj.STR_ID_PARENT);
                // alert(JSON.stringify(obj))
            }
        } catch (err) {
            // alert(err)
        }


    }, [])
    const LoadRelatedCategories = (STRIDPARENT) => {
        
        var rows = [];
        for (var i = 0; i < categories.length; i++) {
            if (categories[i].STR_ID_PARENT === STRIDPARENT) {
                rows.push(categories[i]);
            }
        }
        if(STRIDPARENT===0 && STR_ID !==0)
        {
            
            
            if(rows.length === 0){
                var obj = categories.find((item) => item.STR_ID === Number(STR_ID));
                console.log("obj", STRIDPARENT)
                for (var i = 0; i < categories.length; i++) {
                    
                    if (categories[i].STR_ID_PARENT === obj.STR_ID_PARENT && categories[i].STR_ID !== Number(STR_ID)) {
                       
                        rows.push(categories[i]);
                    }
                }
            }
            
            
           
        }
        const uniqueRows = rows.filter((row => {
            const seen = new Set();
            return row => seen.has(row.STR_NODE_NAME) ? false : seen.add(row.STR_NODE_NAME);
        })());
        return uniqueRows;
    }
    return (
        STR_ID_PARENT && LoadRelatedCategories(STR_ID_PARENT).length > 0 ?
        <div className="col-md-8 mt-3">
            <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                {t('advantageous_offers_for_vehicle', {value: STR_NODE_NAME})}
            </h2>
        <div className="row">


            {
                categories && categories.length > 0 && LoadRelatedCategories(STR_ID_PARENT).map((item) => {
                    
                    return <div style={{cursor: 'pointer'}} key={item.STR_ID} className="col-md-4" onClick={() => { navigate(`/Spare-Parts/${slugify(item.STR_NODE_NAME)}-${item.STR_ID}`); window.scrollTo(0, 0) }}>
                        <div className="d-flex align-items-center  rounded hover-shadow">
                            

                            <div style={{
                                width: '100px',
                                height: '60px',
                                overflow: 'hidden',
                                position: 'relative'
                            }}>
                                <img
                                    src={`${gdcURL}/category-images/600x600/${item.STR_ID}`}
                                    className="img-fluid"
                                    alt={item.STR_NODE_NAME}
                                    style={{
                                        position: 'absolute',
                                        top: '20px',
                                        width: 'calc(50% + 50px)',
                                        height: 'calc(50% + 50px)',
                                        objectFit: 'cover'
                                    }}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = "https://placehold.co/100x100/FFFFFF/FFFFFF/png";
                                    }}
                                />
                            </div>
                            <h6 className="mt-4">{item.STR_NODE_NAME}</h6>
                        </div>
                    </div>


                })
            }
        </div>
    </div>
    :<div></div>
    )
}

export default NoCarOtherParts;