import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import { components } from 'react-select';
import { ConfigContext } from '../../Context/ConfigContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { AddToGarage, GetGarages } from '../../Helper/MyCars';
import { Tooltip } from 'react-tooltip';

const UniCarSearch = ({ redirectStatus, home, setShowModalGarage, defaultMFAID, defaultMake, defaultYear, defaultMSID, defaultModel, defaultEngine, isHorizontal }) => {
  const { t } = useTranslation();
  const { setMyGarageCars, gdcURL, setContextMSID, PCID, TillYear, setContextPCID, setCategories, categories, saveCarInformation, makes, setMakes } = useContext(ConfigContext);
  const dir = localStorage.getItem('dir');
  const navigate = useNavigate();
  const locale = localStorage.getItem('lang');

  const [selectedCarMake, setSelectedCarMake] = useState();
  const [models, setModels] = useState([]);
  const [selectedCarModel, setSelectedCarModel] = useState();
  const [series, setSeries] = useState([]);
  const [selectedPCID, setSelectedPCID] = useState();
  const [selectedCarInfo, setSelectedCarInfo] = useState([]);
  const [vinLoading, setVINLoading] = useState(false);
  const [vin, setVIN] = useState('');
  const [carInfo, setCarInfo] = useState(localStorage.getItem('car_info')?JSON.parse(localStorage.getItem('car_info'))[0]: '');
  const [carImg, setCarImg] = useState(`${gdcURL}/car-ms-images/${localStorage.getItem('ms_id')}`);
  
  useEffect(() => {
    if (localStorage.getItem('car_info')) {
      var car = JSON.parse(localStorage.getItem('car_info'))[0];
      setCarInfo(car); 
    }
  }, [])
  const GetPlaceHolder = (type) => {
    if (localStorage.getItem('car_info')) {
      var car = "";
      try {
        car = JSON.parse(localStorage.getItem('car_info'))[0];

      } catch (err) {
        return null;
      }
      if (type === 'MAKE') {
        return car.MFA_BRAND;
      } else if (type === "MODEL") {
        return car.ms_label;
      } else if (type === "SERIES") {
        return car.sr_label;
      }
    } else {
      if (type === 'MAKE' && defaultMake) {
        return defaultMake;
      }
      return null;
    }
  }
  const handleResetModel = () => {

    setSelectedCarModel(null);
    setModels([]);
    setSelectedPCID(null);
    setSeries([]);
  };

  useEffect(() => {
    axios.get(`${gdcURL}/Cars/GetMakes`)
      .then(data => {

        setMakes(data.data.data);
        handleResetModel();
      })
  }, []);

  useEffect(() => {
    if(defaultMake && makes && makes.length > 0){
      var m = makes.find((item) => item.MFA_BRAND === defaultMake);
      
      setSelectedCarMake(m.MFA_ID);
    }

    if(defaultMake && makes && makes.length > 0 && defaultMSID){
      setSelectedCarModel(parseInt(defaultMSID));
    }
    
  }, [makes])

  useEffect(() => {
    if (selectedCarMake || carInfo || defaultMake) {

      var mfa_id = "";
      if (selectedCarMake) {
        mfa_id = selectedCarMake;
      } else {
        if (carInfo) {
          mfa_id = carInfo.PC_MFA_ID;
        }
      }
      
      axios.get(`${gdcURL}/Cars/GetUniModels?MFAID=${mfa_id}`)
        .then(data => {
         
          setModels(data.data.data);
          setSelectedPCID("");
          setSeries([]);
        })
    }
  }, [selectedCarMake, defaultMake]);

  useEffect(() => {
    
    if ((selectedCarMake && selectedCarModel) || carInfo) {
      var mfa_id = "";
      var ms_id = "";
      if (selectedCarMake ) {
        mfa_id = selectedCarMake; 
      } else {
        if (carInfo) {
          mfa_id = carInfo.PC_MFA_ID; 
        }
      }

      if (selectedCarModel) {
       
        ms_id = selectedCarModel;
      } else {
        if (carInfo) {
          
          ms_id = carInfo.PC_MS_ID;
        }
      }
      
      axios.get(`${gdcURL}/Cars/GetUniSeries?MFAID=${mfa_id}&MSID=${ms_id}`)
        .then(data => {

          setSeries(data.data.data);
          console.log("GetUniSeries",`${gdcURL}/Cars/GetUniSeries?MFAID=${mfa_id}&MSID=${ms_id}`)
        })
    }else{
      console.log("GetUniSeries",selectedCarMake , selectedCarModel)
    }
    
  }, [selectedCarModel]);

  const SaveCar = async () => {
    if (vin !== '') {
      GetVIN();
      return false;
    }
    if (selectedPCID) {
      localStorage.setItem('mfa_id', selectedCarMake);
      localStorage.setItem('pc_id', selectedPCID);
      localStorage.setItem('ms_id', selectedCarModel);
      var car = await GetCarInfo();
      localStorage.setItem('car_info', JSON.stringify([car]));

      await AddToGarage(car);
      setMyGarageCars(GetGarages());
      if (setShowModalGarage) {
        window.location.reload()
      } else {
        window.location = `/Spare-Parts/Catelog`;
      }

    } else if (GetPlaceHolder('SERIES')) {

      navigate(`/Spare-Parts/Catelog`);
    } else {

    }

  }

  const removeCar = () => {
    saveCarInformation('', '', '', '', '');
    localStorage.setItem('pc_id', '');
    localStorage.setItem('ms_id', '');
    localStorage.setItem('car_info', '');
    setSelectedCarMake(null);
    setSelectedCarModel(null);
    setSelectedPCID(null);
    // navigate(`/Spare-Parts/Catelog`)
    window.location.reload();
  }
  const GetVIN = async () => {
    if (vin.length < 17) {
      alert('Invalid VIN');
      return false;
    }
    setVINLoading(true);
    fetch(`${gdcURL}/Cars/GetVINDecode?vin=${vin}`)
      .then((res) => res.text())
      .then(async (data) => {
        var vindata = JSON.parse(data);
        vindata = JSON.parse(vindata);
       
        setVINLoading(false);
        if (vindata.statusMsg === "Success") {
          if (vindata.result[0].carId) {
            localStorage.setItem('pc_id', vindata.result[0].carId);
            var car = await GetCarInfo();
            AddToGarage(car);
            if (setShowModalGarage) {
              setShowModalGarage(false);
            }
            navigate(`/Spare-Parts/Catelog`);
          } else {
            alert("Not Found, Please select from above menu")
          }
        } else {
          alert("Not Found, Please select from above menu")
        }

      })
  }
  const GetCarInfo = async () => {
    var response = await axios.get(`${gdcURL}/Cars/GetCarInfo?pc_id=${localStorage.getItem('pc_id')}&lang=${locale}`);
    var data = response.data;

    localStorage.setItem('car_info', JSON.stringify(data.data));
    // setCarImg(`${gdcURL}/car-ms-images/${localStorage.getItem('ms_id')}`);
    // setCarInfo(data.data)

    setContextPCID(data.data[0].PC_ID)
    setContextMSID(data.data[0].PC_MS_ID)
    return data.data[0];

  }
  const customStyles = {
    menuPortal: base => ({ ...base, zIndex: setShowModalGarage ? 999999999999 : 999999, textAlign: dir === 'rtl' ? 'right' : 'left', }),
    control: base => ({ ...base, zIndex: setShowModalGarage ? 999999999999 : 999999, textAlign: dir === 'rtl' ? 'right' : 'left', paddingLeft: '10px', paddingRight: dir === 'rtl' ? '10px' : '' }),
    placeholder: base => ({ ...base, zIndex: setShowModalGarage ? 999999999999 : 999999, textAlign: dir === 'rtl' ? 'right' : 'left' })
  };

  const CustomControl = ({ children, ...props }) => (
    <components.Control {...props}>
      <span style={{ backgroundColor: props.selectProps.isDisabled ? '#CCC' : '#cc000b', borderRadius: '50px', width: '20px', textAlign: 'center', color: '#FFF', fontSize: '10px', paddingTop: '3px', paddingBottom: '3px' }}>{props.selectProps.srNo}</span>
      {children}
    </components.Control>
  );

  const groupModels = (models) => {
    const grouped = models.reduce((acc, model) => {
      const groupName = model.label.split(' ')[0];
      if (!acc[groupName]) {
        acc[groupName] = {
          label: groupName,
          options: []
        };
      }
      acc[groupName].options.push(model);
      return acc;
    }, {});

    return Object.values(grouped);
  };

  const groupSeriesByFuelType = (series) => {
    const grouped = series.reduce((acc, item) => {
      const fuelType = item.FUEL_TYPE || 'Other Fuel Type';
      if (!acc[fuelType]) {
        acc[fuelType] = {
          label: fuelType,
          options: []
        };
      }
      acc[fuelType].options.push(item);
      return acc;
    }, {});

    return Object.values(grouped);
  };
  const SelectMake = () => {
    return <Select
      srNo={'1'}
      components={{ Control: CustomControl, DropdownIndicator: null }}
      menuPortalTarget={document.body}
      styles={customStyles}
      className='w-100'
      options={makes ?? []}
      onChange={(e) => { setSelectedCarMake(e.value); handleResetModel(); }}
      placeholder={GetPlaceHolder('MAKE') ?? t('select_car_make')}
      value={
        selectedCarMake 
          ? (makes?.find(item => item.MFA_ID === selectedCarMake) || null)
          : (makes?.find(item => item.MFA_BRAND === GetPlaceHolder('MAKE')) || null)
      }
      />
  }

  const SelectModel = () => {
    return <Select
      srNo={'2'}
      components={{ Control: CustomControl, DropdownIndicator: null }}
      menuPortalTarget={document.body}
      styles={customStyles}
      className='w-100'
      options={groupModels(models) ?? []}
      onChange={(e) => {
        if (e) {
          setSelectedCarModel(parseInt(e.value));
        } else {
          setSelectedCarModel(null);
        }
      }}
      placeholder={GetPlaceHolder('MODEL') ?? t('select_car_model')}
      value={models && models.find((item) => parseInt(item.value) === selectedCarModel??defaultMSID)}
      isClearable={true} 
    />
  }
  const SelectSeries = () => {
    return <Select
      srNo={'3'}
      components={{ Control: CustomControl, DropdownIndicator: null }}
      menuPortalTarget={document.body}
      styles={customStyles}
      className='w-100'
      options={groupSeriesByFuelType(series) ?? []}
      onChange={(e) => {
        if (e) {
          setSelectedPCID(e.value);
          setSelectedCarInfo(e);
        } else {
          setSelectedPCID(null);
        }
      }}
      placeholder={GetPlaceHolder('SERIES') ?? t('select_car_series')}
      value={selectedPCID ? series.find((item) => item.value === selectedPCID) : null}
      isClearable={true} />
  }
  if (isHorizontal) {
    return (
      <div dir={dir} style={{  backgroundColor: '#eeeeee', borderRadius: 0, paddingTop: '30px', paddingBottom: '20px', marginRight: '20px', marginLeft: '20px', paddingRight: '20px' }}>

        <div className="" >

          <div className='row' >
            <div className='col-sm-1'>
              <div style={{ marginTop: '-5px' }} className={`col-12 d-flex justify-content-center align-items-center col-md-12`} >
                <svg viewBox="0 0 32 32" className="sc-1k8rycc-0 huZjNT" style={{ marginLeft: "10px", padding: "5px", border: "2px solid #132531", borderRadius: "0", width: "50px", height: "50px", }} >
                  <path d="M27.867 12.667h-1.733c-0.667-0.933-0.667-0.933-1.467-2.267-1.2-2-3.2-2.4-3.2-2.4l-5.333-0.133-5.467 0.133c0 0-2 0.533-3.333 2.533-0.933 1.333-0.667 0.8-1.467 2.133h-1.733c-0.533 0-0.933 0.4-0.933 0.933 0 0 0 0 0 0v0.133c0 0.533 0.4 0.933 0.933 0.933h1.067l-0.533 1.6c0 0-0.267 0.533-0.267 1.867v5.867c0 0.667 0.533 1.333 1.333 1.333h2.133c0.667 0 1.333-0.533 1.333-1.333v-0.8h13.867v0.8c0 0.667 0.533 1.333 1.333 1.333h2.133c0.667 0 1.333-0.533 1.333-1.333v-6c0-1.333-0.267-1.867-0.267-1.867l-0.667-1.467h1.067c0.533 0 0.933-0.4 0.933-0.933v-0.133c-0.133-0.533-0.533-0.933-1.067-0.933zM9.067 19.467l-2.8-0.133c-0.4-0.133-0.667-0.4-0.8-0.8s-0.133-1.067 0-1.467c0.267-0.8 1.2-0.4 1.733-0.133 0.8 0.267 1.6 0.667 2.4 0.933 0.533 0.133 0.8 0.533 0.8 1.067-0.133 0.667-0.933 0.533-1.333 0.533zM16 14.533c-4.133 0-8.667-0.8-8.667-0.8l1.333-2.667c0.533-1.467 2.667-1.6 2.667-1.6l4.667-0.133h4.667c0 0 2 0.133 2.533 1.6l1.333 2.667c0.133 0.133-4.4 0.933-8.533 0.933v0zM26.533 18.533c-0.133 0.4-0.4 0.667-0.8 0.8l-2.8 0.133c-0.533 0-1.2 0-1.333-0.533s0.267-0.933 0.8-1.067c0.8-0.267 1.6-0.667 2.4-0.933 0.533-0.133 1.467-0.533 1.733 0.133 0.133 0.4 0.133 1.067 0 1.467z" fill="#132531" ></path>
                </svg>
              </div>
            </div>
            <div className='col-sm-3' >
              <div className="row" >
                <div className='pb-2 col-2 d-flex col-sm-10  '>
                  <div style={{ position: 'relative', width: '100%' }}>
                    <input
                      style={{ borderRadius: '0px', height: '38px', paddingRight: '30px' }}
                      className="form-control"
                      placeholder={t("enter_vin")}
                      value={vin}
                      onKeyUp={event => { if (event.key === 'Enter') { SaveCar() } }}
                      onChange={(e) => setVIN(e.target.value.replaceAll(' ', ''))}
                    />
                    <img
                      src="/assets/icons/credit-card.png"
                      alt="Credit Card"
                      style={{ position: 'absolute', right: '10px', top: '40%', transform: 'translateY(-50%)', width: '20px', height: '20px' }}
                    />
                  </div>
                </div>
                <div className='pb-2 col-12 d-flex col-sm-2  justify-content-center align-items-center '>
                  <button className={`btn btn-outline-light pl-3 pr-3`} style={{ marginTop: '-10px', fontSize: "15px", borderRadius: "0px", fontWeight: "bold", padding: '0px', height: '43px', background: '#132531', color: '#eee' }} type='button' disabled={vinLoading} onClick={SaveCar} >{vinLoading ? <i className="fas fa-spinner fa-spin"></i> : t('go')} </button>
                </div>
              </div>
            </div>
            <div className='col-sm-2 '>
              <SelectMake />
            </div>
            <div className='col-sm-3'>
              <SelectModel />
            </div>
            <div className='col-sm-2'>
              <SelectSeries />
            </div>
            <div className='col-sm-1 '>
              <button className={`btn btn-primary-light pull-right`} style={{ width: "100%", fontSize: "15px", borderRadius: "0px", fontWeight: "bold", height: '43px', padding: '0px', background: '#132531', color: '#eee' }} type='button' onClick={() => SaveCar()} >{t('search')} </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // if(carInfo){

  //   return (
  //     <div dir={dir} style={{ backgroundColor: '#fff', border: '0px solid #CCC',borderRadius: 0 }}>
  //       <div className="row justify-content-center pl-4 pr-4 pt-3" style={{ height: '362px'}}>


  //         <div className='col-12 '>
  //           <div className='col-12' style={{textAlign: 'center'}}>
  //             <h3 style={{ color: '#132531' }}>{t('your_curent_vehicle')}</h3>
  //           </div>
  //           <div className='col-lg-6' style={{  margin: "auto", borderRadius: '15px', padding: '15px', backgroundColor: '#FFF' }}>
  //             <img  src={carImg}   className="img-fluid" alt={carInfo && carInfo.TYPEL} 
  //           onError={e => {setCarImg(`${window.location.protocol + '//' + window.location.host}/assets/images/placeholder_car.png`)}}

  //           />
  //           </div>
  //           <br />
  //           <table  cellPadding={8}>
  //             <tr><td style={{textAlign: dir==='rtl'?'right': 'left'}}>{t('manufacturer')}:</td><th style={{textAlign: 'center'}}> {carInfo.MFA_BRAND}</th></tr>
  //             <tr><td style={{textAlign: dir==='rtl'?'right': 'left'}}>{t('year')}:</td><th style={{textAlign: 'center'}}> {`${new Date(carInfo.PCS_CI_FROM).toLocaleString('default', {  year: 'numeric' })} - ${carInfo.PCS_CI_TO ? new Date(carInfo.PCS_CI_TO).toLocaleString('default', {  year: 'numeric' }) : TillYear}`}</th></tr>
  //             <tr><td style={{textAlign: dir==='rtl'?'right': 'left'}}>{t('model')}:</td><th style={{textAlign: 'center'}}> {carInfo.ms_label?carInfo.ms_label.replaceAll(`${new Date(carInfo.PCS_CI_FROM).toLocaleString('default', {  year: 'numeric' })}`, ''): carInfo.ms_label}</th></tr>
  //             <tr><td style={{textAlign: dir==='rtl'?'right': 'left'}}>{t('engine')}:</td><th style={{textAlign: 'center'}}> {carInfo.sr_label}</th></tr>
  //           </table>
  //           <button onClick={() => removeCar()} className={`btn btn-primary-light pull-right`} style={{ width: "100%", fontSize: "15px", borderRadius: "0px", fontWeight: "bold", height: '43px', padding: '0px', background: '#0663ce', color: '#eee' }} type='button' >{t('change_car')} </button>            
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
  return (
    <div dir={dir} style={{ backgroundColor: '#eeeeee', borderRadius: 0 }}>

      <div className="row justify-content-center pl-4 pr-4 pt-3" style={{  height: window.innerWidth <= 768 ?'auto':'361px', margin: 0, overflow: 'hidden' }} >
        {/* <div className={`col-12 d-flex justify-content-center align-items-center col-md-12 pb-4`}>
          <svg viewBox="0 0 32 32" className="sc-1k8rycc-0 huZjNT" style={{ marginLeft: "10px", marginTop: "5px", padding: "5px", border: "2px solid #132531", borderRadius: "0", width: "50px", height: "50px", }} >
            <path d="M27.867 12.667h-1.733c-0.667-0.933-0.667-0.933-1.467-2.267-1.2-2-3.2-2.4-3.2-2.4l-5.333-0.133-5.467 0.133c0 0-2 0.533-3.333 2.533-0.933 1.333-0.667 0.8-1.467 2.133h-1.733c-0.533 0-0.933 0.4-0.933 0.933 0 0 0 0 0 0v0.133c0 0.533 0.4 0.933 0.933 0.933h1.067l-0.533 1.6c0 0-0.267 0.533-0.267 1.867v5.867c0 0.667 0.533 1.333 1.333 1.333h2.133c0.667 0 1.333-0.533 1.333-1.333v-0.8h13.867v0.8c0 0.667 0.533 1.333 1.333 1.333h2.133c0.667 0 1.333-0.533 1.333-1.333v-6c0-1.333-0.267-1.867-0.267-1.867l-0.667-1.467h1.067c0.533 0 0.933-0.4 0.933-0.933v-0.133c-0.133-0.533-0.533-0.933-1.067-0.933zM9.067 19.467l-2.8-0.133c-0.4-0.133-0.667-0.4-0.8-0.8s-0.133-1.067 0-1.467c0.267-0.8 1.2-0.4 1.733-0.133 0.8 0.267 1.6 0.667 2.4 0.933 0.533 0.133 0.8 0.533 0.8 1.067-0.133 0.667-0.933 0.533-1.333 0.533zM16 14.533c-4.133 0-8.667-0.8-8.667-0.8l1.333-2.667c0.533-1.467 2.667-1.6 2.667-1.6l4.667-0.133h4.667c0 0 2 0.133 2.533 1.6l1.333 2.667c0.133 0.133-4.4 0.933-8.533 0.933v0zM26.533 18.533c-0.133 0.4-0.4 0.667-0.8 0.8l-2.8 0.133c-0.533 0-1.2 0-1.333-0.533s0.267-0.933 0.8-1.067c0.8-0.267 1.6-0.667 2.4-0.933 0.533-0.133 1.467-0.533 1.733 0.133 0.133 0.4 0.133 1.067 0 1.467z" fill="#132531" ></path>
          </svg>
        </div> */}
        <div className="row" style={{ width: '100%', zIndex: 10 }}>
          <div className='col-12 col-sm-12 d-flex '>
            <label style={{ color: '#132531' }}>{t("search_car_parts_by_vin")}</label>
          </div>
          <div className='col-10' style={{ paddingRight: '0px', paddingLeft: dir === 'rtl' ? '0px' : '' }}>
            <div style={{ position: 'relative', width: '100%' }}>
              <input
                style={{ borderRadius: '0px', height: '43px', paddingRight: '30px', paddingRight: dir === 'rtl' ? '40px' : '', }}
                className="form-control"
                placeholder={t("enter_vin")}
                value={vin}
                onKeyUp={event => { if (event.key === 'Enter') { SaveCar() } }}
                onChange={(e) => setVIN(e.target.value.replaceAll(' ', ''))}
              />
              <img
                src="/assets/icons/credit-card.png"
                alt="Credit Card"
                style={{ position: 'absolute', right: '10px', top: '40%', transform: 'translateY(-50%)', width: '20px', height: '20px' }}
              />
            </div>

          </div>
          <div className='col-2 '>
            <button className={`btn btn-outline-light pl-3 pr-3`} style={{  fontSize: "15px", borderRadius: "0px", fontWeight: "bold", padding: '0px', height: '43px', background: '#132531', color: '#eee' }} type='button' disabled={vinLoading} onClick={SaveCar} >{vinLoading ? <i className="fas fa-spinner fa-spin"></i> : t('go')} </button>
          </div>
        </div>
        <h5 style={{ width: '100%', textAlign: 'center', borderBottom: '1px solid rgb(204 199 199)', lineHeight: '0.1em', margin: '10px 0 20px' }}><span style={{ background: '1px solid rgb(204 199 199)', padding: '10 0px', color: '#132531', backgroundColor: '#eeeeee' }}>{t('or')}</span></h5>
        <div className='col-12 col-sm-12 d-flex justify-content-between align-items-center'>
          <label style={{ color: '#132531' }}>{t("select_your_car_to_search_for_auto_parts")}</label>
          <i 
            className="sicon-reload" 
            style={{ 
              color: '#132531', 
              cursor: 'pointer',
              fontSize: '16px',
              marginTop: '-10px',
              marginRight: '3px',
              transform: 'rotate(90deg)'
            }} 
            onClick={() => removeCar()}
          />
        </div>
        <div className='col-12 '>
          <SelectMake />
        </div>

        <div className='col-12 mt-1'>
          <SelectModel />
        </div>
        <div className='col-12 mt-1'>
          <SelectSeries />
        </div>
        <div className='col-12 mt-2' style={{ paddingBottom: '25px' }}>
          { <button className={`btn btn-primary-light pull-right`} style={{ width: "100%", fontSize: "15px", borderRadius: "0px", fontWeight: "bold", height: '43px', padding: '0px', background: '#132531', color: '#eee' }} type='button' onClick={() => SaveCar()} >{t('search')} </button>
          }
        </div>
      </div>
    </div>
  )
}


export default UniCarSearch;
