import React, { Suspense } from 'react';
import { NavLink, Outlet } from 'react-router-dom';

const BasketLayout = ({ children }) => {
  console.log('BasketLayout rendering', children);
  return (
    <>
      <div className="page-wrapper">
       
        {/* <div style={{backgroundColor: '#f1f4f6', height: '130px', width: '100%', position: 'absolute', zIndex: 0}}>
            
            <img  src={`${process.env.PUBLIC_URL}/assets/images/Logo/logo_dark.png`} style={{ 
                height: '63px', 
                position: 'absolute',
                left: '20px',
                top: '50%',
                transform: 'translateY(-50%)'
            }} onClick={() => window.location = '/'} alt="Ghayar Logo" />
            <center><div className="" style={{border: '1px solid rgba(204, 204, 204, 0.8)', zIndex: '10000', width: '100%', float: 'none', width: '660px', marginTop: '55.5px'}} ></div></center>
            
        </div> */}
        <main className="main">

          <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </Suspense>
        </main>
       
      </div>
      
    </>
  );
};

export default BasketLayout; 