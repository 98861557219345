import axios from "axios";
import React, { useState, useEffect, useContext, useTransition } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../../Context/ConfigContext";
import { useTranslation } from "react-i18next";
import { GoogleLogin } from "@react-oauth/google";

const MobBasketLogin = ({redirectTo}) => {
    const { apiURL, setLoginStatus, setToken } = useContext(ConfigContext);
    const navigate = useNavigate();
    const {t} = useTranslation();
    if (localStorage.getItem('token') && localStorage.getItem('customer_id')) {
        if(redirectTo){
            window.location = "/"+redirectTo;
        }else{
            window.location = "/basket/address";
        }
        
    }
    

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email_address: '',
        mobile_number: '',
        password: '',
        country_code: '',
        user_id: '',
        user_password: '',
        user_confirm_password: ''
    });
    const [LoginPasswordShow, setLoginPasswordShow] = useState('far fa-eye'); //far fa-eye-slash
    const [SignupPasswordShow, setSignupPasswordShow] = useState('far fa-eye'); //far fa-eye-slash
    const [countries, setCountries] = useState([]);
    const [countryPhoneCode, setCountryPhoneCode] = useState(0);
    const [messages, setMessages] = useState({
        error_message_for_SignIn: '',
        success_message_for_SignIn: '',
        error_message_for_SignUp: '',
        success_message_for_SignUp: ''
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${apiURL}/Home/GetCountries`);
                if (response.data) {
                    setCountries(response.data.data);
                } else {
                    console.log("NO Countries Found", response.data.message);
                }
            } catch (err) {
                console.log("Something Went Wrong", err);
            }
        };

        fetchCountries();
    }, [apiURL]);
    const [ user, setUser ] = useState([]);
    const [ profile, setProfile ] = useState([]);
    

    const handleLogin = async (e) => {
        e.preventDefault();
        const { user_id, user_password } = formData;

        const loginData = { login_id: user_id, password: user_password };

        try {
            const { data } = await axios.post( `${apiURL}/Account/Login`, null, { params: loginData } );

            if (data.success) {
                localStorage.setItem('token', data.data[0].token);
                setToken(data.data[0].token);
                localStorage.setItem('customer_id', data.data[0].customer_id);
                localStorage.setItem('email_address', data.data[0].email_address);
                localStorage.setItem('first_name', data.data[0].first_name);
                localStorage.setItem('mobile_number', data.data[0].mobile_number);
                setMessages({ ...messages, success_message_for_SignIn: data.message, error_message_for_SignIn: '' });
                setLoginStatus(!!localStorage.getItem('token'));
                setTimeout(() => {
                    navigate("/");
                }, 1000);
            } else {
                setMessages({ ...messages, error_message_for_SignIn: data.message, success_message_for_SignIn: '' });
            }
        } catch (error) {
            console.error("Error:", error);
            setMessages({ ...messages, error_message_for_SignIn: "An error occurred while logging in.", success_message_for_SignIn: '' });
        }
    };
    const handleGoogleLogin = async (idToken) => {
        console.log("idToken", idToken)
        
      try {
          const { data } = await axios.post( `${apiURL}/Account/GoogleLogin`,  { idToken: idToken} );

          if (data.success) {
              localStorage.setItem('token', data.data[0].token);
              setToken(data.data[0].token);
              localStorage.setItem('customer_id', data.data[0].customer_id);
              localStorage.setItem('email_address', data.data[0].email_address);
              localStorage.setItem('first_name', data.data[0].first_name);
              localStorage.setItem('mobile_number', data.data[0].mobile_number);
              setMessages({ ...messages, success_message_for_SignIn: data.message, error_message_for_SignIn: '' });
              setLoginStatus(!!localStorage.getItem('token'));
              setTimeout(() => {
                  navigate("/");
              }, 1000);
          } else {
              setMessages({ ...messages, error_message_for_SignIn: data.message, success_message_for_SignIn: '' });
          }
      } catch (error) {
          console.error("Error:", error);
          setMessages({ ...messages, error_message_for_SignIn: "An error occurred while logging in.", success_message_for_SignIn: '' });
      }
  };

    const handleSignUp = async (e) => {
        e.preventDefault();
        const { first_name, last_name, email_address, mobile_number, password, country_code, user_confirm_password } = formData;

        const validationErrors = {};
        if (!first_name) {
            validationErrors.first_name = t('first_name_error');
        } else if (!/^[a-zA-Z]+(?: [a-zA-Z]+)?$/.test(first_name)) {
            validationErrors.first_name = t('first_name_error');
        }

        if (!last_name) {
            validationErrors.last_name = t('last_name_error');
        } else if (!/^[a-zA-Z]+(?: [a-zA-Z]+)?$/.test(last_name)) {
            validationErrors.last_name = t('last_name_error');
        }

        if (!email_address) {
            validationErrors.email_address = t('email_error');
        }
        if (!password) {
            validationErrors.password = t('password_error');
        }

        if (Object.keys(validationErrors).length === 0) {
            const signUpData = {
                first_name,
                last_name,
                email_address,
                mobile_number,
                password,
                country_code
            };

            try {
                const { data } = await axios.post(
                    `${apiURL}/Account/Signup`,
                    null,
                    { params: signUpData }
                );

                if (data.success) {
                    setFormData({
                        first_name: "",
                        last_name: "",
                        email_address: "",
                        mobile_number: "",
                        password: "",
                        country_code: "",
                        user_confirm_password: ""
                    });
                    setMessages({
                        ...messages,
                        success_message_for_SignUp: t('we_have_sent_you'),
                        error_message_for_SignUp: ""
                    });
                    // setTimeout(() => {
                    //     setMessages({ ...messages, success_message_for_SignUp: "" });
                    // }, 1000);
                    // navigate("/Signup");
                } else {
                    setMessages({ ...messages, error_message_for_SignUp: data.message, success_message_for_SignUp: "" });
                }
            } catch (error) {
                console.error("Error:", error);
                setMessages({ ...messages, error_message_for_SignUp: "An error occurred while signing up.", success_message_for_SignUp: "" });
            }
        } else {
            setErrors(validationErrors);
        }
    };

    const handleCountryChange = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const phoneCode = e.target.options[selectedIndex].getAttribute('data-phone_code');
        setCountryPhoneCode(phoneCode);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    
    const handleSuccess = (response) => {
      
      // Get the ID token from the response
      const idToken = response.credential;
      handleGoogleLogin(idToken);
      // Decode the ID token to get user info
      const user = decodeJwt(idToken);
      console.log('User Info:', response);
      console.log('User Email:', user.email);
    };
  
    const decodeJwt = (token) => {
      const base64Url = token.split('.')[1];
      const base64 = decodeURIComponent(atob(base64Url).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(base64);
    };
    const dir = localStorage.getItem('dir');

    return (
        <main className="main" style={{ direction: dir, textAlign: dir === 'rtl' ? 'right' : 'left' }}>
            <div className="container login-container">
                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="heading mb-1 mt-5">
                                    <h2 className="title">{t('login')}</h2>
                                </div>
                                <form onSubmit={handleLogin}>
                                    <label htmlFor="login-email">
                                        {t('email')}
                                        <span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-input form-wide"
                                        id="User_id"
                                        name="user_id"
                                        required
                                        value={formData.user_id}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="login-password">
                                        {t('password')}
                                        <span className="required">*</span>
                                    </label>
                                    <table className="mb-3">
                                    <tbody>
                                        <tr>
                                            <td width={`100%`}>
                                                <input type={LoginPasswordShow === 'far fa-eye'?'password':'text'} className="form-input form-wide mb-0" id="User_password" name="user_password" required value={formData.user_password} onChange={handleChange} />
                                            </td>
                                            <td className="pl-4 pr-4" style={{backgroundColor: '#e9e9e9'}} onClick={() => setLoginPasswordShow(LoginPasswordShow === 'far fa-eye'?'far fa-eye-slash':'far fa-eye')}>
                                                <i className={LoginPasswordShow} id="togglePassword" style={{cursor: 'pointer'}}></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <div className="form-footer d-inline">
                                        <div className="custom-control custom-checkbox float-left">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="lost-password"
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="lost-password"
                                            >
                                                {t('remember_me')}
                                            </label>
                                        </div>
                                        <div className='float-right'>
                                            <NavLink to="/Forget-Password" className="forget-password text-dark">
                                                {t('forget_password')}
                                            </NavLink>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-dark btn-md w-100">
                                        {t('login')}
                                    </button>
                                    <br />
                                    <br />
                                    <GoogleLogin
                                        size="large"
                                        onSuccess={handleSuccess}
                                        onError={() => {
                                            console.log('Login Failed');
                                        }}
                                    />
                                    {messages.error_message_for_SignIn && (
                                        <div id="error" className="alert alert-danger mt-1">
                                            {messages.error_message_for_SignIn}
                                        </div>
                                    )}
                                    {messages.success_message_for_SignIn && (
                                        <div id="success" className="alert alert-success mt-1">
                                            {messages.success_message_for_SignIn}
                                        </div>
                                    )}
                                </form>
                            </div>
                            <div className="col-md-6">
                                <div className="heading mb-1 mt-5">
                                    <h2 className="title">{t('create_account')}</h2>
                                </div>
                                <form onSubmit={handleSignUp}>
                                    <label htmlFor="first_name">{t('first_name')}</label>
                                    <input
                                        name="first_name"
                                        className="form-input form-wide"
                                        placeholder={t('first_name')}
                                        value={formData.first_name}
                                        onChange={handleChange}
                                        type="text"
                                        required
                                    />
                                    {errors.first_name && <div className="colmd-12" style={{ color: 'red' }}>{errors.first_name}</div>}
                                    <label htmlFor="last_name">{t('last_name')}</label>
                                    <input
                                        name="last_name"
                                        className="form-input form-wide"
                                        placeholder={t('last_name')}
                                        type="text"
                                        value={formData.last_name}
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.last_name && <div className="colmd-12" style={{ color: 'red' }}>{errors.last_name}</div>}
                                    <label htmlFor="country">{t('country')}</label>
                                    <select
                                        name="country_code"
                                        className="form-input form-wide"
                                        value={formData.country_code}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleCountryChange(e);
                                        }}
                                        required
                                    >
                                        <option value="">{t('select_country')}</option>
                                        {Array.isArray(countries) &&
                                            countries.map((country) => (
                                                <option data-phone_code={country.phone_code} key={country.country_id} value={country.country_id}>
                                                    {country.country}
                                                </option>
                                            ))}
                                    </select>
                                    <label htmlFor="email_address">{t('email')}</label>
                                    <input
                                        name="email_address"
                                        className="form-input form-wide"
                                        placeholder={t('email')}
                                        value={formData.email_address}
                                        type="email"
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.email_address && <div className="colmd-12" style={{ color: 'red' }}>{errors.email_address}</div>}
                                    <label htmlFor="mobile_number">{t('contact_number')}</label>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td width={`20%`}>
                                                    <input
                                                        name="country_phone_code"
                                                        className="form-input form-wide"
                                                        placeholder={`000`}
                                                        type="number"
                                                        width={10}
                                                        length={4}
                                                        readOnly
                                                        value={countryPhoneCode}
                                                        required
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        name="mobile_number"
                                                        className="form-input form-wide"
                                                        placeholder={t('contact_number')}
                                                        type="number"
                                                        value={formData.mobile_number}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {errors.mobile_number && <div className="colmd-12" style={{ color: 'red' }}>{errors.mobile_number}</div>}
                                    <label htmlFor="password">{t('password')}</label>
                                    <input
                                        name="password"
                                        className="form-input form-wide"
                                        placeholder={t('password')}
                                        value={formData.password}
                                        type="password"
                                        onChange={handleChange}
                                        required
                                    />
                                    <label htmlFor="confirm_password">{t('confirm_password')}</label>
                                   
                                    <table className="mb-3">
                                    <tbody>
                                        <tr>
                                            <td width={`100%`}>
                                                <input type={SignupPasswordShow === 'far fa-eye'?'password':'text'} className="form-input form-wide mb-0" id="user_confirm_password" name="user_confirm_password" required value={formData.user_confirm_password} onChange={handleChange} />
                                            </td>
                                            <td className="pl-4 pr-4" style={{backgroundColor: '#e9e9e9'}} onClick={() => setSignupPasswordShow(SignupPasswordShow === 'far fa-eye'?'far fa-eye-slash':'far fa-eye')}>
                                                <i className={SignupPasswordShow} id="togglePassword" style={{cursor: 'pointer'}}></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <div className="form-footer mb-2">
                                        <button type="submit" className="btn btn-dark btn-md w-100 mr-0">
                                            {t('create_account')}
                                        </button>
                                    </div>
                                    {messages.error_message_for_SignUp && (
                                        <div id="error" className="alert alert-danger">
                                            {messages.error_message_for_SignUp}
                                        </div>
                                    )}
                                    {messages.success_message_for_SignUp && (
                                        <div id="success" className="alert alert-success">
                                            {messages.success_message_for_SignUp}
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default MobBasketLogin;
