import React, { useContext, useTransition } from "react";
import { NavLink } from 'react-router-dom';
import { ConfigContext } from "../../../Context/ConfigContext";
import {    useTranslation } from 'react-i18next';

const InfoBox = () => {
  const { t } = useTranslation();
  const { apiURL } = useContext(ConfigContext);
  const dir = localStorage.getItem('dir');
  
  return (
    <div className="container mt-3" dir={dir} style={{textAlign: dir === 'rtl'? 'right': 'unset'}}> 
      {/* Info Boxes */}
      <div className="row mb-2">
        <div className="col-md-4">
          <NavLink to={`/Delivery`}>
            <div className="info-box info-box-icon-left">
              <i className="icon-shipping" />
              <div className="info-box-content" style={{marginRight: dir === 'rtl'? '10px': 'unset'}}>
                <h4>{t("free_shipping_return")}</h4>
                <p className="text-body">{t("free_shipping_return.above")}</p>
              </div>
            </div>
          </NavLink>
        </div>
        <div className="col-md-4">
          <NavLink to={`/Cancelation`}>
            <div className="info-box info-box-icon-left">
              <i className="icon-money" />
              <div className="info-box-content" style={{marginRight: dir === 'rtl'? '10px': 'unset'}}>
                <h4>{t("money_back_guarantee")}</h4>
                <p className="text-body">{t("money_back_guarantee.100")}</p>
              </div>
            </div>
          </NavLink>
        </div>
        <div className="col-md-4">
          <NavLink to={`/International-Customers`}>
            <div className="info-box info-box-icon-left">
              <i className="icon-support" />
              <div className="info-box-content" style={{marginRight: dir === 'rtl'? '10px': 'unset'}}>
                <h4>{t("online_sppprt_247")}</h4>
                <p className="text-body">
                  {t("online_sppprt_247.everything_you_need_for_yourcar")}
                </p>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default InfoBox;
