import React from "react";
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
const CustomerSupport = () => {
  
    const { t } = useTranslation();;
    return(
      <section className="feature-boxes-container" style={{ backgroundColor: '#FFF', paddingTop:0}} >
        
      <div className="container" style={{borderTop: '1px solid #dedede', paddingTop:' 40px'}} data-animation-name="fadeInUpShorter" >
        <div className="row">
          <div className="col-md-4">
          <NavLink to={`/International-Customers`}>
            <div className="feature-box px-sm-5 feature-box-simple text-center">
              <div className="feature-box-icon">
                <i className="icon-earphones-alt" />
              </div>
              <div className="feature-box-content p-0">
                
                <h3 >{t('internation_customers_upport')}</h3>
                <h5>{t('international_customer_support.youwowntbealone')}</h5>
               
              </div>
              {/* End .feature-box-content */}
            </div>
            </NavLink>
            {/* End .feature-box */}
          </div>
          {/* End .col-md-4 */}
          <div className="col-md-4">
          <NavLink to={`/Safe-Shopping`}>
            <div className="feature-box px-sm-5 feature-box-simple text-center">
              <div className="feature-box-icon">
                <i className="icon-credit-card" />
              </div>
              <div className="feature-box-content p-0">
                <h3 >{t('safe_shopping')}</h3>
                <h5>{t('safe_shopping.fast_shipping')}</h5>
                
              </div>
              {/* End .feature-box-content */}
            </div>
            </NavLink>
            {/* End .feature-box */}
          </div>
          {/* End .col-md-4 */}
          <div className="col-md-4">
          <NavLink to={`/Delivery`}>
            <div className="feature-box px-sm-5 feature-box-simple text-center">
              <div className="feature-box-icon">
                <i className="icon-action-undo" />
              </div>
              <div className="feature-box-content p-0">
                <h3 >{t('return_shipment_policy')}</h3>
                <h5>{t('return_shipment_policy.made_to_help_you')}</h5>
                
              </div>
              {/* End .feature-box-content */}
            </div>
            </NavLink>
            {/* End .feature-box */}
          </div>
          {/* End .col-md-4 */}
        </div>
        {/* End .row */}
      </div>
      {/* End .container*/}
    </section>
    )
  
}

export default CustomerSupport