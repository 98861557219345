import axios from "axios";
import { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../Context/ConfigContext";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
const AddressCard = ({item, onDataChanged, backUrl, handleNavigation}) =>{
    const { apiURL, token } = useContext(ConfigContext);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dir = localStorage.getItem('dir');
    const SetDefaultAddress = async(address_id) => {
        setLoading(true);
        try {
          const customer_id = localStorage.getItem("customer_id");
          const headers = { token: token, Accept: "application/json", "Content-Type": "application/json", };
            var formData = {
                customer_id: customer_id, 
                address_id: address_id
    
            }
          const response =  await axios.post(
            `${apiURL}/Account/SetDefaultAddress`,formData, 
            { headers }
          );
          onDataChanged();
          setLoading(false);
          if(backUrl){
         
            window.location.href = backUrl ;
          }
          
        }catch(err){
            setLoading(false);
            console.log("Error While Fetching Customer Addresses", err);
        }
    }

    const DeleteAddress = async(address_id) => {
        setDeleting(true);
        try {
          const customer_id = localStorage.getItem("customer_id");
          const headers = { token: token, Accept: "application/json", "Content-Type": "application/json", };
            var formData = {
                customer_id: customer_id, 
                address_id: address_id
    
            }
          const response =  await axios.post(
            `${apiURL}/Account/DeleteAddress`,formData, 
            { headers }
          );
          onDataChanged();
          setDeleting(false);
          if(response.data.success){
            Swal.fire({
                title: 'Successfully',
                text: response.data.message,
                icon: 'success',
                confirmButtonText: 'Okay'
              })
          }else{
            Swal.fire({
                title: 'Error',
                text: response.data.message,
                icon: 'error',
                confirmButtonText: 'Okay'
              })
          }
          
          console.log(response.data)
        }catch(err){
            setDeleting(false);
            console.log("Error While Fetching Customer Addresses", err);
        }
    }
    var defText = "";
    return  <div className="card" dir={dir} style={{textAlign: dir==='rtl'?'right': ''}}>
                <div className="card-header">
                    
                    <div className="float-right">
                        {item.selected === 1?<span className="badge badge-success">{t("default")}</span>:<button onClick={() => SetDefaultAddress(item.address_id)} className="btn btn-sm btn-primary mx-1" title="Set As Default" disabled={item.selected === 1 || loading===true?true:false} > <i className="fa fa-check"></i> </button>}
                        {
                          handleNavigation ?<button onClick={() => handleNavigation(item.address_id)}  style={{ backgroundColor: "#cc000b", color: "white", }} className="btn btn-sm btn-primary mx-1" id="EditAddressBtn" title="Edit"  > <i className="fa fa-edit"></i> </button>:<NavLink to={`/MyAccount/EditAddress/${item.address_id}`}  style={{ backgroundColor: "#cc000b", color: "white", }} className="btn btn-sm btn-primary mx-1" id="EditAddressBtn" title="Edit"  > <i className="fa fa-edit"></i> </NavLink>
                        }
                        
                        <button  disabled={deleting===true?true:false} className="btn btn-sm btn-primary mx-1" onClick={() => DeleteAddress(item.address_id)} title="Remove"  > <i className="fa fa-trash"></i> </button>
                    </div>
                </div>
                <div className="card-body">
                    <div key={item.address_id}>
                    <div>
                        <strong>{t("name")}</strong>
                        <p> {item.first_name} {item.last_name} </p>
                    </div>
                    <div>
                    <strong>{t("address")}</strong>
                    <p>
                        
                        {item.complete_address},{" "}
                        {item.pincode && item.pincode !== '0' && item.pincode !== 0?item.pincode+',': ''}  <br /> 
                        {item.country}
                    </p>
                    </div>
                    <div>
                    <strong>{t("phone")}</strong>
                    <p>
                        {item.mobile_number_1}{" "}
                        {/* <span className="text-primary">
                        {t("verify_now")}
                        </span> */}
                    </p>
                    </div>
                </div>
            </div>
  </div>
}

export {AddressCard};