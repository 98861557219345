import { Component, useContext, useEffect, useState } from "react";
import { ConfigContext } from "../Context/ConfigContext";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import slugify from "slugify";
import { CreateTree } from "../Helper/Utility";
import MyGarage from "./MyGarage";
import MobileMyGarage from "./MobileMyGarage";

const MobileHeader = ({ mobileDrawerRef, closeDrawer }) => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      {/* <div className="mobile-menu-overlay" /> */}
      <div className="mobile-menu-container" ref={mobileDrawerRef} style={{ backgroundColor: '#f9fafb', maxWidth: '300px' }}>
        <div className="mobile-menu-wrapper">
          <span className="mobile-menu-close" onClick={() => closeDrawer()} style={{ color: '#184560' }}> <i className="fa fa-times" /> </span>

          <nav className="mobile-nav" style={{ color: '#184560' }}>
            <SidebarMenu closeDrawer={closeDrawer} />
          </nav>
          <div className="social-icons">
            <NavLink to={() => { }} className="social-icon social-facebook icon-facebook" target="_blank" rel="noreferrer" style={{ color: '#184560', backgroundColor: '#dddddd' }}></NavLink>
            <NavLink to={() => { }} className="social-icon social-twitter icon-twitter" target="_blank" rel="noreferrer" style={{ color: '#184560', backgroundColor: '#dddddd' }}></NavLink>
            <NavLink to={() => { }} className="social-icon social-instagram icon-instagram" target="_blank" rel="noreferrer" style={{ color: '#184560', backgroundColor: '#dddddd' }}></NavLink>
          </div>
        </div>
        {/* End .mobile-menu-wrapper */}
      </div>
      {/* End .mobile-menu-container */}

    </div>
  )
}


const SidebarMenu = ({ closeDrawer }) => {
  const { categories, CarStatus, gdcURL, setCategories, CartItemsQty, CartList, isLoggedIn, lang, setLang } = useContext(ConfigContext);
  const [navHistory, setNavHistory] = useState([null])
  const [mobCategories, setMobCategories] = useState([]);
  const { t, i18n } = useTranslation();
  const [myGarageStatus, setMyGarageStatus] = useState(false);
  function hasChildren(nodeId) {
    return categories.some(item => item.STR_ID_PARENT === nodeId);
  }

  // Adding STR_CHILDREN field to each object
  categories.forEach(item => {
    item.STR_CHILDREN = hasChildren(item.STR_ID);
  });

  return <div>
    { myGarageStatus && 
      <div style={{marginTop: '-40px'}}>
      <div style={{display: 'flex', alignItems: 'center',}}>
        <i onClick={() => setMyGarageStatus(false)} className="fas fa-arrow-left" style={{marginRight: '18px', marginLeft: '18px'}}></i>
        <h3 className="ml-4 mt-2" style={{fontSize: '17px', textAlign: 'center', width: 'center'}}>{t("my_cars")}</h3>
      </div>
      <MobileMyGarage closeDrawer={closeDrawer} />
    </div>
    }
     {/* <button type="button" onClick={() => closeDrawer()}>Close</button> */}
    <div style={{ marginLeft: '20px', color: '#184560' }}>{navHistory.length > 1 && <NavLink to="#" onClick={() => setNavHistory(navHistory.slice(1))}><i className="fas fa-chevron-left"></i> Back</NavLink>}</div>
    
    <ul className="mobile-menu" style={{ color: '#184560' }}>
      <li className="col-sm-4" style={{ borderBottom: '1px solid #e5e7eb' }}>
        <NavLink to={localStorage.getItem("customer_id") ? "/MyAccount" : "Signup"} style={{ color: '#184560', fontWeight: '500' }}>
          <i className="icon-user-2" style={{marginRight: '8px', color: '#184560', fontSize: 'large'}}></i>
          <span style={{marginLeft: '12px'}}>{localStorage.getItem("customer_id") ? t('my_account') : t('signin')}</span>
          <i className="fas fa-chevron-right" style={{float: 'right', marginTop: '4px', color: '#184560'}}></i>
        </NavLink>
      </li>
      <li onClick={() => setMyGarageStatus(true)} className="col-sm-4" style={{ borderBottom: '1px solid #e5e7eb' }}>
        <NavLink to={() => {}} style={{ color: '#184560', display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontWeight: '500' }}>
          <span  className="header-icon" title="Gatage" style={{ display: "flex", alignItems: "flex-start", marginRight: "20px", cursor: "pointer", color: '#184560' }}>
            <svg
              className="header-nav__icon base-svg mr-2"
              role="img"
              style={{
                width: "20px",
                height: "20px",
                fill: "#184560",
              }}>
              <use href="/assets/images/icon-sprite-bw.svg#sprite-garage-car-icon-bw"></use>
            </svg>
            <div style={{ color: "#184560", marginLeft: '12px' }}>
              <div style={{ fontSize: "small", fontWeight: "500" }}>
                {t("my_cars")}
              </div>
              <div style={{ fontSize: "smaller", marginTop: "-5px", color: '#6d6a6a' }}>
                {t("add_your_car")}
              </div>
            </div>
            
            <i className="fas fa-chevron-right" style={{right: 0, position: 'absolute', marginTop: '4px', color: '#184560', fontSize: 'small'}}></i>
          </span>
        </NavLink>
      </li>
      {
        categories.map((item, index) => {
          if (item.STR_ID_PARENT === navHistory[0]) {
            return <li onClick={() => {
              if (item.STR_CHILDREN === true) {
                setNavHistory([item.STR_ID, ...navHistory])
              }
            }
            } key={`mob-nav-${index}`} className="col-sm-4" style={{ borderBottom: '1px solid #e5e7eb' }}>
              <NavLink onClick={() => {
                if (item.STR_CHILDREN === false) { closeDrawer() }
              }} to={item.STR_CHILDREN === false ? `/Spare-Parts/${slugify(item.STR_NODE_NAME)}-${item.STR_ID}` : '#'} style={{ color: '#184560', display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontWeight: '500' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ 
                    width: '25px', 
                    height: '25px',
                    marginRight: '12px',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <img
                      alt={item.STR_NODE_NAME}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = "https://placehold.co/100x100/FFFFFF/FFFFFF/png";
                      }}
                      src={`${gdcURL}/category-images/600x600/${item.STR_ID}`}
                      style={{
                        width: '40px',
                        height: '40px',
                        objectFit: 'cover'
                      }}
                    />
                  </div>
                  {item.STR_NODE_NAME}
                </div>
                <i className="fas fa-chevron-right" style={{ color: '#184560' }}></i>
              </NavLink>
            </li>
          }
        })
      }
    </ul>
  </div>
}

const SidebarMenux = () => {
  const { categories, treeCategories, CarStatus, gdcURL, setCategories, CartItemsQty, CartList, isLoggedIn, lang, setLang } = useContext(ConfigContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dir = localStorage.getItem('dir');


  const GetSubMenu = (childrens) => {

    if (childrens.length > 0) {
      return <ul >
        {
          childrens.map((child, index) => {

            var url = child.children.length > 0 ? '#' : `/Spare-Parts/${slugify(child.STR_NODE_NAME)}-${child.STR_ID}`;
            return <li key={`${child.STR_ID}-${index}`}>
              <NavLink to={url}>{child.STR_NODE_NAME}</NavLink>
              {child.children.length > 0 ? GetSubMenu(child.children) : <></>}
            </li>
          })
        }
      </ul>
    }
    return <div></div>
  }
  return (
    <ul className="mobile-menu">
      {
        treeCategories.map((category, index) => {
          return <li key={`${category.STR_ID}-${index}`}>
            <NavLink to="#">{category.STR_NODE_NAME}</NavLink>
            {GetSubMenu(category.children)}
          </li>
        })
      }
    </ul>
  );
}


export { SidebarMenu };
export default MobileHeader;