import React, { useContext, useEffect, useRef, useState } from "react";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../Context/ConfigContext";
import { useParams } from "react-router-dom";

// import CarSearchBox from "../Cars/CarSeachBox";
import { Helmet } from "react-helmet";

import OtherParts from "./OtherParts";
import PopularSuppliers from "./PopularSuppliers";
import CarSelectionSection from "../Cars/CarSelectionSection";
import UniCarSearch from "../Cars/UniCarSearch";
import NoCarOtherParts from "./NoCarOtherParts";
import Sections from "./Catelog/Sections";
import Tabs from "./Catelog/Tabs";
import slugify from "slugify";
import RecentViews from "../Home/RecentViews";

const NoCarSelected = () => {
  const { currency } = useContext(ConfigContext);
  const location = useLocation();
  const { t } = useTranslation();
  const locale = localStorage.getItem("lang") ?? "en";
  const { gdcURL, makes, treeCategories } = useContext(ConfigContext);
  const dir = localStorage.getItem("dir");
  var { slug, make, model, series } = useParams();
  var STR_ID = slug.split("-").slice(-1);
  const [loading, setLoading] = useState(false);
  const [parts, setParts] = useState([]);

  const [selectedOE, setSelectedOE] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const [departmentName, setDepartmentName] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [catalogueLimit, setCatalogueLimit] = useState(6);
  const [catalogueLimit2, setCatalogueLimit2] = useState(5);
  const queryParams = new URLSearchParams(location.search);
  console.log({ slug, make, model, series })

  useState(() => {
    if (localStorage.getItem('pc_id') && localStorage.getItem('pc_id') != 0 && localStorage.getItem('pc_id') != null) {
      var nocar = window.location.href.replaceAll('Spare-Parts-nocar', 'Spare-Parts');
      window.location = nocar
    }
  }, [])
  const [limitCars, setLimitCars] = useState([80, 111, 5, 121, 16, 39, 819, 1526, 138, 184, 183, 842, 45, 36, 1820, 77]);
  const showMore = () => {
    setLimitCars(null);
  }

  const showLess = () => {
    setLimitCars([80, 111, 5, 121, 16, 39, 819, 1526, 138, 184, 183, 842, 45, 36, 1820, 77]);
  }


  useEffect(() => {
    setDepartmentName(slug.split("-").slice(0, -1).join(" "));
    const supplier = queryParams.get('supplier');
    if (supplier) {
      var sup_name = supplier.split('-');
      if (sup_name) {
        setSupplierName(sup_name[1])
      }
      console.log("supplierName", supplierName)
    }

  }, [])
  const [car_info, setCarInfo] = useState(
    localStorage.getItem("car_info")
      ? JSON.parse(localStorage.getItem("car_info"))
      : []
  );
  const [loadMore, setLoadMore] = useState(false);
  const loadMoreRef = useRef(null);

  const navigate = useNavigate();
  const DepartmentDepartment = (item) => {
    return <div style={{ cursor: 'pointer' }} key={item.STR_ID} className="col-md-4" onClick={() => { navigate(`/Spare-Parts/${slugify(item.STR_NODE_NAME)}-${item.STR_ID}`); window.scrollTo(0, 0) }}>
                  <div className="d-flex align-items-center  rounded hover-shadow">
                    <div style={{
                      width: '120px',
                      height: '80px',
                      overflow: 'hidden',
                      position: 'relative'
                    }}>
                      <img
                        src={`${gdcURL}/category-images/600x600/${item.STR_ID}`}
                        className="img-fluid"
                        alt={item.STR_NODE_NAME}
                        style={{
                          position: 'absolute',
                          top: '10px',
                          width: 'calc(50% + 50px)',
                          height: 'calc(50% + 50px)',
                          objectFit: 'cover'
                        }}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = "https://placehold.co/100x100/FFFFFF/FFFFFF/png";
                        }}
                      />
                    </div>
                    <h6 className="mt-4">{item.STR_NODE_NAME}</h6>
                  </div>
                </div>
  }
  return (
    <main style={{ marginTop: '-10px' }} className="main" dir={dir}>

      <Helmet>
        <title>{departmentName}</title>
        <meta name="keywords" content={localStorage.getItem("pc_id") && loading === false &&

          parts && parts.length > 0 && ((selectedBrands.length !== 0 && selectedOE === true) ||
            selectedOE !== true) &&
          parts.map((part, index) => {
            return `${part.PRODUCT_GROUP} - ${part.ART_ARTICLE_NR} - ${part.ART_SUP_BRAND}`
          })
        } />
      </Helmet>

      <div className="row justify-content-center pl-2" style={{ backgroundColor: 'rgb(238, 238, 238)' }}>
        <div className="col-md-8">
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to={"/"}>
                  <i className="icon-home" />
                </NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink to={`/Spare-Parts/Catelog`}>{t("catelog")}</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {departmentName}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="row justify-content-center pl-2" style={{ backgroundColor: 'rgb(238, 238, 238)' }}>
        <div className="col-md-8 mt-2">
          <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
            {t('for_my_car', { value: departmentName })}
          </h2>
        </div>
      </div>
      <div className="row justify-content-center pl-2" style={{ backgroundColor: 'rgb(238, 238, 238)' }}>
        <div className="col-md-12" >
          <div className="row justify-content-center pt-0" >
            <div className="col-md-3" >
              <div style={{ width: '400px' }}>
              {/* <UniCarSearch defaultMake={make && make} defaultModel={model && model.split('-')[1]} defaultMSID={model && model.split('-')[0]} redirectStatus={true} /> */}
                <UniCarSearch defaultMake={make && make.split('-')[1]} defaultModel={model && model.split('-')[1]} defaultMSID={model && model.split('-')[0]} redirectStatus={true} />
              </div>
            </div>
            <div className="col-md-5" >
              <center style={{ marginRight: '-100px' }}>
                <br /> <br />
                <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                  {t('where_to_buy_at_good', { value: departmentName })}
                </h2>
                <div style={{
                  width: '400px',
                  height: '250px',
                  overflow: 'hidden',
                  position: 'relative'
                }}>
                  <img
                    src={`${gdcURL}/category-images/600x600/${STR_ID}`}
                    className="img-fluid"
                    alt={departmentName}
                    style={{
                      position: 'absolute',
                      top: '-36px',
                      width: 'calc(100% + 100px)',
                      height: 'calc(100% + 100px)',
                      objectFit: 'cover'
                    }}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://placehold.co/100x100/FFFFFF/FFFFFF/png";
                    }}
                  />
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center pl-2" >

        {STR_ID &&
          <NoCarOtherParts STR_ID={STR_ID} STR_NODE_NAME={departmentName} />
        }
       { !make && !model && <div className="col-md-8 mt-4">
          <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
            {t('department_auto_parts_for_all_top_car_brands', { department: departmentName })}
          </h2>
          {
            !make && <div className="row justify-content-center mt-2" >
              {
                makes && makes.length > 0 && makes.map((make) => {
                  console.log("makemake", make)
                  if (limitCars === null || limitCars.includes(make.MFA_ID)) {
                    return (
                      <div
                        onClick={() => navigate(`/Spare-Parts/${slug}/${make.MFA_ID}-${make.label}`)}
                        style={{ cursor: 'pointer', width: '12.5%' }} className='' key={make.MFA_ID}>
                        <div style={{ height: '70px', backgroundColor: '#FFF', textAlign: 'center', marginLeft: '10px', marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #CCC', borderRadius: '5px' }}>
                          <NavLink to={`/Spare-Parts/${slug}/${make.MFA_ID}-${make.label}`} className={`nav-link-custom`}>{make.MFA_BRAND}</NavLink>
                        </div>
                      </div>
                    )
                  }
                })
              }
            </div>
          }
          <center className="mt-3">

            <NavLink onClick={() => {
              if (limitCars) {
                showMore();
              } else {
                showLess();
              }
            }}>{limitCars ? t('show_more') : t('show_less')} <i className={`fas fa-chevron-${!limitCars ? 'up' : 'down'} ml-2`}></i></NavLink>
          </center>
        </div>}
        {/* <div className="col-md-8 mt-3 mb-5">
            <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                {t('best_brands_original', {value: departmentName})}
            </h2>
            <PopularSuppliers />
        </div> */}

        <div className="col-md-12 mt-3 mb-5">
          <CarSelectionSection brand={supplierName} department={departmentName} model={model} sries={series} make={make ? make : '5-AUDI'} base_url={`/Spare-Parts/${slug}`} />
        </div>



        <div className="col-md-8 mt-3 mb-5">
          <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
            {t('car_parts_catalogue', { department: departmentName })}
          </h2>
          <div className="row">
            {
              treeCategories && treeCategories.length > 0 && treeCategories.slice(0, catalogueLimit).map((item) => {
                return <div className="col-lg-2 text-center sectiontab" style={{ width: '200px', textAlign: 'center', zIndex: 90 }} key={`section-tabs-${item.STR_ID}-${Math.random() * 1000}`} id={`sec-${item.STR_ID}`}>

                  <NavLink onClick={() => navigate(`/Spare-Parts/${slugify(item.STR_NODE_NAME.replaceAll('/', '-'))}-${item.STR_ID}`)}>
                    <div className="" style={{ height: '200px', width: '200px', }}>
                      <img
                        alt={item.STR_NODE_NAME}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // Prevents infinite loop in case fallback also fails
                          currentTarget.src = "https://placehold.co/100x100/FFFFFF/FFFFFF/png";
                        }}
                        src={`${gdcURL}/category-images/600x600/${item.STR_ID}`} style={{ margin: 'auto', width: '400px' }} />
                    </div>
                    <div className="card-text text-center pl-2 pr-2 mb-1" style={{ height: '70px' }}>
                      <span style={{ fontFamily: 'Montserrat,Arial,sans-serif', fontSize: '17px', fontWeight: 500, color: '#032f4a' }}>{item.STR_NODE_NAME}  </span>
                    </div>
                  </NavLink>
                </div>
              })
            }
          </div>

          <center className="mt-3">
            <NavLink onClick={() => { catalogueLimit === 6 ? setCatalogueLimit(10000) : setCatalogueLimit(6) }}>{catalogueLimit === 6 ? t('show_more') : t('show_less')} <i className={`fas fa-chevron-${catalogueLimit !== 6 ? 'up' : 'down'} ml-2`}></i></NavLink>
          </center>
        </div>

        <div className="col-md-8 mt-3 mb-5">
          <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
            {t('find_cheap_deals_for', { department: departmentName })}
          </h2>
          <div className="row">
            {
              treeCategories && treeCategories.length > 0 && treeCategories.filter(item => item.STR_ID === 100006)[0].children.slice(0, catalogueLimit2).map((item) => { 

                if(item.children.length > 0 ){
                  return item.children.map((child) => {
                    return DepartmentDepartment(child);
                  })
                }else{
                  return DepartmentDepartment(item);
                }

              })
            }
          </div>
          <center className="mt-3">
            <NavLink onClick={() => { catalogueLimit2 === 5 ? setCatalogueLimit2(10000) : setCatalogueLimit2(6) }}>{catalogueLimit2 === 5 ? t('show_more') : t('show_less')} <i className={`fas fa-chevron-${catalogueLimit2 !== 5 ? 'up' : 'down'} ml-2`}></i></NavLink>
          </center>
        </div>
        
      </div>
      <div className="col-md-12 mt-3 mb-5">
          <RecentViews />
        </div>
    </main>
  );
};
const ImageWithFallback = ({ src, index, alt, fallbackText, OE }) => {
  const [imgError, setImgError] = useState(false);

  const handleError = () => {
    setImgError(true);
  };
  const fallbackStyle = {
    textAlign: "center",
    fontSize: "10px",
    padding: "10px",
    wordBreak: "break-word",
    maxWidth: "90%",
  };

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <div style={containerStyle}>
      {imgError ? (
        <div style={fallbackStyle}>{fallbackText}</div>
      ) : (
        <img
          draggable="false"
          style={{
            height: "40px",
            margin: "auto",
            float: "none",
            marginTop: OE === false ? "5px" : 0,
          }}
          key={`img-part-${index}`}
          src={src}
          alt={alt}
          onError={handleError}
        />
      )}
    </div>
  );
};
export default NoCarSelected;
