import React, { useState, useEffect, useContext } from "react";
import { ConfigContext } from "../../../Context/ConfigContext";
import axios from "axios";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { AddressCard } from "../../../Components/Cards/MyAccountComponents";
import { useTranslation } from 'react-i18next';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Autocomplete from "react-google-autocomplete";
import Swal from "sweetalert2";
const BasketAddress = () => {
  const { apiURL, token } = useContext(ConfigContext);
  const [customersAddresses, setCustomersAddresses] = useState([]);
  const [countries, setCountries] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    GetAddresses();
    GetCountries();
  }, []);

  const GetCountries = async () => {
    try {
      const response = await axios.get(`${apiURL}/Home/GetCountries`);
      const data = response.data;
      if (data) {
        setCountries(data.data);
      } else {
        console.log("No Countries Found", response.data.message);
      }
    } catch (err) {
      console.log("Something Went Wrong", err);
    }
  };
  const search = useLocation().search;
  const backUrl = new URLSearchParams(search).get('backUrl');
  const GetAddresses = async () => {
    try {
      const customer_id = localStorage.getItem("customer_id");
      const headers = {
        token: token,
        Accept: "application/json",
        "Content-Type": "application/json"
      };

      const response = await axios.get(
        `${apiURL}/Account/GetAddresses?customer_id=${customer_id}`,
        { headers }
      );

      const data = response.data;

      if (data.success) {
        setCustomersAddresses(data.data);
      } else {
        console.log("Error While Fetching Customer Addresses", data.message);
      }
    } catch (error) {
      console.log("Oops Something Went Wrong", error);
    }
  };

  const dir = localStorage.getItem('dir');
  const [formEnable, setFormEnable] = useState(-1);
  const handleNavigationState = async (address_id) => {
   
    setFormEnable(address_id);

  }

  return formEnable >= 0 ? <AddEditAddress handleNavigationState={handleNavigationState} countries={countries} address_id={formEnable} /> : (
    <main dir={dir} style={{ textAlign: dir === 'rtl' ? 'right' : '' }}>

      <div className="container account-container custom-account-container">
        <div className="row justify-content-center">

          <div className={`col-lg-9 order-lg-last order-1 tab-content ${dir === 'rtl' ? 'text-right' : ''}`}>
            <div className="tab-pane fade show active" id="address" role="tabpanel">
              <div className="addresses-content">
                <div className="address">
                  <div className="card">
                    <div className="card-body">
                      <p className="card-text">
                        {t("manage_address_line")}
                      </p>
                      <button
                        onClick={() => handleNavigationState(0)}
                        className="btn btn-default link-to-tab"
                        style={{ textDecoration: "none", background: '#cc000b', color: "white" }}
                      >
                        <i className="sicon-plus align-middle mr-3"></i> {t("add_new_address")}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="address">
                  <div className="card">
                    <div className="card-header">
                      <i className="sicon-location-pin align-middle mr-3" /> {t("other_address")}
                    </div>
                    <div className="card-body" style={{ textAlign: 'left' }}>
                      <div className="container-fluid">
                        <div className="row">
                          {customersAddresses.map((item) => (
                            <div className="col-sm-4" key={`address-key-${item.address_id}`}>
                              <AddressCard handleNavigation={handleNavigationState} backUrl={'payment'} editUrl={'address'} onDataChanged={GetAddresses} item={item} />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <center> <button onClick={() => window.location = '/basket/payment'} className="btn btn-primary btn-lg" style={{ width: '300px' }}>{t('next')}</button></center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

const AddEditAddress = ({ address_id, countries, handleNavigationState }) => {
  const { apiURL, token, gdcURL } = useContext(ConfigContext);
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [country, setCountry] = useState("");
  const [completeAddress, setCompleteAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [mobileNumber1, setMobileNumber1] = useState("");
  const [mobileNumber2, setMobileNumber2] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [selected, setSelected] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [CountryAlphaCode, setCountryAlphaCode] = useState("");
  const [countryId, setCountryId] = useState(0);
  const [addressLat, setAddressLat] = useState("");
  const [addressLng, setAddressLng] = useState("");
  const [addressId, setAddressId] = useState("");

 
  const dir = localStorage.getItem('dir');
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); 
  const navigate = useNavigate();
  useEffect(() => {
     

    if (address_id) {
      GetAddresseInfo();
    }
  }, [])


  

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
    marginBottom: '20px'
  };
  const [mapCenter, setMapCenter] = useState({}); // Dubai coordinates
  const [libraries] = useState(['places']); // Add this

  // Update the handlePlaceSelected function
  const handlePlaceSelected = (place) => {
    console.log("clickbite")
    if (place.geometry) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setAddressLat(lat);
      setAddressLng(lng);
      setCompleteAddress(place.formatted_address);
      geodeco(lat, lng);
    }

  };
  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    // Check if google is available
    if (!window.google || !window.google.maps) {
      console.error("Google Maps API not loaded");
      return;
    }

    geodeco(lat, lng);

  };

  const geodeco = (lat, lng) => {
    setAddressLat(lat);
    setAddressLng(lng);
    setMapCenter({ lat, lng });
    setPincode('');
    setCity('');
    setState('');
    setCountry('');
    setCountryId('');
    setCountryCode('');
    setCountryAlphaCode('');
    
    try {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results[0]) {
          setCompleteAddress(results[0].formatted_address);
          for (var i = 0; i < results.length; i++) {
            const componentType = results[i].types[0];
            // console.log("componentType", componentType);
            var component = results[i].address_components[0]
            if (componentType === 'country') {
              const selectedCountry = countries.find(c => c.country_alpha1_code.toLowerCase() === component.short_name.toLowerCase());
              setCountry(selectedCountry.country);
              setCountryId(selectedCountry.country_id);
              setCountryCode(selectedCountry.phone_code); 
              setCountryAlphaCode(component.short_name); 
            }

            if (componentType === 'postal_code') {
              // console.log("componentType", i, component.long_name)
              setPincode(component.long_name);
            }

            if (componentType === 'locality') {
              setCity(component.long_name);
              // console.log("componentType", i, component.long_name)
            }


            if (componentType === 'administrative_area_level_1') {
              setState(component.long_name);
              // console.log("componentType", i, component.long_name) 
            }


          }

        }
      });
    } catch (err) {
      console("errorx", err)
    }
  }
  const searchBoxStyle = {
    position: 'absolute',
    top: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80%',
    maxWidth: '400px',
    zIndex: 1
  };

  const GetAddresseInfo = async () => {
    try {
      const customer_id = localStorage.getItem("customer_id");
      const headers = {
        token: token,
        Accept: "application/json",
        "Content-Type": "application/json"
      };

      const response = await axios.get(
        `${apiURL}/Account/GetAddresses?customer_id=${customer_id}&address_id=${address_id}`,
        { headers }
      );

      const data = response.data;

      if (data.success) {
        var row = data.data[0];

        setFirstName(row.first_name);
        setLastName(row.last_name);
        setCompanyName(row.company_name);
        setCountry(row.country);
        setCompleteAddress(row.complete_address);
        setApartment(row.apartment);
        setCity(row.city);
        setState(row.state);
        setPincode(row.pincode);
        setMobileNumber1(row.mobile_number_1);
        setMobileNumber2(row.mobile_number_2);
        setContactEmail(row.contact_email);
        setSelected(row.selected === 1);
        setCountryCode(row.country_code);
        setCountryId(row.country_id);
        setCountryAlphaCode(row.country_alpha1_code);
        
        setAddressLat(parseFloat(row.address_lat));
        setAddressLng(parseFloat(row.address_lng));
        setAddressId(row.address_id);
        setMapCenter({ lat: parseFloat(row.address_lat), lng: parseFloat(row.address_lng) });
      } else {
        console.log("Error While Fetching Customer Addresses", data.message);
      }
    } catch (error) {
      console.log("Oops Something Went Wrong", error);
    }
  };
  const AddUpdateCustomerAddress = async (e) => {
    setSaving(true);

    // Remove any existing error highlights
    document.querySelectorAll('.form-control.error').forEach(el => {
      el.classList.remove('error');
    });

    // Validation for required fields
    const requiredFields = [
      { field: firstName, name: 'firstName', message: t('enter_first_name') },
      { field: lastName, name: 'lastName', message: t('enter_last_name') },
      { field: completeAddress, name: 'completeAddress', message: t('select_address_on_map') },
      { field: city, name: 'city', message: t('enter_city') },
      { field: state, name: 'state', message: t('enter_state') },
      { field: mobileNumber1, name: 'mobileNumber1', message: t('enter_phone_number') },
      { field: contactEmail, name: 'contactEmail', message: t('enter_email') }
    ];

    for (const { field, name, message } of requiredFields) {
      if (!field || field.trim() === '') {
        const inputElement = document.querySelector(`[name="${name}"]`);
        if (inputElement) {
          // Add error class
          inputElement.classList.add('error');

          // Scroll to the element
          inputElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });

          // Focus on the element
          inputElement.focus();
        }

        Swal.fire({
          title: t('error'),
          text: message,
          icon: "error",
          confirmButtonText: "Okay",
        });
        setSaving(false);
        return false;
      }
    }
    if((!pincode || pincode===0 || pincode === null) &&  countryId !== 1){
      Swal.fire({
        title: t('error'),
        text: t('invalid_address'), // Use detail if available
        icon: "error",
        confirmButtonText: "Okay",
      });
      setSaving(false);
      return false;
    }
    // Validate address using DHL API
    const validateAddress = async () => {
      const response = await axios.get(`${gdcURL}/Orders//ValidateAddress`, {
        headers: {
          accept: "application/json",
          "Message-Reference": "d0e7832e-5c98-11ea-bc55-0242ac13",
          "Authorization": "Basic YXBaM3pUOXhFMWpVM2U6SkA3akFeNmRNJDBhWV41ag==",
        },
        params: {
          country: country,
          countryCode: CountryAlphaCode,
          pincode: pincode,
          city: city,
          city: city
        }
      });
      return response.data; // Return the validation response
    };

    // Call the address validation function
    const validationResponse = await validateAddress();
    console.log("validationResponse", validationResponse);
    // Check for invalid address response
    if (validationResponse.success === false) {
      Swal.fire({
        title: t('error'),
        text: validationResponse.detail || t('invalid_address'), // Use detail if available
        icon: "error",
        confirmButtonText: "Okay",
      });
      setSaving(false);
      return false;
    }

    try {
      const headers = {
        token: token,
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const addressData = {
        customer_id: localStorage.getItem("customer_id"),
        first_name: firstName,
        last_name: lastName,
        company_name: companyName,
        country: country,
        complete_address: completeAddress,
        apartment: apartment,
        city: city,
        state: state,
        pincode: pincode,
        mobile_number_1: mobileNumber1,
        mobile_number_2: mobileNumber2,
        contact_email: contactEmail,
        selected: selected ? 1 : 0,
        country_code: countryCode,
        country_id: countryId,
        address_lat: addressLat,
        address_lng: addressLng,
      };
      if (address_id) {
        addressData.address_id = address_id;
      }
      const response = await axios.post(
        `${apiURL}/Account/AddAddress`,
        addressData,
        { headers }
      );

      if (response.data.success) {
        Swal.fire({
          title: "Successfully",
          text: response.data.message,
          icon: "success",
          confirmButtonText: "Okay",
        });
        if (!addressId) {
          resetFields();
        }
        // window.location = '/basket/address';
      } else {
        Swal.fire({
          title: "Error",
          text: response.data.message,
          icon: "error",
          confirmButtonText: "Okay",
        });
      }
      setSaving(false);
    } catch (error) {
      setSaving(false);
      setErrorMessage("An error occurred Adding/Updating Address.");
    }
  };


  const resetFields = () => {
    setFirstName("");
    setLastName("");
    setCompanyName("");
    setCountry("");
    setCompleteAddress("");
    setApartment("");
    setCity("");
    setState("");
    setPincode("");
    setMobileNumber1("");
    setMobileNumber2("");
    setContactEmail("");
    setSelected(false);
    setCountryCode("");
    setCountryId(0);
    setAddressLat("");
    setAddressLng("");
    setAddressId("");
  };
  useEffect(() => {
    console.log("mapCenter", mapCenter)
  }, [mapCenter])
  return <div className="card mt-3">
    <div className="card-header" style={{textAlign: dir === 'rtl'? 'right': 'left'}}>
      <button className="btn btn-link" onClick={() => handleNavigationState(-1)}><i className={`fas ${dir === 'rtl'? 'fa-arrow-right': 'fa-arrow-left'}`}></i> {" "} {t('back_to_address')}</button>
    </div>
    <div className="card-body" style={{ textAlign: 'left' }}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {t("first_name")} <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                name="firstName"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {t("last_name")} <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                name="lastName"
              />
            </div>
          </div>

        </div>
        <div className="col-lg-12" dir={dir}>
          <div className="d-flex align-items-center p-5 mb-3"
            style={{
              background: '#f8f9fa',
              border: '1px dashed #dee2e6',
              borderRadius: '8px'
            }}>
            <div className={dir === "rtl" ? "ml-5" : "mr-5"}>
              <i className="fas fa-map-marker-alt"
                style={{
                  fontSize: '34px',
                  color: '#cc000b'
                }}></i>
            </div>
            <div className={dir === "rtl" ? "ml-2" : "mr-2"} style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }}>
              <h6 className="mb-1" style={{ fontWeight: 'bold', lineHeight: 0 }}>
                {t("pin_your_location")}
              </h6>
              <p className="mb-0" style={{ color: '#6c757d' }}>
                {t("drop_pin_or_search_address")}
              </p>
            </div>
          </div>

          <LoadScript
            googleMapsApiKey="AIzaSyCnDyGNIfJgUAA3evE-n805f1u16Doq6Qs"
            libraries={['places', 'geocoder']}
          >
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={mapCenter}
              zoom={15}
              onClick={handleMapClick}
            >
              <div style={searchBoxStyle}>
                <Autocomplete
                  className="form-control"
                  onPlaceSelected={handlePlaceSelected}
                  placeholder={t("search_location")}
                  options={{
                    types: ['address'],
                    componentRestrictions: { country: CountryAlphaCode }
                  }}
                  style={{ width: '100%', height: '40px', paddingLeft: '16px' }}
                />
              </div>
              <Marker position={mapCenter} />
              <i
                className="fas fa-crosshairs"
                style={{
                  position: "absolute",
                  top: "150px",
                  right: "10px",
                  fontSize: "24px",
                  color: "#333",
                  cursor: "pointer",
                  zIndex: 1000,
                  padding: "8px",
                  backgroundColor: "white",
                  borderRadius: "4px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
                }}
                onClick={() => {
                  if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                      (position) => {
                        const currentLocation = {
                          lat: position.coords.latitude,
                          lng: position.coords.longitude
                        };
                        setMapCenter(currentLocation);

                        const geocoder = new window.google.maps.Geocoder();
                        geocoder.geocode({ location: currentLocation }, (results, status) => {
                          if (status === "OK" && results[0]) {
                            setCompleteAddress(results[0].formatted_address);
                            handlePlaceSelected(results[0]);
                          }
                        });
                      },
                      (error) => {
                        console.log("Error getting location:", error);
                      }
                    );
                  }
                }}
              />
            </GoogleMap>
          </LoadScript>
        </div>

      </div>
      <div className="col-lg-12">
        <div className="selected-address-container p-4 mt-4"
          style={{
            background: '#fff',
            borderRadius: '10px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            border: '1px solid #eee'
          }}>
          {completeAddress ? (
            <>
              <div className="d-flex align-items-center mb-3">
                <div className="location-dot"
                  style={{
                    width: '12px',
                    height: '12px',
                    background: '#cc000b',
                    borderRadius: '50%',
                    marginRight: '10px'
                  }} />
                <h6 className="mb-0" style={{ fontWeight: '600' }}>
                  {t("selected_location")}
                </h6>
              </div>

              <div className="address-details"
                style={{
                  paddingLeft: '22px',
                  borderLeft: '1px dashed #cc000b',
                  marginLeft: '5px'
                }}>
                <p className="mb-2" style={{ fontSize: '15px', color: '#333' }}>
                  {completeAddress}
                </p>
                <div className="d-flex flex-wrap">
                  {pincode && (
                    <span className="address-tag mr-2 mb-2"
                      style={{
                        background: '#f8f9fa',
                        padding: '4px 12px',
                        borderRadius: '20px',
                        fontSize: '13px',
                        color: '#666'
                      }}>
                      <i className="fas fa-map-pin mr-1" style={{ color: '#cc000b' }}></i>
                      {pincode || t("not_applicable")}
                    </span>
                  )}
                  {city && (
                    <span className="address-tag mr-2 mb-2"
                      style={{
                        background: '#f8f9fa',
                        padding: '4px 12px',
                        borderRadius: '20px',
                        fontSize: '13px',
                        color: '#666'
                      }}>
                      <i className="fas fa-city mr-1" style={{ color: '#cc000b' }}></i>
                      {city}
                    </span>
                  )}
                  {country ? (
                    <span className="address-tag mb-2"
                      style={{
                        background: '#f8f9fa',
                        padding: '4px 12px',
                        borderRadius: '20px',
                        fontSize: '13px',
                        color: '#666'
                      }}>
                      <i className="fas fa-globe mr-1" style={{ color: '#cc000b' }}></i>
                      {country}
                    </span>
                  ) : (
                    <span className="address-tag mb-2"
                      style={{
                        background: '#f8f9fa',
                        padding: '4px 12px',
                        borderRadius: '20px',
                        fontSize: '13px',
                        color: '#666'
                      }}>
                      <i className="fas fa-globe mr-1" style={{ color: '#cc000b' }}></i>
                      {t("not_applicable")}
                    </span>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="text-center text-muted p-3">
              <i className="fas fa-map-marker-alt mb-2" style={{ fontSize: '24px', color: '#cc000b' }}></i>
              <p className="mb-0">{t("no_address_selected")}</p>
            </div>
          )}
        </div>
      </div>
      <div className="row p-3 mt-3">
        <div className="col-md-6">
          <div className="form-group mb-3">
            <label>
              {t("phone")} <span className="required">*</span>
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text pl-4 pr-4" style={{ background: '#f8f9fa', borderRight: 'none' }}>
                  +{countryCode}
                </span>
              </div>
              <input
                type="number"
                className="form-control"
                value={mobileNumber1}
                onChange={(e) => setMobileNumber1(e.target.value)}
                required
                style={{ borderLeft: 'none' }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group mb-3">
            <label>
              {t("email")} <span className="required">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
              required
            />
          </div>
        </div>
      </div>
    </div>
    <div className="card-footer">
      <center> <button onClick={() => AddUpdateCustomerAddress()} className="btn btn-primary btn-lg" style={{ width: '300px' }}>{t('save')}</button></center>
    </div>
  </div>
}
export default BasketAddress;
