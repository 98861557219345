import React, { Component, useContext } from "react";
import Slider from "./Slider";
import FeaturedProducts from "../../Components/Home/FeaturedProducts/FeaturedProducts";
import InfoBox from "../../Components/Home/InfoBox/InfoBox";
import NewArrivals from "../../Components/Home/NewArrivals/NewArrivals";
import Promotion from "../../Components/Home/Promotions/Promotion";
import CustomerSupport from "../../Components/Home/CustomerSupport/CustomerSupport";
import LatestNews from "../../Components/Home/LatestNews/LatestNews";
import SelectedCarView from "../../Components/Home/SelectedCarView";
import { ConfigContext } from "../../Context/ConfigContext";
import CarSelectionOption from "../../Components/CarSelectionOption";
import Offers from "./Offers";

import Suppliers from "./Suppliers";
import Makes from "./Makes";
import RecentViews from "./RecentViews";
import Offer4x4Section from "./4x4Section";
import Sections from "../SpareParts/Catelog/Sections";
import useIsMobile from "../../Helper/useIsMobile";
import MobSection from "../SpareParts/Catelog/MobSection";
import { Helmet } from "react-helmet";
import SocialMediaFooter from "./SocialMediaFooter";

// import Slider from "./Slider";
// import BelowSliderHighlights from "./BelowSliderHighlights";

const Home = () => {
    const {CarStatus, resizeState } = useContext(ConfigContext);
    const isMobile = useIsMobile();
    return (
        <div>
            <Helmet>
                <title>Ghayar Spare Parts - Your Trusted Auto Parts Supplier in UAE</title>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name="csrf-token" content="VeV893Poy3i5QHyNChCCGZVxaFTw34sv7Rd61d3X" />
                <meta name="robots" content="index, follow" />
                <meta name="description" content="Ghayar LLC - Leading supplier of genuine car parts in UAE. Fast delivery across Dubai, Abu Dhabi & KSA. Shop now for quality spare parts with flexible returns." />
                <meta name="keywords" content="car parts UAE, spare parts Dubai, auto parts Abu Dhabi, vehicle parts KSA, genuine car parts, auto components UAE, car maintenance parts, car repair parts" />
                <meta property="og:image" content="https://ghayar.com/assets/images/Logo/car7_transparent_powered_by.png" />
                <meta property="og:title" content="Ghayar LLC - Your Trusted Auto Parts Supplier in UAE" />
                <meta property="og:description" content="Ghayar LLC - Leading supplier of genuine car parts in UAE. Fast delivery across Dubai, Abu Dhabi & KSA. Shop now for quality spare parts with flexible returns." />
                <meta property="og:url" content="https://ghayar.com/" />
                <meta name="google-site-verification" content="sqbHPE0JL6KdK0P-oI0unVHXTA2jxGM9cebO3qfkyqE" />
                <meta property="twitter:title" content="Ghayar LLC - Your Trusted Auto Parts Supplier in UAE" />
                <meta property="twitter:description" content="Ghayar LLC - Leading supplier of genuine car parts in UAE. Fast delivery across Dubai, Abu Dhabi & KSA. Shop now for quality spare parts with flexible returns." />
                <meta property="twitter:url" content="https://ghayar.com/" />
                <link rel="canonical" href="https://ghayar.com/" />
            </Helmet>
            {/* {CarStatus === false && isMobile ? <CarSelectionOption></CarSelectionOption> : <div></div>}
            {CarStatus === true && isMobile ? <SelectedCarView /> : <div></div>}  */}
            
            <Slider />
        {window.innerWidth > 768  && <InfoBox />}
            
            <RecentViews />
            
            {/* <Offer4x4Section /> */}
            {isMobile ? <MobSection/>: <Sections from={true} />}
            <section className="f" >
                <Makes />
            </section>
            
            {window.innerWidth > 768  && <CustomerSupport />}

            
            <Offers /> 

            <Suppliers /> 
            
            <SocialMediaFooter />
        
            {/* <BelowSliderHighlights /> */}
        </div>
    );
    
}
export default Home;