import React, { Component } from 'react'
import  { NavLink } from 'react-router-dom'
import { ConfigContext } from "../Context/ConfigContext";
import axios from 'axios';
import { CartProcess, DeleteCartItem, GetCarts } from '../Helper/Carts';
import { withTranslation } from 'react-i18next';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
class MobileCartSideBar extends Component{
  static contextType = ConfigContext 

    componentDidMount(){
        this.LoadCart();
    }
      LoadCart = async () => {
        const { apiURL, UpdateCartList } = this.context;
          // const { coupon } = this.state;
          
          try {
            var carts = GetCarts();
            if(carts && Array.isArray(carts) && carts.length > 0){
              const response = await axios.get(
                `${apiURL}/Carts/CheckoutPreview`,{
                  params: {
                    lang: localStorage.getItem('lang')??'en',
                    currency: localStorage.getItem('currency')??'aed',
                    customer_id: localStorage.getItem('customer_id') || 1,
                    carts: JSON.stringify(carts),
                  }
                }
              );
              
              const data = response.data.data;
              CartProcess(data.items);
              UpdateCartList(data);
            }
          } catch (err) {
            console.log("LoadCart err", err);
          }
      };
    
    handleDeleteCartItem = (index) => {
      const { UpdateCartList } = this.context;
      DeleteCartItem(index);
      UpdateCartList(GetCarts());
      this.LoadCart();
    }
    render(){
      const { t } = this.props;
      const { apiURL, CartList, CartProcessStatus, ChangeCartProcessStatus, SideCartStatus, ChangeSideCartStatus } = this.context;
      let cart = CartList && CartList.items && Array.isArray(CartList.items) &&  CartList.items.length > 0;
    
      if(CartProcessStatus === true){
        
        ChangeCartProcessStatus(false);
        if(GetCarts() !== '' && GetCarts().length !==0){
          this.LoadCart();
        }
        
      }
     
      
        return (
            <>
            
            {cart 
              ? 
              (
                <div className="dropdown-menu  mobile-cart show" style={SideCartStatus === true?{transform: 'none'}: {}}>
                
                <NavLink to="#" title="Close (Esc)"  style={{color: '#000 !important',    position: 'absolute',left: '-4.2rem',top: '0.7rem',fontSize: '3.3rem',fontWeight: 300}}><i style={{color: '#000 !important'}} onClick={() => ChangeSideCartStatus(false)} className='icon-arrow-forward-right'></i></NavLink>
                <div className="dropdownmenu-wrapper custom-scrollbar">
                  <div className="dropdown-cart-header"><i onClick={() => ChangeSideCartStatus(false)} className='icon-arrow-forward-right'></i> {t("shopping_cart")}</div>
                  {/* End .dropdown-cart-header */}
                  
                  <div className="dropdown-cart-products">
                  {cart ? CartList.items.map((item, index) => {
                    return <div key={index} className="product">
                      <div className="product-details">
                        <h4 className="product-title">
                          <NavLink to="/">{item.part_title}</NavLink>
                        </h4>
                        <span className="cart-product-info">
                          <span className="cart-product-qty">{item.part_qty}</span> × {item.part_display_price}
                        </span>
                      </div>
                      {/* End .product-details */}
                      <figure className="product-image-container">
                        <NavLink to="/" className="product-image">
                          <img src={`${item.part_title}`} alt="product" width={80} height={80} />
                        </NavLink>
                        <NavLink to="#" onClick={()=>this.handleDeleteCartItem(index)} className="btn-remove" title="Remove Product"><RemoveCircleOutlineOutlinedIcon color="danger" /></NavLink>
                      </figure>
                    </div>
                  }):<div></div>}
                   
                  </div>
                  {/* End .cart-product */}
                  <div className="dropdown-cart-total">
                    <span>{t("sub_total")}:</span>
                    <span className="cart-total-price float-right">{CartList.sub_total}</span>
                  </div>
                  {/* End .dropdown-cart-total */}
                  <div className="dropdown-cart-action">
                    <NavLink to={'/Cart'} onClick={() => ChangeSideCartStatus(false)} className="btn btn-gray btn-block view-cart">{t("view_cart")}</NavLink>
                    <NavLink to="/Checkout" onClick={() => ChangeSideCartStatus(false)} className="btn btn-dark btn-block">{t("checkout")}</NavLink>
                  </div>
                  {/* End .dropdown-cart-total */}
                </div>
                {/* End .dropdownmenu-wrapper */}
              </div>
              )  : (
                <div className="dropdown-menu  mobile-cart show" style={SideCartStatus === true?{transform: 'none'}: {}}>

                <NavLink to="#" title="Close (Esc)"  style={{color: '#000 !important',    position: 'absolute',left: '-4.2rem',top: '0.7rem',fontSize: '3.3rem',fontWeight: 300}}><i style={{color: '#000 !important'}} onClick={() => ChangeSideCartStatus(false)} className='icon-arrow-forward-right'></i></NavLink>
                <div className="dropdownmenu-wrapper custom-scrollbar">
                  <div className="dropdown-cart-header">
                    <i onClick={() => ChangeSideCartStatus(false)} className='icon-arrow-forward-right'></i> {t("shopping_cart")}</div>
                  {/* End .dropdown-cart-header */}
                  
              <div className="dropdown-cart-products">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="cart-table-container align-middle">
                      <div className="d-flex justify-content-center">
                        <h5>
                        {t("your_cart_is_empty")}
                          <i className="minicart-icon"></i>
                        </h5>
                      </div>
                      <div className="checkout-methods d-flex justify-content-center">
                        <NavLink to={"/"} className="btn btn-dark btn-lg px-5">
                          {t("shop_now")}
                          <i className="fa fa-arrow-right" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                  </div>
                  {/* End .dropdown-cart-total */}
                </div>
                {/* End .dropdownmenu-wrapper */}
              </div>
              )}

            
            </>
           
        );
    }
}

export default withTranslation()(MobileCartSideBar);