import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'

import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import slugify from 'slugify';

import Carousel from 'react-multi-carousel';
import axios from 'axios';
import { Spinner } from '../../../Components/Shimmers';

import Sections from './Sections';
import { ConfigContext } from '../../../Context/ConfigContext';
import CatelogModal from './CatelogModal';

import Tabs from './Tabs';
import useIsMobile from '../../../Helper/useIsMobile';
import MobSection from './MobSection';
import { Helmet } from 'react-helmet';

import UniCarSearch from '../../Cars/UniCarSearch';
import RecentViews from '../../Home/RecentViews';
import CarInfoBox from '../../Cars/CarInfoBox';
import Offers from "../../Home/Offers";
const AllMakesList = () => {
  const { t } = useTranslation();
 
  const locale = localStorage.getItem('lang');
  const { gdcURL, PCID, setCategories, categories, treeCategories, saveCarInformation, makes } = useContext(ConfigContext);
  const dir = localStorage.getItem('dir'); 
  const [loading, setLoading] = useState();
  const [showCarModal, setShowCarModal] = useState(false);
  const [cars, setCars] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {

    window.scrollTo(0, 0);

    var totalPCS = 0;
    for(var i = 0; i < makes.length; i++){
        totalPCS += makes[i].TOTAL_PCS;
    }
    setCars(totalPCS);
    
  }, []);

  const removeCar = (MFA_BRAND) => {
    saveCarInformation('', '', '', '', '');
    localStorage.setItem('pc_id', '');
    localStorage.setItem('ms_id', '');
    localStorage.setItem('car_info', '');
    
    // navigate(`/Spare-Parts/Catelog`)
    navigate(`/Car-Brands/${MFA_BRAND}`); window.scrollTo(0, 0);
  }
  
  return (
    <main className="main" style={{marginTop: '-10px', textAlign: dir==='rtl'?'right': ''}} dir={dir}>
      <Helmet>
        <title>{t('spare_parts_catelogue')}</title>

      </Helmet>
      {/* <UniCarSearch isHorizontal={true} /> */}
      
    <CarInfoBox />
      <div className="parts p-5">
        <div className="container" >
          {/* {loading === true && <div><center className='mt-5 pt-5'><Spinner/>Please wait...</center></div>} */}
          <h3 style={{fontWeight: 500}}>{t('vehicle_parts_car_brands', {makes:makes?makes.length:0, cars:cars})}</h3>
         
            <div className='row'>
            {
                makes && makes.map((make) => {
                    return <div style={{cursor: 'pointer'}} className='col-sm-3' onClick={() => {removeCar(make.MFA_BRAND);}}>
                        <span className='alert alert-default d-flex justify-content-between align-items-center'>
                            {make.MFA_BRAND} 
                            <span style={{backgroundColor: '#EEE'}} className="badge bg-gray-100">{`${make.TOTAL_PCS}`}</span>
                        </span>
                    </div>
                })
            }
            </div>
            <Offers /> 
            <RecentViews /> 
          
        </div>
      </div>
      
    </main>

  )

}

export default AllMakesList;