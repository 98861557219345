import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import slugify from "slugify";
import { Helmet } from "react-helmet";
import { Tooltip } from "react-tooltip";
import CarInfoBox from "../Cars/CarInfoBox";

const Suppliers = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang');
    const {gdcURL, PCID} = useContext(ConfigContext);
    const dir = localStorage.getItem('dir');
    const [suppliers, setSuppliers] = useState([]);
    const [ResponseData, setResponseData] = useState([]);
    const [activeTooltip, setActiveTooltip] = useState(null);
    const [alphabet, setAlphabet] = useState('A');

    const sectionRefs = useRef({});

    const handleScrollToSection = (id) => {
        if (sectionRefs.current[id]) {
        sectionRefs.current[id].scrollIntoView({ behavior: 'smooth' });
        }
    };

    const toggleTooltip = (supId, event) => {
        event.preventDefault();
        setActiveTooltip(activeTooltip === supId ? null : supId);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        var config = {
            method: 'get',
            url: `${gdcURL}/Suppliers/GetSuppliers?status=1&lang=${locale}&pc_id=${localStorage.getItem('pc_id')}`,
            headers: { }
        };

        axios(config)
        .then(function (response) {
            setResponseData(response.data.data);
            setSuppliers(response.data.data);
            // response.data.data.sort((a, b) => a.SUP_BRAND.localeCompare(b.SUP_BRAND));

            // // Initialize a Map to hold the categorized data
            // const map = new Map();
            // map.set("09", []);

            // // Group data by the initial letter of SUP_BRAND
            // response.data.data.forEach(item => {
            //     const initial = item.SUP_BRAND[0].toUpperCase();
            //     if (/\d/.test(initial)) {
            //         map.get("09").push(item);
            //     } else {
            //         if (!map.has(initial)) {
            //             map.set(initial, []);
            //         }
            //         map.get(initial).push(item);
            //     }
            // });

            // // Convert the Map to an array, sort by keys, and update state
            // const sortedEntries = new Map([...map.entries()].sort());

            // setSuppliers(sortedEntries);

            
        })
        .catch(function (error) {
        });

    }, [])
    

    return (
        <main className="main" dir={dir} style={{textAlign: dir === 'rtl'?'right': ''}}>
            <Helmet>
                <title>Spare Parts Suppliers - {t('site_name')}</title>
                <meta name="description" content={`Find the best spare parts suppliers at {t('site_name')}. We offer a wide range of high-quality parts to meet your needs.`} />
                
                <meta name="keywords" content={ ResponseData  && ResponseData.map((item) => { return item.SUP_BRAND ;} ) }/>
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content={`Spare Parts Suppliers - ${t('site_name')}`} />
                <meta property="og:description" content={`Explore the top-rated spare parts suppliers at ${t('site_name')}. Discover a variety of parts and components for different industries.`} />
                <meta property="og:url" content={`${t('site_url')}/spare-parts-suppliers`} />
                <meta property="og:type" content="website" />
                
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`Spare Parts Suppliers - ${t('site_name')}`} />
                <meta name="twitter:description" content={`Looking for spare parts suppliers? ${t('site_name')} connects you with the best suppliers offering quality parts and services.`} />
                
                <link rel="canonical" href={`${t('site_url')}/spare-parts-suppliers`} />
            </Helmet>

            <div className="container">
                <div className="container">
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><NavLink to={'/'}><i className="icon-home" /></NavLink></li>
                        <li className="breadcrumb-item"><NavLink to={() => {}}>{t('home')}</NavLink></li>
                        <li className="breadcrumb-item active" aria-current="page"></li>
                    </ol>
                </nav>
                </div>
                <div className="container">
                <CarInfoBox isHorizontal={true} redirectStatus={true}/>
                <br />
                <h3>{t('catalogue_of_manufacturers')}</h3>
                <span>{t('our_shop_offer_catalogue', {value: suppliers.length})}</span>
                    <div className="col-lg-12">
                   
                    {
                        suppliers && <div style={{
                            overflowX: 'auto',
                            whiteSpace: 'nowrap',
                            WebkitOverflowScrolling: 'touch',
                            scrollbarWidth: 'thin',
                            msOverflowStyle: 'none',
                            padding: '10px 0'
                        }}>
                            {
                                [...new Set(suppliers.map(supplier => supplier.alphabet))].sort().map((letter) => (
                                    <div 
                                        className="col-auto" 
                                        key={letter} 
                                        onClick={() => setAlphabet(letter)}
                                        style={{
                                            cursor: 'pointer', 
                                            padding: '10px',
                                            margin: '5px',
                                            backgroundColor: alphabet === letter ? '#cc000b' : '#f8f9fa',
                                            color: alphabet === letter ? '#fff' : '#000',
                                            borderRadius: '5px',
                                            display: 'inline-block'
                                        }}
                                    >
                                        <h5 style={{margin: 0}}>{letter}</h5>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    </div>
                </div>
                

                <div className="container">
                {
                    suppliers && <div >
                       
                            <div className="col-lg-12" >
                                
                                <hr style={{margin: '8px'}}/>
                                {/* <h3 style={{color: '#cc000b', margin: 0, paddingLeft: '10px'}}>{key}</h3> */}
                               
                                <div className="row justify-content-center">
                                    {suppliers.filter((c) => c.alphabet === alphabet).map((item, index) => {
                                        return (
                                            <div className="col-md-2 pt-5 pb-5 card" key={`supplier-logos-${item.SUP_ID}-${index}`} style={{
                                                margin: '5px',
                                           
                                                alignItems: 'center',
                                                textAlign: 'center',
                                                padding: '10px',
                                               
                                            }}>
                                               <NavLink to={`/Supplier-Catelog/${item.SUP_ID}-${slugify(item.SUP_BRAND)}`}>
                                                    <ImageWithFallback src={`${gdcURL}/sup-logo/${item.SUP_LOGO_NAME}`} alt={item.SUP_BRAND} index={item.SUP_ID} fallbackText={item.SUP_BRAND}/>
                                                </NavLink>
                                                <span style={{margin: '5px 0'}}>{item.SUP_BRAND}</span>
                                                <div className="tooltip-container">
                                                    <NavLink to={() => {}} 
                                                   
                                                        data-tooltip-id={`tooltop-${item.SUP_ID}-${index}`} 
                                                        data-tooltip-html={`<div className="custom-tooltip" style="z-index: 1000000">
                                                            <h3>${item.SUP_FULL_NAME}</h3>
                                                            <div className="tooltip-arrow"></div> 
                                                            <div>
                                                                <p><strong>Address:</strong> ${item.STREET1??''} ${item.STREET2??''} ${item.CITY1 ?? ''}</p>
                                                               
                                                                ${item.EMAIL ? `<p> <strong>E-Mail:</strong>${' '} ${item.EMAIL} </p>`:`<span></span>`}
                                                                ${item.HOMEPAGE ? `<p> <strong>Website:</strong>${' '} <a href="${item.HOMEPAGE}" target="_blank" rel="noopener noreferrer"> ${item.HOMEPAGE} </a> </p>`:`<span></span>`}
                                                                
                                                            </div>
                                                        </div>`}
                                                        onClick={(e) => toggleTooltip(item.SUP_ID, e)}
                                                        className={activeTooltip === item.SUP_ID ? 'active' : ''}
                                                    >
                                                        {t('show_address')} 
                                                    </NavLink> 
                                                    <Tooltip 
                                                    id={`tooltop-${item.SUP_ID}-${index}`} 
                                                    style={{ zIndex:10000+index, color: "#000", backgroundColor: '#EEE', opacity:1, textAlign: 'left', border: '1px solid #e50813' }}
                                                    // content="Hello world!" 
                                                   
                                                    events={['click']}
                                                    />
                                                    
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        
                    </div>
                }
                </div>
            </div>
        </main>
    )
}
const ImageWithFallback = ({ src, index, alt, fallbackText }) => {
    const [imgError, setImgError] = useState(false);
  
    const handleError = () => {
      setImgError(true);
    };
    const fallbackStyle = {
      textAlign: 'center',
      fontSize: '8px',
      padding: '10px',
      wordBreak: 'break-word',
      maxWidth: '90%',
      
    };
  
    const containerStyle = {
      position: 'relative',
      width: '100px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '0px solid #CCC',
      padding: '11px'
    };
    return (
      <div style={containerStyle}>
        {imgError ? (
          <div style={fallbackStyle}>{fallbackText}</div>
        ) : (
          <img style={{height: '45px', float: 'none'}} key={`img-part-${index}`} src={src} alt={alt} onError={handleError}  />
         
        )}
      </div>
    );
  };
export default Suppliers;