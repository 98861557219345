import React, { useState, useEffect, useContext,useRef } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { Suspense } from "react";
import axios from "axios";
import { ConfigContext } from "../Context/ConfigContext";
import MobileHeader from "./MobileHeader";
import { GetCars } from "../Components/Cars";
import {jwtDecode} from "jwt-decode";
import Sidebar from "./Sidebar";
import MobileSearchBar from "../Components/SearchBar/MobileSearchBar";
import UniCarSearch from "../Pages/Cars/UniCarSearch";

const AppLayout = () => {
  const { 
    UpdateCartList, setLang, setWindowResize, setCartItemsQty, 
    setLoginStatus, apiURL, setCategories, lang, setCars, setMakes, ChangeCarStatus, UpdateCarList, resizeState, CarStatus 
  } = useContext(ConfigContext);

  const [categories, setCategoriesState] = useState([]);
  const [cars, setCarsState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  
  // Initialize localStorage settings
  useEffect(() => {
    localStorage.setItem('dir', 'ltr');
    if (localStorage.getItem('lang') === 'ar') {
      localStorage.setItem('dir', 'rtl');
    }

    setLang(localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage') : 'en');
    setCartItemsQty();
    setWindowResize({
      isMobile: window.innerWidth <= 768,
      loading: false,
      error: null,
    });
    CheckLogin();
  }, []); // Empty dependency array ensures it runs once like componentDidMount

  // Check login status
  const CheckLogin = () => {
    if (!localStorage.getItem('token')) {
      setLoginStatus(false);
      return false;
    }
    const token = localStorage.getItem('token');
    const decodedToken = jwtDecode(token);
    const currentDate = new Date();

    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      setLoginStatus(false);
      return false;
    } else {
      setLoginStatus(true);
    }
  };

  // Fetch categories
  const GetCategories = () => {
    axios.get(`${apiURL}/Categories/GetCategories?country_id=225&lang=${lang}`)
      .then(response => {
        const data = response.data;
        setCategories(data.data);
        setCategoriesState(data.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  };

  // Fetch cars
  const GetCarsAPI = async () => {
    axios.get(`${apiURL}/Parts/GetCars`, null, { token: localStorage.getItem('token') })
      .then(response => {
        const data = response.data;
        setCars(data.data);
        const mMakes = [];
        data.data.reduce((acc, car) => {
          const brand = car.car_brand;
          if (!acc[brand]) {
            acc[brand] = [];
            mMakes.push({ value: car.car_brand, label: car.car_brand });
          }
          acc[brand].push(car);
          return acc;
        }, {});
        setMakes(mMakes);
        setCarsState(data.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  };

  // Check car status
  const CheckStatus = () => {
    if (GetCars().length > 0) {
      ChangeCarStatus(true);
      UpdateCarList(GetCars());
    }
  };


  const mobileDrawerRef = useRef(null);
  const openDrawer = () => {
    if (mobileDrawerRef.current) {
      mobileDrawerRef.current.style.transform = "translateX(0)";
      mobileDrawerRef.current.style.zIndex = "10000000000000000";
    }
  }
  const closeDrawer = (bool) => {
    if(bool){
      setShowModal(true);
    }
    if (mobileDrawerRef.current) {
      mobileDrawerRef.current.style.transform = "translateX(-100%)";
    }
  }

  const [isMobile, setIsMobile] = useState(false);

  // Function to check the current window size
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Set to mobile if width <= 768px
  };

  useEffect(() => {
    handleResize(); // Check initial size
    window.addEventListener('resize', handleResize); // Add event listener

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const dir = localStorage.getItem("dir");
  const [showModal, setShowModal] = useState();
  const divRef = useRef(null);  // Reference to the div 
  
  useEffect(() => {
    // Function to detect if the click is outside of the div
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowModal(false);  // Close the div or trigger any action on outside click
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="page-wrapper" dir={dir}>
        <Sidebar />
        <Header isMobile={isMobile} openDrawer={openDrawer} closeDrawer={closeDrawer}/>
        
        <main className="main">
          <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </Suspense>
        </main>
        <Footer />
      </div>
      
      <MobileHeader mobileDrawerRef={mobileDrawerRef} closeDrawer={closeDrawer}/>
      <div className={`modal fade ${showModal ? 'show d-block' : 'd-none'}`}
        id="subcatsModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="subcatsModalLabel"
        aria-hidden="true"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 100000000 }}>

        <div ref={divRef} className="modal-dialog " role="document" >
          <div className="modal-content">
            <div className="modal-body">
              <div className="container">
                <UniCarSearch setShowModalGarage={setShowModal} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppLayout;
