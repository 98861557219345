import { Component } from "react";
import {NavLink} from 'react-router-dom';
import { ConfigContext } from "../../Context/ConfigContext";
import { AddToCart, GetCarts } from "../../Helper/Carts";
import { ToastContainer, toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { AddToCartNotifier } from "../CartComponents";
class PartsCard extends Component{
  static contextType = ConfigContext;
  constructor(props){
    super(props);
    this.state  = {
      add_to_cart_status : false
    };
  }
  handleAddToCart = async(slug) => {
    // toast.success('Successfully toasted!');
    const {UpdateCartList, ChangeCartProcessStatus, apiURL} = this.context;
    this.setState({add_to_cart_status: true});
    await AddToCart(this.props.part_id, this.props.part_uid, 1, slug);

    const { t } = this.props;
    var partImg = this.props.partImg?this.props.partImg:`${apiURL}/public/placeholder_category.jpeg`
    toast.success(<AddToCartNotifier item_name={this.props.title}  item_img={partImg}/>, {
      onClose: () => {
        this.setState({add_to_cart_status: false});
      }
    })
    UpdateCartList(GetCarts());
    ChangeCartProcessStatus(true);
    
  }
   
    render(){
      const {apiURL} = this.context;
      const { t } = this.props;
      const {add_to_cart_status} = this.state;
      var partImg = this.props.partImg?this.props.partImg:`${apiURL}/public/placeholder_category.jpeg`
      var partURL = `/Part/${this.props.part_slug}`; 
      return (
            <div className="product-default" data-animation-name="fadeInRightShorter">
              <figure>
               <NavLink to={partURL}>
                  <img src={partImg} width={280} height={280} alt={`${this.props.title}`} />
                  <img src={partImg} width={280} height={280} alt={`${this.props.title}`} />
                </NavLink>
                <div className="label-group">
                  {this.props.greenLabel===''|| this.props.greenLabel==null?<div></div>:<div className="product-label label-hot">{(this.props.greenLabel)}</div>}
                  <div className="product-label label-sale">{(this.props.redLabel)}</div>
                </div>
              </figure>
              <div className="product-details">
                {this.props.PartNumber?
                    <div className="category-list">
                    <NavLink to={partURL} className="product-category">{this.props.PartNumber}</NavLink>
                    </div>:<div></div>
                }
               
                
                <h3 className="product-title">
                <NavLink to={partURL}>{this.props.title}</NavLink>
                </h3>
                <div className="ratings-container">
                  <div className="product-ratings">
                    <span className="ratings" style={{width: '80%'}} />
                    {/* End .ratings */}
                    <span className="tooltiptext tooltip-top" />
                  </div>
                  {/* End .product-ratings */}
                </div>
                {/* End .product-container */}
                
                <div className="price-box">
                  <table>
                  <tbody>
                    <tr>
                      <td><span><del className="old-price">{this.props.displayMRPPrice}</del></span></td>
                      <td><span className="product-price">{this.props.displayPrice}</span></td>
                    </tr>
                    </tbody>
                  </table>
                  
                </div>
                {/* End .price-box */}
                {this.props.action?
                <div className="product-action">
                  <NavLink to={() => false} className="btn-icon-wish" title={t(`wishlist`)}><i className="icon-heart" /></NavLink>
                  <button disabled={add_to_cart_status} onClick={() => this.handleAddToCart(`/Part/${this.props.part_uid}/${this.props.part_id}/${this.props.title.replace(/\s/g, "_")}/${this.props.PartNumber}`)} className="btn-icon btn-add-cart"><i className="fa fa-arrow-right" />
                      <span style={{textTransform: 'uppercase'}}>
                        {t('add_to_cart')}    
                      </span>
                  </button>
                  <NavLink to={partURL} className="btn-quickview" title="Quick View"><i className="fas fa-external-link-alt" /></NavLink>
                </div>:<div></div>
                }
                
              </div>
              
              {/* End .product-details */}
            </div>
        )
    }
}

export default withTranslation()(PartsCard);