import { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import { NavLink, useNavigate } from "react-router-dom";
import { AddToGarage } from "../../Helper/MyCars";
import axios from 'axios';
import { useTranslation } from "react-i18next";
import slugify from "slugify";
const CarSelectionSection = ({ base_url, brand, department, make,  model }) => {
    const { gdcURL, setContextPCID, setContextMSID, saveCarInformation } = useContext(ConfigContext);
    const { t } = useTranslation();
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [series, setSeries] = useState([]);
    const [showAllModels, setShowAllModels] = useState(false);
    const initialModelsToShow = 8;
    const locale = localStorage.getItem('lang');
    const dir = localStorage.getItem('dir');
    const navigate = useNavigate();

    const modelsPerLine = 3;
    const modelsToShow = showAllModels ? models : models.slice(0, modelsPerLine * 2);
    const GetCarInfo = async () => {
        var response = await axios.get(`${gdcURL}/Cars/GetCarInfo?pc_id=${localStorage.getItem('pc_id')}&lang=${locale}`);
        var data = response.data;

        localStorage.setItem('car_info', JSON.stringify(data.data));
        setContextPCID(data.data[0].PC_ID)
        setContextMSID(data.data[0].PC_MS_ID)
        return data.data[0];

    }
    const removeCar = () => {
        saveCarInformation('', '', '', '', '');
        localStorage.setItem('pc_id', '');
        localStorage.setItem('ms_id', '');
        localStorage.setItem('car_info', '');
        // setSelectedCarMake(null);
        // setSelectedCarModel(null);
        // setSelectedPCID(null);
        // navigate(`/Spare-Parts/Catelog`)
        window.location.reload();
      }
    useEffect(() => {
        fetch(`${gdcURL}/Cars/GetMakes`)
            .then((res) => res.json())
            .then((data) => {
                setMakes(data.data);
            })
    }, []);
    useEffect(() => {
        if(make){
            var mfa_id = make.split('-')[0];
            if (mfa_id) {
                fetch(`${gdcURL}/Cars/GetUniModels?MFAID=${mfa_id}`)
                .then((res) => res.json())
                .then((data) => {
                    setModels(data.data);

                })
            }
        }else{
            console.log("nomake", make)
        }
        
    }, [make]);



    useEffect(() => {

        if (model) {
            var ms_id = model.split('-')[0];
            var mfa_id = make.split('-')[0];
            if (ms_id && mfa_id) {
            axios.get(`${gdcURL}/Cars/GetUniSeries?MFAID=${mfa_id}&MSID=${ms_id}`)
            .then(data => {
                setSeries(data.data.data);
            })
        }
    }

    }, [model]);
    



    const SaveCar = async (pc_id) => {
        var ms_id = model.split('-')[0];
        var mfa_id = make.split('-')[0];
        if (pc_id && ms_id) {
          localStorage.setItem('mfa_id', mfa_id);
          localStorage.setItem('pc_id', pc_id);
          localStorage.setItem('ms_id', ms_id);
    
    
          var car = await GetCarInfo();
          localStorage.setItem('car_info', JSON.stringify([car]));
    
          AddToGarage(car);
          
          window.location.reload();
          
        }
    
      }

    return <div dir={dir}>
        <div className="row justify-content-center mt-3">
                <div className="col-lg-9">
                    {!model && <h3 style={{ fontWeight: 500 }}>{t('spare_parts_for_your', { make: make.split('-')[1] })}</h3>}
                    {models && models.length > 0 && model && (
                        <h3 style={{ fontWeight: 500 }}>
                            {t('spare_parts_for_your_model', { 
                                make: models.find((item) => 
                                    Number(model.split('-')[0]) === Number(item.MS_ID)
                                )?.MS_NAME 
                            })}
                        </h3>
                    )}
                </div>
            </div>
            <div className="row justify-content-center pl-2">

                {
                    !model && models && modelsToShow.map((item, index) => {
                        if (index > 0 && index % modelsPerLine === 0) {
                            return (
                                <>
                                    <div className="w-100"></div>
                                    <div className="col-sm-3 m-1" style={{ backgroundColor: '#FFF', border: '1px solid #EEE', display: 'flex', alignItems: 'center', justifyContent: 'center',  }}>
                                    <NavLink style={{ width: '100%' }} onClick={() => window.scrollTo(0, 0)} to={`${base_url}/${make}/${item.MS_ID}-${slugify(item.MS_NAME)}`}>
                                            <table style={{ textAlign: 'center' }}>
                                                <tr>
                                                    <td rowSpan={2} style={{ height: '100px', width: '120px', textAlign: dir === 'rtl'?'right': 'left' }}>
                                                        <img
                                                            width={100}
                                                            src={`${gdcURL}/car-ms-images/${item.MS_ID}`}
                                                            style={{ float: 'left' }}
                                                            onError={e => {
                                                                e.target.src = `${window.location.protocol}//${window.location.host}/assets/images/placeholder_car.png`;
                                                            }}
                                                            alt="Ghayar's Car Placeholder Image"
                                                        />
                                                    </td>
                                                    <td className={`nav-link-custom`} style={{ textAlign: dir === 'rtl'?'right': 'left' }}>
                                                        <b>{item && item.label && item.label.split(' ')[0]}</b> <br />
                                                        {item.label}
                                                    </td>
                                                </tr>
                                            </table>
                                        </NavLink>
                                    </div>
                                </>
                            );
                        }
                        return (
                            <div className="col-sm-3 m-1" style={{ backgroundColor: '#FFF', border: '1px solid #EEE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <NavLink style={{ width: '100%' }} onClick={() => window.scrollTo(0, 0)} to={`${base_url}/${make}/${item.MS_ID}-${slugify(item.MS_NAME)}`}>
                                    <table style={{ textAlign: 'center' }}>
                                        <tr>
                                            <td rowSpan={2} style={{ height: '100px', width: '120px', textAlign: 'left' }}>
                                                <img
                                                    width={100}
                                                    src={`${gdcURL}/car-ms-images/${item.MS_ID}`}
                                                    style={{ float: 'left' }}
                                                    alt={item && item.label}
                                                    onError={e => {
                                                        e.target.src = `${window.location.protocol}//${window.location.host}/assets/images/placeholder_car.png`;
                                                    }}
                                                />
                                            </td>
                                            <td className={`nav-link-custom`} style={{ textAlign: dir === 'rtl'?'right': 'left' }}>
                                                <b>{item && item.label && item.label.split(' ')[0]}</b> <br />
                                                {item.label}
                                            </td>
                                        </tr>
                                    </table>
                                </NavLink>
                            </div>
                        );
                    })
                }
                <div className="col-lg-12 pb-4">
                    {!model && models && models.length > modelsPerLine * 2 && (
                        <div className="text-center mt-3">
                            <button
                                className="btn btn-link"
                                onClick={() => setShowAllModels(!showAllModels)}
                                style={{ textDecoration: 'none' }}
                            >
                                {showAllModels ? t('show_less') : t('show_more')}
                                <i style={{marginRight: dir === 'rtl'?'10px': ''}} className={`fas fa-chevron-${showAllModels ? 'up' : 'down'} ml-2`}></i>
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <div className="row justify-content-center pl-2">
                <div className="col-md-9 pb-4">
                   
                    <div className="row justify-content-left">
                        
                        {
                            series && series.map((item, index) => {
                                return <div className="col-md-3 p-2">
                                    <NavLink onClick={() => SaveCar(item.PC_ID)} >{item.label}</NavLink>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
    </div>


}
export default CarSelectionSection;