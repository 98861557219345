import { useTranslation } from "react-i18next";
import {
  ClearGarage,
  GetGarages,
  RemoveCarGarage,
  SelectCarGarage,
  SelectCarGarageByPCID,
} from "../Helper/MyCars";

import axios from "axios";
import Swal from "sweetalert2";
import { useContext, useEffect, useRef, useState } from "react";
import { ConfigContext } from "../Context/ConfigContext";
import { useNavigate } from "react-router-dom";
import UniCarSearch from "../Pages/Cars/UniCarSearch";
// import CarSearchBox from "../Pages/Cars/CarSeachBox";

const MobileMyGarage = ({closeDrawer}) => {
  const [myCars, setMyCars] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const [MySavedCars, setMySavedCars] = useState();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loginData, setLoginData] = useState({
    user_id: '',
    user_password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  
  const { TillYear, apiURL,gdcURL, MyGarageCars, setMyGarageCars, setContextPCID, setContextMSID, setLoginStatus, setToken } =
    useContext(ConfigContext);
  const dir = localStorage.getItem("dir");
  const navigate = useNavigate();
  const handleSelectCarGarage = async (CAR) => {
    var cars = await SelectCarGarageByPCID(CAR.PC_ID); 
    setMyGarageCars(cars);
    localStorage.setItem('car_info', JSON.stringify([CAR]));

    setContextPCID(CAR.PC_ID);
    setContextMSID(CAR.PC_MS_ID);
    localStorage.setItem("ms_id", CAR.PC_MS_ID);
    localStorage.setItem("pc_id", CAR.PC_ID);
    localStorage.setItem('car_info', JSON.stringify([CAR]));
    console.log("CARR",CAR)
    window.location = "/Spare-Parts/Catelog";
  };
  const handleSelectCarRemove = async (index) => {
    RemoveCarGarage(index);

    window.location.reload();
  };
  const handleClearHisotry = async () => {
    await ClearGarage();
    setMyGarageCars([]);
  };
  useEffect(() => {

  }, [MyGarageCars])
  const divRef = useRef(null);  // Reference to the div 
  
  useEffect(() => {
    // Function to detect if the click is outside of the div
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setShowModal(false);  // Close the div or trigger any action on outside click
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleAddCar = async(PC_ID, index) =>{
    const customerId = localStorage.getItem('customer_id');
    if (!customerId || isNaN(customerId)) {
      setShowLoginModal(true);
      return;
    }
    
    const addCarData = { customer_id: customerId, customers_car_PC_ID: PC_ID };

    try {
        const { data } = await axios.post( `${gdcURL}/MyCars/AddCar`, addCarData );
        console.log("data", data)
        if (data.success) {
          
          await RemoveCarGarage(index);
          setMyGarageCars(GetGarages());
            loadSavedCars();
            return true;
        } else {
           return false;
        }
    } catch (error) {
        console.error("Error:", error);
        return false;
       
    }
  }

  const handleSaveCarRemove = async(car, index) =>{
    const customerId = localStorage.getItem('customer_id');
    if (!customerId || isNaN(customerId)) {
      setShowLoginModal(true);
      return;
    }
    
    const addCarData = { customer_id: customerId, customers_car_PC_ID: car.PC_ID };

    try {
        const { data } = await axios.post( `${gdcURL}/MyCars/RemoveCar`, addCarData );
        console.log("data", data)
        if (data.success) {
          
          
            loadSavedCars();
            return true;
        } else {
           return false;
        }
    } catch (error) {
        console.error("Error:", error);
        return false;
       
    }
  }
  const loadSavedCars = () => {
    fetch(`${gdcURL}/MyCars/GetMyCars?customer_id=${localStorage.getItem("customer_id")}`)
        .then((res) => res.json())
        .then((data) => {
          setMySavedCars(data.data);
        })
  }

  useEffect(() => {
    loadSavedCars();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    const { user_id, user_password } = loginData;

    try {
      const { data } = await axios.post(`${apiURL}/Account/Login`, null, { 
        params: { login_id: user_id, password: user_password } 
      });

      if (data.success) {
        localStorage.setItem('token', data.data[0].token);
        setToken(data.data[0].token);
        localStorage.setItem('customer_id', data.data[0].customer_id);
        localStorage.setItem('email_address', data.data[0].email_address);
        localStorage.setItem('first_name', data.data[0].first_name);
        localStorage.setItem('mobile_number', data.data[0].mobile_number);
        setLoginStatus(!!localStorage.getItem('token'));
        setShowLoginModal(false);
        // You might want to reload or update the state here
      } else {
        // Handle login error
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLoginChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div>
      <div style={{zIndex: 10000000}} >
        <div>
          <div
            className="pl-2 pr-2"

            dir={dir}
            style={{ textAlign: dir === "rtl" ? "right" : "left" }}>
            
            {MySavedCars && MySavedCars.map((car, index) => {
              return (
                <div
                  className="row pt-4 pb-2 pl-2 pr-2"
                  key={`cars-index-${index}`}
                  style={{
                    borderBottom: "1px solid rgb(230, 230, 230)",
                    marginTop: 0,
                    marginBottom: 0,
                  }}>
                  <div className="col-1">
                   

                    <input type="radio" value={1} onClick={() => { handleSelectCarGarage(car); }} checked={String(car.PC_ID) === String(localStorage.getItem('pc_id'))} />
                  </div>
                  <div style={{cursor: 'pointer', pointerEvents: 'none', width: '190px'}} className="col-sm-8 nav-link-custom" >
                    <label onClick={() => { handleSelectCarGarage(car); }} style={{cursor: 'pointer', pointerEvents: 'none'}} className="nav-link-custom">{car.TYPEL} {car.PC_ENG_CODES} {new Date(car.PCS_CI_FROM).toLocaleString('default', {  year: 'numeric' })} - {car.PCS_CI_TO ? new Date(car.PCS_CI_TO).toLocaleString('default', {  year: 'numeric' }) : TillYear}</label>
                    
                  </div>
                  <div className="col-1">
                    <button
                      onClick={() => handleSelectCarGarage(car)}
                      className="btn btn-xs btn-primary"
                      style={{ marginTop: "-5px" }}>
                      <i
                        className="sicon-arrow-right align-right"
                        style={{ fontSize: "12px" }}
                      />
                    </button>
                  </div>
                  <div className="col-1">
                    <div
                      onClick={() => handleSaveCarRemove(car)}
                      style={{ marginTop: "-2px", marginLeft: dir ==='rtl'?"":"10px", marginRight: dir ==='rtl'?"10px":"" }}>
                      <i
                        className="fas fa-trash-alt align-right"
                        style={{ fontSize: "12px", color: "rgb(0, 104, 215)" }}
                      />
                    </div>
                  </div>
                  {/* <div style={{cursor: 'pointer'}}  onClick={() => {handleAddCar(car.PC_ID, index)}} className="link-blue-sm col-lg-12" >{t('save')}</div> */}
                </div>
              );
            })}
            <button 
              data-toggle="modal" 
              data-target="#exampleModal" 
              onClick={() => {
                closeDrawer(true);
                setShowModal(true) // Add slight delay
              }} 
              type="button" 
              className="btn btn-primary" 
              style={{ width: '100%' }}
            >
              {t('add_new_car')}
            </button>
            <ul className="ml-3">
              <li style={{ marginTop: "12px", color: "#132530", fontSize: "13px" }}>
                <svg
                  className="header-nav__icon base-svg mr-2"
                  role="img"
                  style={{ width: "20px", height: "20px", fill: "#184460", marginLeft: dir ==='rtl'?"10px":"" }}>
                  {" "}
                  <use href="/assets/images/icon-sprite-bw.svg#sprite-garage-car-icon-bw"></use>{" "}
                </svg>
                {t("save_to_garage")}
              </li>

              <li style={{ marginTop: "12px", color: "#132530", fontSize: "13px" }}>
                <svg
                  className="header-nav__icon base-svg mr-2"
                  role="img"
                  style={{ width: "20px", height: "20px", fill: "#184460", marginLeft: dir ==='rtl'?"10px":"" }}>
                  {" "}
                  <use href="/assets/images/icon-sprite-bw.svg#sprite-target-icon-bw"></use>{" "}
                </svg>
                {t("quick_search_for_saerch")}
              </li>

              <li style={{ marginTop: "12px", color: "#132530", fontSize: "13px" }}>
                <svg
                  className="header-nav__icon base-svg mr-2"
                  role="img"
                  style={{ width: "20px", height: "20px", fill: "#184460", marginLeft: dir ==='rtl'?"10px":"" }}>
                  {" "}
                  <use href="/assets/images/icon-sprite-bw.svg#sprite-like-icon-bw"></use>{" "}
                </svg>
                {t("guaranteed_compatibility")}
              </li>
            </ul>
            {MyGarageCars && MyGarageCars.length > 0 && <div className="row">
              <div className="col-sm-8 pl-4">
                <span style={{ fontWeight: "bold", fontSize: "large" }}>
                  {t("history")}
                </span>
              </div>
              <div
                className="col-sm-4 pt-2"
                style={{ color: "#0068d7", fontWeight: "600", textAlign: "right" }}>
                <span
                  onClick={() => handleClearHisotry()}
                  style={{ color: "#0068d7", fontWeight: "600", textAlign: "right" }}>
                  {t("clear_history")}
                </span>
              </div>
            </div>
            }
            <table>
              <tbody>
                <tr>
                  <td width={"74%"}></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            {MyGarageCars?.slice().reverse().map((car, index) => {
              return (
                <div
                  className="row pt-4 pb-2 pl-3"
                  key={`cars-index-${index}`}
                  style={{
                    borderBottom: "1px solid rgb(230, 230, 230)",
                    marginTop: 0,
                    marginBottom: 0,
                  }}>
                  <div className="col-1">
                   

                    <input type="radio" value={1} onClick={() => { handleSelectCarGarage(car); }} checked={String(car.PC_ID) === String(localStorage.getItem('pc_id'))} />
                  </div>
                  <div style={{cursor: 'pointer', pointerEvents: 'none'}} className="col-8 nav-link-custom" >
                    <label onClick={() => { handleSelectCarGarage(car); }} style={{cursor: 'pointer', pointerEvents: 'none'}} className="nav-link-custom">{car.TYPEL}</label>
                    
                  </div>
                  <div className="col-1">
                    <button
                      onClick={() => handleSelectCarGarage(car)}
                      className="btn btn-xs btn-primary"
                      style={{ marginTop: "-5px" }}>
                      <i
                        className="sicon-arrow-right align-right"
                        style={{ fontSize: "12px" }}
                      />
                    </button>
                  </div>
                  <div className="col-1">
                    <div
                      onClick={() => handleSelectCarRemove(car)}
                      style={{ marginTop: "-2px", marginLeft: dir ==='rtl'?"":"10px", marginRight: dir ==='rtl'?"10px":"" }}>
                      <i
                        className="fas fa-trash-alt align-right"
                        style={{ fontSize: "12px", color: "rgb(0, 104, 215)" }}
                      />
                    </div>
                  </div>
                  <div style={{cursor: 'pointer'}}  onClick={() => {handleAddCar(car.PC_ID, index)}} className="link-blue-sm col-lg-12" >{t('save')}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      

      {/* Login Modal */}
      <div className={`modal fade ${showLoginModal ? 'show d-block' : 'd-none'}`}
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 100000000 }}>
        <div className="modal-dialog" role="document" style={{ maxWidth: '500px' }}>
          <div className="modal-content" style={{backgroundColor: '#f9fafb'}}>
            <div className="modal-header">
              <h5 className="modal-title">{t('login')}</h5>
              <button type="button" className="close" onClick={() => setShowLoginModal(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleLogin}>
                <div className="form-group">
                  <label>{t('email')}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="user_id"
                    value={loginData.user_id}
                    onChange={handleLoginChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>{t('password')}</label>
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      name="user_password"
                      value={loginData.user_password}
                      onChange={handleLoginChange}
                      required
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ padding: '0.375rem 0.75rem' }}
                      >
                        {showPassword ? (
                          <i className="fa fa-eye-slash" style={{ fontSize: '14px' }}></i>
                        ) : (
                          <i className="fa fa-eye" style={{ fontSize: '14px' }}></i>
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="text-right mt-2">
                    <a href="/Forget-Password" className="link-blue-md">{t('forget_password')}</a>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">{t('login')}</button>
              </form>
              <div className="text-center mt-3">
                {t("If you don't have an Ghayar account yet, ")}
                <a href="/Signup" className="link-blue-md" style={{fontSize: '16px'}}>{t("register now")}</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default MobileMyGarage;
