import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConfigContext } from '../../Context/ConfigContext';

const MobileSearchBar = () => {
    const { t } = useTranslation();
    const [keyword, setKeyword] = useState('');
    const [searchResDisplay, setSearchResDisplay] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate();
    const modalRef = useRef(null);
    const { gdcURL } = useContext(ConfigContext);
    const lang = localStorage.getItem('lang');
    const dir = localStorage.getItem('dir');

    const showModal = () => {
        const modalElement = modalRef.current;
        if (modalElement) {
            const modal = new window.bootstrap.Modal(modalElement);
            modal.show();
        } else {
            console.error("Modal element not found.");
        }
    };

    const hideModal = () => {
        const modalElement = modalRef.current;
        if (modalElement) {
            console.log("Modal element found:", modalElement);
            modalElement.classList.remove('show');
            modalElement.setAttribute('aria-hidden', 'true');
            modalElement.style.display = 'none';
            document.body.classList.remove('modal-open');
            const backdrop = document.querySelector('.modal-backdrop');
            if (backdrop) {
                backdrop.remove();
            }
        } else {
            console.error("Modal element not found.");
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setKeyword(value);

        if (value.length > 2) {
            fetch(
                `${gdcURL}/Gen/Search?keyword=${value.trim()}&pc_id=${localStorage.getItem("pc_id")}&lang=${lang}`
            )
            .then((res) => res.json())
            .then((data) => {
                const docs = data.response.docs;
                const uniqueDocs = Array.from(new Map(docs.map(doc => [doc.SEARCH_TITLE.join('|'), doc])).values());
                setSearchResults(uniqueDocs);
                setSearchResDisplay(uniqueDocs.length > 0);
            });
        } else {
            setSearchResults([]);
            setSearchResDisplay(false);
        }
    };

    const navigateHandle = (part) => {
        setSearchResDisplay(false);
        hideModal();
        if (part.SEARCH_TYPE === 'CATEGORY') {
            navigate(`/Spare-Parts/${part.SEARCH_URL}`);
        } else if (part.SEARCH_TYPE === 'IAMNumber') {
            navigate(`/Spare-Parts/Details/${part.SEARCH_URL}`);
        } else if (part.SEARCH_TYPE === 'PRODUCTS') {
            navigate(part.SEARCH_URL);
        }
    };

    return (
        <div style={{backgroundColor: '#132531', marginTop: '-2px'}}>
            <div className="modal fade" ref={modalRef} id="mobileSearchModal" tabIndex={-1} role="dialog" aria-labelledby="mobileSearchModalTitle" aria-hidden="true" style={{ zIndex: 1050000000, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <input type="text" className="form-control" placeholder={t("enter_part_number_name")} value={keyword} onChange={handleInputChange} />
                            <div style={{ backgroundColor: '#FFF', position: 'relative', height: 'auto', width: '100%', zIndex: 100000, overflowY: 'auto', maxHeight: '300px' }}>
                                <table className='table table-bordered'>
                                    {searchResults.map((item) => (
                                        <tr key={item.SEARCH_TITLE} style={{ cursor: 'pointer' }} onClick={() => navigateHandle(item)}>
                                            <td>{item.SEARCH_TITLE}</td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-inline-flex align-items-center w-100 pl-2 pr-2">
                <input type="text" placeholder={t("enter_part_number_name")} className="form-control flex-grow-1" onClick={showModal} />
                <button className="btn btn-primary ml-2" onClick={showModal} style={{ marginTop: '-10px' }}>
                    <i className="fas fa-search"></i>
                </button>
            </div>
        </div>
    );
}

export default MobileSearchBar;