import { useTranslation } from "react-i18next";

const SocialMediaFooter = () => {

  const { t } = useTranslation();
  const dir = localStorage.getItem("dir");
  if(window.innerWidth <= 768){
    return <div
    className="block block-posts-carousel block-posts-carousel--layout--list"
    data-layout="list"
    style={{ marginTop: 150, textAlign: dir==='rtl'?'right': '' }}
  >
    <div className="container-fluid" style={{ backgroundColor: "#0e1f2a" }}>
      <div className="container">
        <div className="row justify-content-center">
          <div
            className="col-md-12"
            style={{
              height: 200,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              
            }}
          >
            <img
              src="https://ghayar.ae/assets/dist/images/screenshotofapp.png"
              alt={t('purchasing_products_via_ghayar_app')}
              style={{ 
                height: 300,
                objectFit: 'cover',
                position: 'relative',
                top: '-50px'
              }}
            />

            
          </div>
          <div className="col-md-12" style={{marginTop: '-50px', textAlign: 'center'}}>
            <div style={{ color: "#FFF", marginTop: 80, fontSize: '17px' }}> {t('purchasing_products_via_ghayar_app')} </div>
          </div>
          <div className="row justify-content-center mt-2">
            <div className="col-6">

            <table style={{ width: 130, margin: "auto" }} cellSpacing={20}>
              <tbody>
                <tr>
                  <td>
                    <a href="https://play.google.com/store/apps/details?id=com.ghayar">
                      <img
                        src="https://ghayar.ae/assets/dist/images/playstore.png"
                        alt={t('purchasing_products_via_ghayar_app')}
                        style={{ width: 130 }}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="https://apps.apple.com/in/app/ghayar-spare-parts/id1571024450">
                      {" "}
                      <img
                        src="https://ghayar.ae/assets/dist/images/appstore.png"
                        alt={t('purchasing_products_via_ghayar_app')}
                        style={{ width: 130 }}
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
            <div className="col-4">
            <img
              src="https://ghayar.ae/assets/dist/images/appqrycode.png"
              alt={t('scan_the_qr_code_to_do')}
              style={{ width: 110, margin:'auto' }}
            />
            </div>
          </div>
          <div className="col-md-12 mt-2" style={{textAlign: 'center'}}>
            <span style={{ color: "#FFF" }}>
            {t('scan_the_qr_code_to_do')}
            </span>
          </div>
         
        </div>
      </div>
    </div>
    
  </div> 
  }
    return <div
    className="block block-posts-carousel block-posts-carousel--layout--list"
    data-layout="list"
    style={{ marginTop: 100, textAlign: dir==='rtl'?'right': '' }}
  >
    <div className="container-fluid" style={{ backgroundColor: "#0e1f2a" }}>
      <div className="container">
        <div className="row">
          <div
            className="col-md-4"
            style={{
              height: 200,
              
              textAlign: "center"
            }}
          >
            <img
              src="https://ghayar.ae/assets/dist/images/screenshotofapp.png"
              alt={t('purchasing_products_via_ghayar_app')}
              style={{ marginTop: "-100px", height: 300 }}
            />
          </div>
          <div
            className="col-md-3"
            style={{
              textAlign: "center",
              fontFamily: '"Inter"',
              fontWeight: 200,
              height: 200,
              
              paddingTop: 23
            }}
          >
            <img
              src="https://ghayar.ae/assets/dist/images/appqrycode.png"
              alt={t('scan_the_qr_code_to_do')}
              style={{ width: 110, margin:'auto' }}
            />
            <br />
            <span style={{ color: "#FFF" }}>
            {t('scan_the_qr_code_to_do')}
            </span>
          </div>
          <div
            className="col-md-4"
            style={{
              textAlign: "center",
              fontFamily: '"Inter"',
              fontWeight: 200,
              height: 200
            }}
          >
            <div style={{ color: "#FFF", marginTop: 80 }}>
              {t('purchasing_products_via_ghayar_app')}
            </div>
          </div>
          <div className="col-md-1" style={{ textAlign: "center" }}>
            <div style={{ height: 53 }} />
            <table style={{ width: 130, margin: "auto" }} cellSpacing={20}>
              <tbody>
                <tr>
                  <td>
                    <a href="https://play.google.com/store/apps/details?id=com.ghayar">
                      <img
                        src="https://ghayar.ae/assets/dist/images/playstore.png"
                        alt={t('purchasing_products_via_ghayar_app')}
                        style={{ width: 130 }}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="https://apps.apple.com/in/app/ghayar-spare-parts/id1571024450">
                      {" "}
                      <img
                        src="https://ghayar.ae/assets/dist/images/appstore.png"
                        alt={t('purchasing_products_via_ghayar_app')}
                        style={{ width: 130 }}
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ height: 23 }} />
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid" style={{ backgroundColor: "#132531" }}>
      <div className="container" >
        <div
          className="row"
          style={{
            textAlign: "left",
            color: "#CECCCC",
            fontFamily: '"Inter"',
            fontWeight: 200,
          }}
        >
          <div
            className="col-sm-4"
            style={{  padding: 20 }}
          >
            <a href="https://www.instagram.com/ghayar.ae/" target="_blank">
            <table cellPadding={5}>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="https://ghayar.ae/assets/dist/images/instagramupdates.png"
                      style={{ width: "100%" }} 
                      alt={t('follow_latest_trends')}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p style={{ color: "#CCC" }}>
                      {t('follow_latest_trends')}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.instagram.com/ghayar.ae/"
                      style={{
                        float: "right",
                        fontWeight: "bold",
                        color: "#FFF",
                        direction: dir === 'rtl'?'ltr':''
                      }}
                    >
                      {t('see_more_on_instagram')} <i className="fa fa-play" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            </a>
          </div>
          <div
            className="col-sm-4"
            style={{  padding: 20 }}
          >
            <a href="https://www.youtube.com/@ghayarautospareparts8352">
            <table cellPadding={5}>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="https://ghayar.ae/assets/dist/images/youtubeupdates.png"
                      style={{ width: "100%" }} 
                      alt={t('follow_latest_trends')}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p style={{ color: "#CCC",textAlign: dir==='rtl'?'right': '' }}>
                      {t('follow_latest_trends')}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.youtube.com/@ghayarautospareparts8352"
                      style={{
                        float: "right",
                        fontWeight: "bold",
                        color: "#FFF",
                        direction: dir === 'rtl'?'ltr':''
                      }}
                    >
                      {t('see_more_on_youtube')} <i className="fa fa-play" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            </a>
          </div>
          <div
            className="col-sm-4"
            style={{  padding: 20 }}
          >
            <a href="https://www.facebook.com/ghayar.ae">
            <table cellPadding={5}>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="https://ghayar.ae/assets/dist/images/facebooksupdates.jpg"
                      style={{ width: "100%" }} 
                      alt={t('follow_latest_trends')}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p style={{ color: "#CCC",textAlign: dir==='rtl'?'right': '' }}>
                      {t('follow_latest_trends')}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.facebook.com/ghayar.ae"
                      style={{
                        float: "right",
                        fontWeight: "bold",
                        color: "#FFF",
                        direction: dir === 'rtl'?'ltr':''
                      }}
                    >
                      {t('see_more_on_facebook')} <i className="fa fa-play" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>  
}

export default SocialMediaFooter;