import React, { useEffect, useState } from "react";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import { Helmet } from "react-helmet";
import MobBasketCart from "./MobBasketCart";
import { useTranslation } from "react-i18next";
import MobBasketLogin from "./MobBasketLogin";
import BasketAddress from "./BasketAddress";
import MobBasketPayment from "./MobBasketPayment";
import MobBasketConfirm from "./MobBasketConfirm";
import { useLocation } from "react-router-dom";

export default function MobBasket({ default: defaultStep = 0 }) {  
  console.log('Basket component rendering');
  const dir = localStorage.getItem("dir");
  const { t } = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState();
  const [currentStep, setCurrentStep] = useState(defaultStep);
  const location = useLocation();

  // Update current step when location changes
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const step = queryParams.get('step');
    if (step && !isNaN(step)) {
      setCurrentStep(parseInt(step));
    }
  }, [location]);

  useEffect(() => {
    const handleStepClick = (index) => {
      if(currentStep && currentStep > 0 && currentStep !== index){
        if((index + 1) === 1){
          window.location = '/basket';
        }else if((index + 1) === 2){
          window.location = '/basket/login';
        }else if((index + 1) === 3){
          window.location = '/basket/address';
        }else if((index + 1) === 4){
          window.location = '/basket/payment';
        }
      }

      
      // alert(`Clicked step ${index + 1}`);
    };

    const timer = setTimeout(() => {
      // Target the correct elements based on the provided HTML
      const stepElements = document.querySelectorAll('.wizard-icon-circle.md');
      if (stepElements.length > 0) {
        console.log('Found step elements:', stepElements.length);
        stepElements.forEach((step, index) => {
          step.addEventListener('click', () => handleStepClick(index));
        });
      } else {
        // console.warn('No step elements found');
      }
    }, 1000);

    return () => {
      const stepElements = document.querySelectorAll('.wizard-icon-circle.md');
      stepElements.forEach(step => {
        step.removeEventListener('click', handleStepClick);
      });
      clearTimeout(timer);
    };
  }, []);

  const handleComplete = () => {
    console.log("Form completed!");
    // Handle form completion logic here
  };
  
  
  return (
    <>
      <div dir={dir}
       
        style={{
          borderBottom: "1px solid rgb(226, 226, 226)",
          marginBottom: "10px",
        }}>
        <Helmet>
          <title>{t('basket')}</title>
        </Helmet>
        <div className="container">
          
          
              
            <FormWizard 
            stepSize="xs" 
            color="#829bab"
            disableBackOnClickStep={true} 
            startIndex={currentStep}  
            backButtonTemplate = {(handleNext) => (
              <button style={{display: 'none'}} className="base-button" onClick={handleNext}>
                next
              </button>
            )}
            nextButtonTemplate={(handleNext) => (
              <button style={{display: 'none'}} className="base-button" onClick={handleNext}>
                next
              </button>
            )}
            finishButtonTemplate={(handleComplete) => (
              <button className="finish-button" style={{display: 'none'}}  onClick={handleComplete}>
                finish
              </button>
            )}

           
            onComplete={handleComplete} >
              <FormWizard.TabContent title={t('basket')}  number="1" onClick={() => alert('1')}>
                <MobBasketCart />
              </FormWizard.TabContent>
              <FormWizard.TabContent title={t('login')} number="2">
                <MobBasketLogin />
              </FormWizard.TabContent>
              <FormWizard.TabContent color={'red'} title={t('addresses')}number="3">
                <BasketAddress />
              </FormWizard.TabContent>
              <FormWizard.TabContent title={t('payment')}number="4">
                <MobBasketPayment />
              </FormWizard.TabContent>
              <FormWizard.TabContent title={t('confirm')}number="5" >
                  <MobBasketConfirm />
              </FormWizard.TabContent>
            </FormWizard>
            {/* add style */}
            <style>{`
        @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
      `}</style>
        
        </div>
      </div>
    </>
  );
}