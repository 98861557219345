import React, { Component } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import { AddCar, GetCars } from "../../Components/Cars";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';
class SelectCarsMob extends Component {
  static contextType = ConfigContext;
  constructor(props) {
    super(props);

    this.state = {
      selectedMake: "",
      selectedYear: "",
      selectedModel: "",
      selectedEngine: "",
      makes: [],
      vin: "",
      // years: [],
      // models: [],
      // engines: [],
      // cars: [],
      selectedCar: "",
    };
  }
  componentDidMount() {}

  validateInputs = () => {
    const { selectedMake, selectedYear, selectedModel, selectedEngine, vin } =
      this.state;

    // Check if VIN is disabled (other four fields are enabled)
    if (!vin) {
      // Validation logic for the other four fields
      if (!selectedMake || !selectedYear || !selectedModel || !selectedEngine) {
        alert("Please select all car details");
        return false;
      }
    } else {
      // Validation logic for VIN
      if (!vin) {
        alert("Please enter VIN");
        return false;
      }
    }

    // You can add more specific validations as needed

    return true;
  };

  handleMakesChange = (selectedMake) => {
    const { cars, setYears } = this.context;
    this.setState({ selectedMake: selectedMake }, () => {
      var filteredCars = cars.filter(
        (car) => car.car_brand === this.state.selectedMake.value
      );
      var groupedCars = [];
      var tempGroupedCars = [];
      var mYears = [];
      filteredCars.forEach(function (car) {
        if (!tempGroupedCars[car.car_year]) {
          tempGroupedCars[car.car_year] = [];
          groupedCars.push(car);
          mYears.push({ value: car.car_year, label: car.car_year });
        }
      });
      mYears.sort((a, b) => a.value - b.value);
      setYears(mYears);
    });
  };
  handleYearsChange = (selectedYear) => {
    const { cars, setModels } = this.context;
    this.setState({ selectedYear: selectedYear }, () => {
      var filteredCars = cars.filter(
        (car) =>
          car.car_brand === this.state.selectedMake.value &&
          car.car_year === this.state.selectedYear.value
      );
      var groupedCars = [];
      var tempGroupedCars = [];
      var mModels = [];
      filteredCars.forEach(function (car) {
        if (!tempGroupedCars[car.car_model]) {
          tempGroupedCars[car.car_model] = [];
          groupedCars.push(car);
          mModels.push({ value: car.car_model, label: car.car_model });
        }
      });
      setModels(mModels);
    });
  };

  handleModelsChange = (selectedModel) => {
    const { cars, setEngines } = this.context;
    this.setState({ selectedModel: selectedModel }, () => {
      var filteredCars = cars.filter(
        (car) =>
          car.car_brand === this.state.selectedMake.value &&
          car.car_year === this.state.selectedYear.value &&
          car.car_model === this.state.selectedModel.value
      );
      var groupedCars = [];
      var tempGroupedCars = [];
      var mEngines = [];
      filteredCars.forEach(function (car) {
        if (!tempGroupedCars[car.car_model]) {
          tempGroupedCars[car.car_model] = [];
          groupedCars.push(car);
          mEngines.push({ value: car, label: car.car_engine });
        }
      });
      setEngines(mEngines);
    });
  };
  handleEnginesChange = (selectedEngine) => {
    this.setState({ selectedEngine, selectedCar: selectedEngine }, () => {});
  };

  handleGo() {
    console.log("this.state.selectedCar.value", this.state.selectedCar.value);
    const { ChangeCarStatus, UpdateCarList } = this.context;
    if (this.validateInputs()) {
      if (this.state.vin) {
        this.searchByVin(this.state.vin);
      } else {
        AddCar(this.state.selectedCar.value);
        ChangeCarStatus(true);
        this.props.navigate(
          `/Catalog/${
            this.state.selectedCar.value.car_id
          }/${this.state.selectedCar.value.car_brand.replace(/\s/g, "_")}/${
            this.state.selectedCar.value.car_year
          }/${this.state.selectedCar.value.car_model.replace(
            /\s/g,
            "_"
          )}/${this.state.selectedCar.value.car_engine.replace(/\s/g, "_")}`
        );
        UpdateCarList(GetCars());
        this.resetFields();
      }
    }
  }
  searchByVin = async (vin) => {
    const { apiURL } = this.context;
    console.log("vin", vin);
    try {
      const response = await axios.get(
        `${apiURL}/Parts/GetVINCar?country_id=225&lang=en&vin=${vin}`
      );

      const carData = response.data.data;

      if (carData) {
        this.props.navigate(`/VIN/${vin}`);
      } else {
        this.props.navigate(`/VIN/${vin}`);
        console.warn("Car data or VIN not found in the response:", carData);
      }
    } catch (error) {
      console.error("Error fetching car data:", error);
      // setCarDataByVin(null);
    }
  };
  resetFields = () => {
    const { setYears, setModels, setEngines } = this.context;

    this.setState(
      {
        selectedMake: null,
        selectedYear: null,
        selectedModel: null,
        selectedEngine: null,
        vin: "",
      },
      () => {
        // Reset dropdown options to initial state
        setYears([]);
        setModels([]);
        setEngines([]);
      }
    );
  };
  render() {
    const { makes, years, models, engines } = this.context;
    const isButtonDisabled = !(this.state.selectedCar || this.state.vin);
    const { t } = this.props;
    return (
      <main className="main">
        <div className="container mt-3">
          <span
            className="btn btn-primary btn-block"
            style={{ borderRadius: "10px 10px 0px 0px" }}
            data-toggle="modal"
            data-target=".bg-modal-for-car-select">
            <div className="container">
              <div className="select-option d-flex justify-content-around align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Files/Cars/select-car.png`}
                    alt="select-car-icon"
                    style={{ width: "90%" }}
                  />
                </div>
                <div
                  className="mr-3 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "20px" }}>
                  <h3
                    className=" text-center text-light text-capitalize"
                    style={{ fontWeight: "normal" }}>
                      {t("choose_your_car")}
                    
                  </h3>
                </div>
                <div className="dropdown-img d-flex justify-content-center align-items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Files/Cars/dropdown.png`}
                    alt="dropdown icon"
                    style={{ width: "60%" }}
                  />
                </div>
              </div>
            </div>
          </span>

          <div
            className="modal fade bg-modal-for-car-select"
            style={{ background: "rgb(0 0 0 / 80%)" }}
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true">
            <div
              className="modal-dialog modal-sm"
              style={{
                display: "flex",
                justifyContent: "center",
                height: "67vh",
                alignItems: "center",
              }}>
              <div
                className="modal-content"
                style={{
                  background: "transparent",
                  boxShadow: "none",
                  border: "none",
                  minWidth: "auto",
                  display: "block",
                }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{
                    color: "#fff",
                    position: "absolute",
                    top: "-50px",
                    right: "30px",
                  }}>
                  <span aria-hidden="true" style={{ fontSize: "40px" }}>
                    ×
                  </span>
                </button>
                <WithNavigate />
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
function WithNavigate(props) {
  let navigate = useNavigate();
  return <SelectCarsMob {...props} navigate={navigate} />;
}
export default withTranslation()(WithNavigate);
