import React, { Component } from "react";

import SelectCarsMob from "../Components/SelectCars/SelectCarsMob";
import WithNavigate from "./SelectCars/SelectCarsWeb";
import { withTranslation } from 'react-i18next';
class CarSelectionOption extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <div className="container mt-3">
          <span
            className="btn btn-primary btn-block"
            style={{ borderRadius: "10px 10px 0px 0px" }}
            data-toggle="modal"
            data-target=".bg-modal-for-car-select">
            <div className="container">
              <div className="select-option d-flex justify-content-around align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Files/Cars/select-car.png`}
                    alt="select-car-icon"
                    style={{ width: "90%" }}
                  />
                </div>
                <div
                  className="mr-3 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "20px" }}>
                  <h3
                    className=" text-center text-light text-capitalize"
                    style={{ fontWeight: "normal" }}>
                    {t("choose_your_car")}
                  </h3>
                </div>
                <div className="dropdown-img d-flex justify-content-center align-items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Files/Cars/dropdown.png`}
                    alt="dropdown icon"
                    style={{ width: "60%" }}
                  />
                </div>
              </div>
            </div>
          </span>

          <div className="modal fade bg-modal-for-car-select" style={{ background: "rgb(0 0 0 / 80%)" }} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-sm" style={{ display: "flex", justifyContent: "center", height: "67vh", alignItems: "center", }}>
              <div className="modal-content" style={{ background: "transparent", boxShadow: "none", border: "none", minWidth: "auto", display: "block", }}>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ color: "#fff", position: "absolute", top: "-50px", right: "30px", }}> <span aria-hidden="true" style={{ fontSize: "40px" }}> × </span> </button>
                <WithNavigate />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(CarSelectionOption);
