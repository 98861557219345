import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ConfigContext } from "../../../Context/ConfigContext";
import { Rating } from '@smastrom/react-rating'
import axios from "axios";
import Swal from "sweetalert2";
import '@smastrom/react-rating/style.css'

const Reviews = ({ title, partNumber, supId, reviews }) => {
    const [recents, setRecents] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang');
    const dir = localStorage.getItem('dir');
    const { gdcURL, apiURL } = useContext(ConfigContext);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [review, setReview] = useState('');
    const [rating, setRating] = useState(0)
    const [yesiwould, setYesiwould] = useState(0);
    const [reviewSent, setReviewSent] = useState(false);
    const [sendingStatus, setSendingStatus] = useState(false);
    const AddReview = async () => {
        setSendingStatus(true);
        const headers = {
            Accept: "application/json",
            "content-type": "application/json",
        };
        var formdata = {
            customer_id: localStorage.getItem('customer_id') ? Number(localStorage.getItem('customer_id')) : 0,
            name: name,
            email: email,
            review: review,
            rating: rating,
            yes_iwould: yesiwould,
            part_number: partNumber ?? '',
            sup_id: supId
        }
        console.log("formdata", formdata)
        const { data } = await axios.post(`${apiURL}/Parts/AddReview`, formdata);

        if (data.success) {
            // setReviewSent(true);
            Swal.fire({
                title: 'Success',
                text: 'Review has been sent',
                icon: 'success',
                confirmButtonText: 'Okay'
            })
            setName("");
            setEmail("");
            setReview("");
            setRating(0);
            setReviewSent(true);
        } else {
            Swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        setSendingStatus(false);

    }
    const BtnDisable = () => {
        if (!name || !email || !review || rating === 0) {
            return true;
        }
        if (sendingStatus) {
            return sendingStatus;
        }
    }
    return (
        <div dir={dir} style={{textAlign: dir==='rtl'?'right': ''}}>
            {
                reviewSent ? <div className="alert alert-success text-center" style={{ textAlign: 'center' }}>
                    Your review has been submitted
                </div> :
                    <div className="container" dir={dir} style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }}>
                        <h5>{title}</h5>
                        <div className="row justify-content-center">
                            <div className="col-sm-6">
                                <div className="row">
                                    <div className="col-md-12 mb-2" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <span style={{ fontSize: '18px', color: '#383737' }}>{t('please_rate_this_product')}</span>
                                        <Rating fontSize={12} style={{ maxWidth: 100 }} value={rating} onChange={setRating} />
                                    </div>
                                    <div className="col-md-6">
                                        {name && <label style={{position: 'absolute', top: '-8px', marginLeft: '10px'}}>{t('name')}</label>}
                                        <input className="form-control" placeholder={t('name')} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="col-md-6">
                                    {email && <label style={{position: 'absolute', top: '-8px', marginLeft: '10px'}}>{t('email')}</label>}
                                        <input className="form-control" placeholder={t('email')} onChange={(e) => setEmail(e.target.value)} />
                                    </div>

                                    <div className="col-md-12 mb-1">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" onChange={(e) => setYesiwould(e.target.checked ? 1 : 0)} value="" id="flexCheckDefault" />
                                            <label className="form-check-label ml-4 mr-4" htmlFor="flexCheckDefault" > {t('yes_i_would_like')}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">


                                        {review && <label style={{position: 'absolute', top: '-8px', marginLeft: '10px'}}>{t('write_review')}</label>}
                                            <textarea className="form-control" placeholder={t('write_review')} onChange={(e) => setReview(e.target.value)}>

                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 pt-5 " style={{ border: '1px solid #CCC' }}>
                                <div className="justify-content-center pb-4" style={{ borderBottom: '1px solid #CCC' }}>
                                    <center>
                                        <Rating fontSize={12} style={{ maxWidth: 150 }} value={reviews && reviews !== "0" && Math.round(reviews.split('-')[0])} onChange={setRating} />
                                        {t("submit_a_review")}
                                    </center>
                                </div>

                                <div className="d-flex align-items-center mb-3 mt-3">
                                    <span className="mr-2">0 {t('stars')}</span>
                                    <div className="progress flex-grow-1 ml-3 mr-3" style={{ height: '10px' }}>
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: `${(rating / 5) * 100}%` }}
                                            aria-valuenow={(rating / 5) * 100}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        ></div>
                                    </div>
                                    <span className="ml-2">5 {t('stars')}</span>
                                </div>
                                <div className="d-flex align-items-center mb-3 mt-3" style={{ borderTop: '1px solid #CCC' }}>
                                    <div className="w-100">
                                        <div className="accordion" id="reviewQuestions">
                                            <div className="card" style={{ marginBottom: 0 }}>
                                                <div className="card-header" id="headingOne" style={{ backgroundColor: '#FFF' }}>
                                                    <h2 className="mb-0">
                                                        <button className="btn btn-link btn-block text-left d-flex justify-content-between align-items-center" type="button" data-toggle="collapse" data-target="#collapseOne">
                                                            {t('how_the_star_rating')}
                                                            <i className="fas fa-plus"></i>
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div id="collapseOne" className="collapse" data-parent="#reviewQuestions">
                                                    <div className="card-body">
                                                        {t('customer_reviews_and_star')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="headingTwo" style={{ backgroundColor: '#FFF' }}>
                                                    <h2 className="mb-0">
                                                        <button className="btn btn-link btn-block text-left d-flex justify-content-between align-items-center" type="button" data-toggle="collapse" data-target="#collapseTwo">
                                                        {t('how_we_verify')}
                                                            <i className="fas fa-plus"></i>
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div id="collapseTwo" className="collapse" data-parent="#reviewQuestions">
                                                    <div className="card-body">
                                                    {t('the_tag_verified')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button disabled={BtnDisable()} className="btn btn-primary" type="button" onClick={AddReview}>{t('submit_a_review')}</button>
                    </div>
            }
        </div>
    )
}

export default Reviews;


