import Invoice from "../Components/Invoice/Invoice"; 
import Account from "../Pages/Account/Account";
import AccountDetails from "../Pages/Account/AccountDetails";
import Addresses from "../Pages/Account/Addresses";
import Downloads from "../Pages/Account/Downloads";
import EmailVerification from "../Pages/Account/EmailVerification";
import ManageAddress from "../Pages/Account/ManageAddress";
import MyOrders from "../Pages/Account/MyOrders";
import OrderDetails from "../Pages/Account/OrderDetails";
import Signup from "../Pages/Account/Signup";
import CarVin from "../Pages/Cars/CarVIn";
import Cart from "../Pages/Carts/Cart";
import Checkout from "../Pages/Carts/Checkout";
import Success from "../Pages/Carts/Success";
import Home from "../Pages/Home/Home";
import MapHome from "../Pages/Map/MapHome";

import Catelog from "../Pages/SpareParts/Catelog/Catelog";
import MakesModels from "../Pages/SpareParts/MakesModels";
import PartDetails from "../Pages/SpareParts/PartDetails/PartDetails";

import Parts from "../Pages/SpareParts/Parts";
import PartsBySuppliers from "../Pages/SpareParts/PartsBySuppliers";
import SupplierCatelog from "../Pages/SpareParts/SupplierCatelog";
import Suppliers from "../Pages/SpareParts/Suppliers"; 
import WishList from "../Pages/WishList/WishList";
import ForgetPassword from "../Pages/Account/ForgetPassword";
import ResetPassword from "../Pages/Account/ResetPassword";
import PageNotFound from "../Pages/Statics/PageNotFound";
import NoResults from "../Pages/SpareParts/NoResults";
import NoCarSelected from "../Pages/SpareParts/NoCarSelected";
import Pages from "../Pages/Statics/Pages";
import Contact from "../Pages/Statics/Contact";
import OEPartDetails from "../Pages/SpareParts/OEPartDetails/OEPartDetails";
import Failure from "../Pages/Carts/Failure";
import AllMakesList from "../Pages/SpareParts/Catelog/AllMakesList";
import MakeSection from "../Pages/SpareParts/Catelog/MakeSection";
import Basket from "../Pages/Carts/Basket/Basket";
import { Navigate } from "react-router-dom";
import MobBasket from "../Pages/Carts/MobBasket/MobBasket";
import MobParts from "../Pages/SpareParts/MobParts";


const PrivateRoute = ({ element, ...rest }) => {
  const userLoggedIn = localStorage.getItem('customer_id') === ''|| localStorage.getItem('customer_id') === null || localStorage.getItem('customer_id') === '0'?false:true;
  return ( userLoggedIn?element: <Signup />)
};
// routes.js
const routes = [
  { path: '/', element: <Home /> },
  { path:'*', element: <PageNotFound /> },
  { path: '/MapTest', element: <MapHome /> },

  // <Route path="/Signup/" element={<Signup />} />
  { path: '/Signup', element: <Signup /> },
  { path: '/Login', element: <Signup /> },
  { path: '/Forget-Password', element: <ForgetPassword /> },
  { path: '/Reset-Password/:reset_password_token', element: <ResetPassword /> },
  { path: '/EmailVerification/:token', element: <EmailVerification/> },

  { path: '/Cart', element: <Cart /> },
  
  { path: '/Contact', element: <Contact/> },
  { path: '/Aboutus', element: <Pages page="Aboutus" /> },
  { path: '/Faqs', element: <Pages page="Faqs" /> },
  { path: '/Delivery', element: <Pages page="Delivery" /> },
  { path: '/Warranty-Coverage', element: <Pages page="Warranty-Coverage" /> },
  { path: '/Privacy-Policy', element: <Pages page="Privacy-Policy" /> },
  { path: '/International-Customers', element: <Pages page="International-Customers" /> },
  { path: '/Cancelation', element: <Pages page="Cancelation" /> },
  { path: '/Easy-way-to-Shop', element: <Pages page="Easy-way-to-Shop" /> },
  { path: '/Safe-Shopping', element: <Pages page="Safe-Shopping" /> },
  { path: '/Car-Parts-Online', element: <Pages page="Car-Parts-Online" /> },
  { path: '/Attractive-Prices', element: <Pages page="Attractive-Prices" /> },
  { path: '/Brands', element: <Pages page="Brands" /> },
  { path: '/Careers', element: <Pages page="Careers" /> },
  { path:'/WishList', element:<PrivateRoute element={<WishList />} /> },
  { path: '/Checkout', element: <Checkout /> },
  { path: '/OrderInformation/:order_uid', element: <Success /> },
  { path: '/FailureOrderInformation/:order_uid?', element: <Failure /> },
  { path: '/Invoice', element: <Invoice /> },

  ////////////////////// Master's Routing ///////////////////////////

  // { path:'/Part/:part_uid/:part_id/:part_title_en/:PartNumber', element:<PartDetail /> },

  { path:'/VIN/:vin', element:<CarVin /> },
  { path:'/Spare-Parts/Catelog', element:<Catelog /> },
  { path:'/Spare-Parts/Catelog/:s_str_id?', element:<Catelog /> },
  { path:'/Spare-Parts/:slug?/:make?/:year?/:model?/:engine?/:fuel?', element:window.innerWidth <= 768 ? <MobParts/> : <Parts/> },
  { path:'/Spare-Parts-nocar/:slug?/:make?/:model?/:series?', element:<NoCarSelected /> },
  { path:'/Spare-Parts-No-Results/:keyword?', element:<NoResults /> },
  { path:'/Spare-Parts-Supplier/:slug/:sup_id/:sup_name', element:localStorage.getItem('pc_id') && localStorage.getItem('pc_id') !=0 && localStorage.getItem('pc_id')!=null?<PartsBySuppliers /> :<NoCarSelected />},
  { path:'/Spare-Parts/Details/:part_slug', element:<PartDetails /> },
  
  { path:'/Supplier-Catelog/:slug', element:<SupplierCatelog /> },
  { path:'/Suppliers', element:<Suppliers /> },
  { path:'/Car-Brands', element:<AllMakesList /> },
  { path:'/Car-Brands/:make', element:<MakeSection /> },
  { path:'/Car-Brands/:make/:model?', element:<MakeSection /> },
  { path:'/Makes/:mf_name/:mfa_id', element:<MakesModels /> },
  { path:'/OE-Spare-Parts/Details/:part_slug', element:<OEPartDetails /> },
  // { path:'/OE-Spare-Parts/Details/:part_slug', element:<OEPartDetails /> },

////////////////////////////// BASKET /////////////////
{ path:'/basket', element: window.innerWidth <= 768 ? <MobBasket default={0}/> : <Basket default={0}/> },
{ path:'/basket/login', element: localStorage.getItem('token') && localStorage.getItem('customer_id') 
  ? <Navigate to="/basket/address" replace /> 
  : window.innerWidth <= 768 ? <MobBasket default={1}/> : <Basket default={1}/> },
{ path:'/basket/address', element: localStorage.getItem('token') && localStorage.getItem('customer_id') 
  ? window.innerWidth <= 768 ? <MobBasket default={2}/> : <Basket default={2}/>  
  : <Navigate to="/basket/login" replace /> },
{ path:'/basket/payment', element: localStorage.getItem('token') && localStorage.getItem('customer_id') 
  ? window.innerWidth <= 768 ? <MobBasket default={3}/> : <Basket default={3}/>  
  : <Navigate to="/basket/login" replace /> },
{ path:'/basket/confirm', element: localStorage.getItem('token') && localStorage.getItem('customer_id') 
  ? window.innerWidth <= 768 ? <MobBasket default={4}/> : <Basket default={4}/>  
  : <Navigate to="/basket/login" replace /> },

////////////////////////////// BASKET /////////////////

  ////////////////////// MyAccount Routing ///////////////////////////
  { path:'/MyAccount', element:<PrivateRoute element={<Account />} /> },
  { path:'/MyAccount/Dashboard', element:<PrivateRoute element={<Account />} /> },
  { path:'/MyAccount/Addresses', element:<PrivateRoute element={<Addresses />} />},
  { path:'/MyAccount/ManageAddress', element:<PrivateRoute element={<ManageAddress />} />},
  { path:'/MyAccount/EditAddress/:address_id', element:<PrivateRoute element={<ManageAddress />} />},
  { path:'/MyAccount/Orders', element:<PrivateRoute element={<MyOrders />} />},
  { path:'/MyAccount/Orders/:order_uid', element:<PrivateRoute element={<OrderDetails />} />},
  { path:'/MyAccount/Downloads', element:<PrivateRoute element={<Downloads />} />},
  { path:'/MyAccount/AccountDetails', element:<PrivateRoute element={<AccountDetails />} />},
];
  
export default routes;
  