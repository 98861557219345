import { Component, useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { ConfigContext } from "../../Context/ConfigContext";
// import { AddToCart, GetCarts } from "../../Helper/Carts";
import { AddToCart, GetCartItemQty, GetCarts } from "../../Helper/MyCart";
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation, withTranslation } from 'react-i18next';
import { AddToCartNotifier } from "../CartComponents";

const PartsHorizontalCard = ({ partImg, id, number, title, mrp, price, qty, imgUrl, url, criteria, displayPrice, brand, supid, partInfo, display_price, pt_intl, suplogo, art_type, mfa_id, part }) => {

  const { local, apiURL, gdcURL, setCartItemsQty, isUserInternational, setIsCartDropdownOpen } = useContext(ConfigContext);
  const [brandImg, setBrandImg] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dir = localStorage.getItem('dir');
  const [add_to_cart_status, setAdd_to_cart_status] = useState(false);
  const [selectQty, setSelectedQty] = useState(1);
  const [PTINTL, setPTINTL] = useState(pt_intl);
  const [images, setimages] = useState([]);
  const [loadingMedia, setLoadingMedia] = useState(false);
  const [partImageFullURL, setPartImageFullURL] = useState(`${apiURL}/public/placeholder_category.jpeg`);

  // var partImg = partImg?`${gdcURL}/images/${partImg}`:`${apiURL}/public/placeholder_category.jpeg`
  var partURL = url;
  useEffect(() => {
    if (art_type !== 'OENumber') {
      setPartImageFullURL(partImg);
    }

  }, [])
  useEffect(() => {
    if (!isUserInternational) {
      setPTINTL(1)
    }
  }, [])

  useEffect(() => {
    GetPartMedias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Fetch medias whenever ART_ID changes

  const GetPartMedias = () => {
    setLoadingMedia(true);
    fetch(`https://ghayar.ae/api/GetOEPartMedias?part_number=${part.ART_SEARCH_NUMBER}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Origin': window.location.origin
        },
        mode: 'cors'
      }
    )
      .then((res) => res.json())
      .then((data) => {
       
        var iRows = [];
        for (var i = 0; i < data.data.length; i++) {
          var row = data.data[i];
          
          if (row.media_type === 0) {
            var obj = {
              original: row.image_full_path,
              thumbnail: row.image_full_path,
            }
            iRows.push(obj)

            setPartImageFullURL(row.image_full_path);
            // alert(row.image_full_path)
          }
        }
        setimages(iRows);
        setLoadingMedia(false);


      })
      .catch((error) => {
        console.error("Error fetching media:", error);
        setLoadingMedia(false);
      });
  };
  const removeBrandImage = () => {
    setBrandImg(false);
  }
  const handleAddToCart = async (slug) => {
    var prev_qty = await GetCartItemQty(number);
    if (Number(qty) >= Number(selectQty) + prev_qty) {
      // setSelectedQty(prevQty => prevQty + 1);

      // toast.success('Successfully toasted!');

      setAdd_to_cart_status(true)
      // console.log(this.props.id,this.props.number, this.props.title, this.props.mrp, this.props.price,  this.props.qty, this.props.imgUrl, this.props.url)
      // console.log(id,number, title, mrp, price,  selectQty, imgUrl, url);

      await AddToCart(id, number, title, mrp, price, selectQty, imgUrl, url, qty);
      setCartItemsQty();

      setIsCartDropdownOpen(true);
      window.scrollTo(0, 0);
      // toast.success(<AddToCartNotifier item_name={title} item_img={imgUrl} />, {
      //   onClose: () => {
      //     setAdd_to_cart_status(false)
      //   }
      // })
    } else {
      toast.success(<AddToCartNotifier item_name={t('sorry_we_have_only', { value: qty })} item_img={imgUrl} />, {})
    }
    // UpdateCartList(GetCarts());
    // ChangeCartProcessStatus(true);

  }
  const incrementQty = async () => {

    var prev_qty = await GetCartItemQty(number);
    if (Number(qty) > Number(selectQty) + prev_qty) {
      setSelectedQty(prevQty => prevQty + 1);
    } else {
      toast.success(<AddToCartNotifier item_name={t('sorry_we_have_only', { value: qty })} item_img={imgUrl} />, {})
    }

  };

  const decrementQty = () => {
    setSelectedQty(prevQty => (prevQty > 1 ? prevQty - 1 : 1)); // Assuming quantity can't be less than 1
  };

  
  return <div className="col-sm-12 col-6 product-default left-details product-list mb-2" style={{ borderBottom: '1px solid #CCC' }}>

    <figure style={{ marginBottom: 'auto', position: 'relative' }}>
      {
        art_type === 'OENumber' && <div style={{
          position: 'absolute',
          width: '190px',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundPosition: 'center',
          backgroundImage: `url('${gdcURL}/make_logo/${mfa_id}.png')`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        }} ></div>
      }
      {
        art_type !== 'OENumber' && <div style={{
          position: 'absolute',
          width: '190px',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundPosition: 'center',
          backgroundImage: `url('${gdcURL}/sup-logo/${suplogo}')`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        }} ></div>
      }
      {/* {
      art_type === 'IAMNumber' && <ImageWithFallback
            src={`${gdcURL}/sup-logo/${suplogo}`}
            alt={supid}
            index={supid}
            fallbackText={suplogo}
            OE={false}
          />
          } */}
      <a href={partImageFullURL}  style={{ marginTop: '50px' }}>

        <img src={partImageFullURL} width={300} style={{ width: '300px', marginTop: '40px', border: '0px solid #CCC' }} height={300} alt={`${title} - ${number} - ${brand}`} />
        {/* <img src={partImg} width={250} style={{width: '250px', border: '1px solid #666'}} height={250} alt="product" /> */}
      </a>
    </figure>





    <div className="product-details responsive-width-part-desc" style={{ minHeight: '300px' }} >

      <h3 className="product-title" style={{ alignSelf: 'flex-start' }}>
        <NavLink onClick={() => {window.scrollTo(0, 0);}} to={partURL}>{title} - {number} - {brand}</NavLink>
      </h3>
      <div className="ratings-container" style={{ margin: 0 }}>

        <div className="d-flex align-items-center justify-content-center">
          <small className="mt-1">{t('article_number', { value: number })} {" "} | {" "}</small>
          <div className="star-rating mt-1" style={{ color: 'red', fontSize: '18px' }}>
           


                  {part && part.REVIEWS && part.REVIEWS !== "0" && Math.round(part.REVIEWS.split('-')[0]) !== 0? 
                        '★'.repeat(Math.round(part.REVIEWS.split('-')[0])) + '☆'.repeat(5 - Math.round(part.REVIEWS.split('-')[0])) 
                        : '☆☆☆☆☆'
                      }
                    <span>{part && part.REVIEWS && part.REVIEWS !== "0" && Math.round(part.REVIEWS.split('-')[0]) !== 0? <NavLink onClick={() => {window.scrollTo(0, 0);}} className={`ml-2 product-description__item-value`} to={partURL} style={{ color: '#0068d7', fontWeight: 500, fontSize: '12px', WebkitFontSmoothing: 'antialiased' }}>{" "}{" "}  {t('reviews')} - {part.REVIEWS.split('-')[1]}</NavLink>: '' }</span>
          </div>

         {part && part.REVIEWS && part.REVIEWS !== "0" ? <span></span>: <span className="mt-1 ml-2"><NavLink onClick={() => {window.scrollTo(0, 0);}} to={partURL} style={{ color: '#0068d7' }}><b>{t('submit_a_review')}</b></NavLink></span>}
        </div>
        {/* End .product-ratings */}
      </div>
      {/* End .product-container */}
      <p className="product-description w-100 pr-5">

        {
          criteria && <table className="table table-striped w-100 mr-4" dir={dir} style={{ textAlign: (dir === 'rtl' ? 'right' : 'left'), marginBottom: 0 }}>
            <tbody>
              {
                criteria.map((crt, indixSub) => {
                  return dir === 'rtl'?<tr key={`info-tr-${indixSub}`} >
                    <th style={{ paddingTop: '3px', borderTop: '1px solid #f1f2f4', paddingBottom: '3px' }}><span className="product-description__item-title">{crt.criteria_ar}</span></th>
                    <td style={{ paddingTop: '3px', borderTop: '1px solid #f1f2f4', paddingBottom: '3px' }}><span className="product-description__item-value">{crt.value_ar}</span></td>
                  </tr>:<tr key={`info-tr-${indixSub}`} >
                    <th style={{ paddingTop: '3px', borderTop: '1px solid #f1f2f4', paddingBottom: '3px' }}><span className="product-description__item-title">{crt.criteria_en}</span></th>
                    <td style={{ paddingTop: '3px', borderTop: '1px solid #f1f2f4', paddingBottom: '3px' }}><span className="product-description__item-value">{crt.value_en}</span></td>
                  </tr>
                })
                // criteria.split(';').map((item, indixSub) => {
                //     var crRow = item.split(':');
                //     return <tr key={`info-tr-${indixSub}`}><td><span>{crRow[0]}</span></td><td><span>{crRow[1]}</span></td></tr>
                // })
              }
            </tbody>

          </table>
        }

      </p>
      {part?.OEM_NUMBERS && (
        <div className="mt-0 mb-3" style={{ width: '100%' }}>
          <div
            className="d-flex align-items-center text-primary cursor-pointer"
            style={{ gap: '5px' }}
            onClick={(e) => {
              const target = e.currentTarget;
              const isOpen = target.nextElementSibling?.classList.toggle('d-none');
              target.querySelector('i').className = `product-description__item-title fas ${isOpen ? 'fa-minus-circle' : 'fa-plus-circle'
                }`;
            }}
          >
            <i style={{ fontSize: '18px' }} className="product-description__item-title fas fa-plus-circle"></i>
            <span className="product-description__item-title">{t('show_oem_numbers')}</span>
          </div>
          <div
            className="d-none"
            style={{
              maxHeight: '80px',
              overflowY: 'auto',
              padding: '5px',
              marginTop: '5px',
              width: '100%'
            }}
          >
            <table style={{ width: '100%' }}>
              {part && part.OEM_NUMBERS && part.OEM_NUMBERS.map((oem, index) => (

                <tr key={`info-tr-${index}`} >
                  <td style={{ paddingTop: '3px', borderTop: '1px solid #f1f2f4', paddingBottom: '3px' }}><span className="product-description__item-value">
                    {oem.ARL_BRA_BRAND} {oem.ARL_DISPLAY_NR}
                  </span></td>
                </tr>

              ))}
            </table>
          </div>
        </div>
      )}

      <div className="col-lg-12 mb-3">
        <NavLink onClick={() => {window.scrollTo(0, 0);}} to={partURL} className="btn btn-outline-dark" style={{ textTransform: 'capitalize', paddingTop: '12px', paddingBottom: '12px', paddingLeft: '35px', paddingRight: '35px' }}>{t('details')} <i className="fas fa-long-arrow-alt-right"></i></NavLink>
      </div>

      {/* End .price-box */}

    </div>
    <div className="product-action" style={{ marginBottom: 'auto' }}>
      <div className="price-box" style={{width:'300px' }}>
        <table>
          <tr>
            <td colSpan={2}>
              {price > 0 && <span ><i style={{ color: 'rgb(11, 207, 28)' }} className="far fa-dot-circle"></i> {t('in_stock')}</span>}

              {price <= 0 && <h1 className='mt-4' >{t('out_of_stock')}</h1>}

              {price > 0 && <h1 className="mt-5 mb-2"  style={{ lineHeight: 0.2, fontSize: '36px', fontWeight: 500 }}>{display_price}</h1>}
              {price > 0 && <span className="mt-4"><small >{t('price_included_vat_excluded_delivery')}</small></span>}

              {PTINTL === 0 &&
                <div className='pl-5 pr-5 pt-3 pb-1' style={{ backgroundColor: 'rgb(254, 245, 245)' }}>
                  <div style={{ color: 'rgb(226, 10, 24)' }}><i className="fas fa-globe"></i> {t('international_delivery_not_for', { value: '' })} </div>
                  <div style={{ color: 'rgb(254, 245, 245)' }}><h3 style={{ fontWeight: 400 }}> {title}</h3></div>
                </div>
              }
            </td>
          </tr>
          <tr>
            <td>

              <div className="input-group float-right mt-1">
                <div className="input-group-prepend" onClick={decrementQty}>
                  <span className="input-group-text"><i className="fas fa-minus"></i></span>
                </div>
                <div type="number" className="form-control no-arrows" value="1" style={{ textAlign: 'center', lineHeight: '35px' }} aria-label="Amount (to the nearest dollar)" >{selectQty}</div>
                <div className="input-group-append" onClick={incrementQty}>
                  <span className="input-group-text"><i className="fas fa-plus"></i></span>
                </div>
              </div>

            </td>
            <td>
              {PTINTL > 0 && price > 0 &&
                <button onClick={() => handleAddToCart()} className="mt-1 btn btn-dark add-cart mr-2 text-light" title={t('add_to_cart')}>
                  <i className="fas fa-cart-plus mr-2"></i>{t('buy')}
                </button>
              }

            </td>
          </tr>
        </table>

      </div>

    </div>
    {/* End .product-details */}
  </div>
}
const ImageWithFallback = ({ src, index, alt, fallbackText, OE }) => {
  const [imgError, setImgError] = useState(false);

  const handleError = () => {
    setImgError(true);
  };
  const fallbackStyle = {
    textAlign: "center",
    fontSize: "10px",
    padding: "10px",
    wordBreak: "break-word",
    maxWidth: "90%",
  };

  const containerStyle = {
    position: "absolute",
    width: "210px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
  };
  return (
    <div style={containerStyle}>
      {imgError ? (
        <div style={fallbackStyle}>{fallbackText}</div>
      ) : (
        <img
          draggable="false"
          style={{
            height: "50px",
            margin: "auto",
            float: "none",
            marginTop: OE === false ? "5px" : 0,
          }}
          key={`img-part-${index}`}
          src={src}
          alt={alt}
          onError={handleError}
        />
      )}
    </div>
  );

};

export default PartsHorizontalCard;